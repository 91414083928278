
defsPackEditor.title.placeholder=Digite o título (tema ou algo assim)
defsPackEditor.description.placeholder=Digite a descrição.
defsPackEditor.title.create=Crie novos flashcards
defsPackEditor.title.save=Editar Flashcards
defsPackEditor.addPackImage=Adicionar imagem
visibility.label=Visível para
visibility.0=Apenas eu
visibility.1=Todos
## не удаляйте этот блок, на него есть ссылки в {plurals}
pl.card.one=cartão
pl.card.few=cartões
pl.card.other=cartões
pl.day.one=dia
pl.day.few=dias
pl.day.other=dias
pl.passedReview.one=Avaliação aprovada
pl.passedReview.few=Passado revisões
pl.passedReview.other=Passado revisões
pl.failedReview.one=Revisão falhada
pl.failedReview.few=Revisões falhadas
pl.failedReview.other=Revisões falhadas
pl.freshCard.one=cartão fresco.
pl.freshCard.few=Cartões frescos.
pl.freshCard.other=Cartões frescos.
pl.hours.one=hora
pl.hours.few=horas
pl.hours.other=horas
pl.hours={0} {0,plural,one{hora}few{horas}other{horas}}
pl.days.one=dia
pl.days.few=dias
pl.days.other=dias
pl.days={0} {0,plural,one{dia}few{dias}other{dias}}
pl.minutes.one=minuto
pl.minutes.few=minutos
pl.minutes.other=minutos
pl.minutes={0} {0,plural,one{minuto}few{minutos}other{minutos}}
## validation
rubd.flashcards.services.validation.SideTextWhenCardIsNotEmpty.message=Cartão não está vazio, você precisa de algum texto para este lado
##
button.save=Salve
button.saveContinue=Salve e continue
button.create=Crio
button.cancel=Cancelar
button.clone=Clone
button.print=Imprimir
button.checkTest=Veja os resultados
button.yes=sim
button.close=Fechar
button.ok=OK
button.help=Ajuda
##
title.label=Título
title.description=Descrição
title.optional=(opcional)
## duration messages
fl.agoString={0} atrás
fl.agoRecently=Recentemente
fl.timeSpent={0} gasto
fl.timeSpentLessThanMinute=Passou um pouco
##
pack.cardCount={0} cartões
pack.cardCount.one={0} cartão
pack.cardCount.many={0} cartões
pack.cardCount.few={0} cartões
##
page.defaultTitle=Flashcards online.
## user private dashboard
fl.mainDashboard.packAgo.title=Tempo decorrido desde a última visita
fl.mainDashboard.packSpent.title=Tempo gasto aprendendo
fl.mainDashboard.hideMemoPack=Este conjunto será removido do painel de aprendizagem de longo prazo. Para adicioná-lo de volta, você precisará clicar no botão "Iniciar aprendizado" na página Set.
##
packEditor.form.termLanguage=Linguagem de termo
packEditor.form.termLanguage.placeholder=Escolha o seu idioma
packEditor.form.definitionLanguage=Linguagem de definição.
packEditor.form.definitionLanguage.placeholder=Escolha o seu idioma
packEditor.form.loading=Carregando a lista de cartões ...
packEditor.form.cardMinimum=Por favor, preencha pelo menos dois flashcards
packEditor.importer.open=Importar de TXT, Word, Excel, Google Docs, etc.
packEditor.importer.close=Fechar
packEditor.importer.import=Importar
packEditor.importer.textLabel=Copie e cole texto aqui (da palavra, Excel, Google Docs, etc)
packEditor.importer.textPlaceholder=Palavra 1    Definição 1\nPalavra 2    Definição 2\nPalavra 3    Definição 3
packEditor.importer.result={0} Entradas foram importadas
packEditor.importer.preview.title=Cartões Visualização
packEditor.importer.preview.empty=Nenhum cartão foi reconhecido. Verifique as configurações de importação no painel direito.
packEditor.anki.button=Importar Anki.
packEditor.anki.dialog.message=Envie seu arquivo .apkg para importar seu deck de flashcards Anki.
packEditor.anki.dialog.notice1=Alguns decks não podem ser importados ou podem ser importados com erros. Depende da estrutura do convés. Lamentamos por isso.
packEditor.anki.dialog.notice2=Você pode importar seus próprios decks ou decks que você tem o direito de copiar e modificar.
packEditor.anki.dialog.title=Importar FlashCards Anki.
packEditor.anki.dialog.button=Navegar
packEditor.anki.progress.sending=Enviando arquivo ...
packEditor.anki.progress.parsing=Arquivo de Parsing ...
packEditor.anki.progress.forming=Inserindo o resultado em forma ...
packEditor.anki.progress.error=Importação falhou.
packEditor.anki.progress.success=Operação completa. Novos cartões foram adicionados à lista abaixo.
packEditor.addRow=Adicionar cartão
packEditor.card.term.placeholder=Prazo
packEditor.card.term.needText=Precisa de texto aqui
packEditor.card.def.placeholder=Definição
packEditor.card.def.needText=Precisa de texto aqui
packEditor.importer.form.mode=Modo de importação
packEditor.importer.form.mode.everyLine=Termo e definição estão em todas as linhas, separadas por delimitador
packEditor.importer.form.mode.lineByLine=O termo e a definição são sequenciais, um elemento por linha
packEditor.importer.form.everyLine1=Delimitador de entrada (linha)
packEditor.importer.form.everyLine2=Delimitador de Termo / Definição
packEditor.importer.form.everyLine3=Opções adicionais
packEditor.importer.form.ldLine=Envoltório de linha
packEditor.importer.form.ldDoubleLine=Envoltório de linha dupla.
packEditor.importer.form.ldCustom=Símbolo personalizado
packEditor.importer.form.ldJoinLowercase=Junte-se a linha que começa com símbolo minúsculo
packEditor.importer.form.tdDash=Traço (-)
packEditor.importer.form.tdTab=Aba
packEditor.importer.form.tdCustom=Símbolo personalizado
packEditor.importer.form.lineByLine1=Opções adicionais
packEditor.importer.form.llDoubleLine=Envoltório de linha dupla entre todos os grupos
packEditor.importer.form.llJoinLowercase=Junte-se a linha que começa com símbolo minúsculo
packEditor.splitNewCards.notice=Parece que você tem muitas cartas. Talvez seja razoável dividir cartões em vários conjuntos
packEditor.splitNewCards.checkbox.1=Sim, quebre os cartões em
packEditor.splitNewCards.checkbox.2=peças em cada conjunto
packEditor.splitNewCards.shuffle=E shuffle cartões antes de dividir
packEditor.splitNewCards.suffix=Parte {0}.
packEditor.putToCollection=Adicionar pacote para
packEditor.putToCollection.none=<Nenhum >.
packEditor.putToCollection.createNew=coleção ou {0} Criar novo {1}.
packEditor.images.search=Pesquisar imagens
packEditor.images.search.or=ou
packEditor.images.upload=Envio
packEditor.images.remove=Remova a imagem.
packEditor.images.emptySearch=Nenhuma imagem encontrada. Tente outra consulta.
packEditor.audio.record=Registro
packEditor.audio.remove=Retirar
packEditor.audio.upload=Envio
##
collectionForm.modal.title=Crie nova coleção
collectionForm.name=Nome
collectionForm.name.placeholder=Digite o nome da coleção
collectionForm.description=Descrição
collectionForm.description.placeholder=Digite a descrição da coleção
##
myPage.title.author=Meus próprios conjuntos
myPage.title.lastPacks=Meus últimos sets.
myPage.title.favourite=Meus conjuntos favoritos
myPage.empty.last=Você não visitou nenhum conjunto
myPage.empty.author=Você não criou nenhum conjunto
myPage.empty.favourite=Sua lista favorita está vazia
##
packPage.header.from=por
packPage.buttonGroup.play=Toque
packPage.button.play=Pré-visualização
packPage.button.test=Teste
packPage.button.learn=Aprender
packPage.button.write=Escrever
packPage.button.spell=Feitiço
packPage.button.match=Corresponder
packPage.button.live=Ao vivo
packPage.solw.short=Rápida aprendizagem
packPage.solw.short.subtitle=Repita este conjunto de flashcards
packPage.solw.short.new=Novo:
packPage.solw.short.familiar=Familiar:
packPage.solw.short.mastered=Dominado:
packPage.solw.long=Aprendizagem de longo prazo
packPage.solw.long.subtitle=Repetição espaçada
packPage.solw.long.today=Hoje tarefa
packPage.solw.long.new=Novo:
packPage.solw.long.review=Rever:
packPage.button.memo=Memorando
ppp0.title=Quer aprender isso rapidamente?
packPage.dialogs.forbidden.alert=Disponível apenas para usuários autorizados
packPage.dialogs.forbidden.addToCollection.text=Você pode adicionar este conjunto a uma de suas coleções
packPage.dialogs.forbidden.addToCollection.alert=Disponível apenas para usuários autorizados
packPage.dialogs.forbidden.createNew.title=Receber!
packPage.dialogs.forbidden.createNew.text=Apenas usuários autorizados podem criar novos cartões.
packPage.dialogs.forbidden.clone.text=Se você usar esses cartões para estudo, mas deseja complementá-los, basta clonar e criar um novo conjunto que você precisa.
packPage.dialogs.forbidden.clone.alert=Disponível apenas para usuários autorizados
packPage.dialogs.addToCollection.title=Adicione este conjunto para coleção
packPage.dialogs.addToCollection.notice=Você pode adicionar este conjunto de flashcards às suas coleções de estudo
packPage.dialogs.addToCollection.createNew=+ Adicionar nova coleção
packPage.dialogs.addToCollection.forbidden=Apenas usuários autorizados podem adicionar cartões a seus cursos
packPage.dialogs.addToFavourites.title=Adicione este conjunto para salvo
packPage.dialogs.addToFavourites.forbidden=Você pode adicionar salvar este conjunto para retornar mais tarde
packPage.dialogs.login=Login / Register.
packPage.dialogs.share.title=Compartilhe este conjunto
packPage.dialogs.clone.title=Clonar este conjunto
packPage.dialogs.clone.notice=Se você usar esses cartões para estudo, mas deseja complementá-los, basta clonar e criar um novo conjunto que você precisa.
packPage.command.edit=Editar
packPage.command.customize=Customizar
packPage.command.addTo=Adicionar à ...
packPage.command.addTo.collection=coleção
packPage.command.addTo.saved=salvou
packPage.command.share=Compartilhado
packPage.list.sorting.original=Original
packPage.list.sorting.alphabetical=Alfabética
packPage.list.filter.all=Mostre tudo
packPage.list.filter.difficult=Difícil
packPage.list.filter.banned=Ignorado
packPage.list.filter.mastered=Masterizado
packPage.list.filter.familiar=Familiar
packPage.list.filter.starred=Estrelou
packPage.list.emptyMessage=Nenhum cartão se encaixa neste filtro
packPage.list.loadMore=Ver mais cartas
packPage.review=Análise
packPage.startMemo=Adicionar ao balcão de aprendizado de longo prazo
packPage.startLearning=Comece a aprender
packPage.speedReview=Revisão de velocidade
packEmbed.clickToFlip=Clique para virar
packPage.tooltips.memoShort=Um modo de aprendizado rápido é útil quando você precisa repetir algumas palavras ou perguntas antes do exame. Adequado para pequenos conjuntos de palavras.
packPage.tooltips.memoLong=O modo de aprendizagem de repetição espaçado é baseado no método de repetição de intervalo. É útil para a preparação sistemática do exame e educação a longo prazo.
packPage.tooltips.preview=Veja todos os conjuntos de cartão sem controlar a si mesmo.
packPage.tooltips.test=Crie um teste para verificar seu conhecimento em um conjunto de cartões.
packPage.tooltips.spell=Verificação ortográfica. Ouvindo e escrevendo o teste.
##
packPlay.back=Voltar
packPlay.options=Opções
packPlay.progress=Progresso
packPlay.progressTemplate={0} de {1}
packPlay.wrongDisplay.title=Resposta errada
packPlay.wrongDisplay.label=Resposta correta
packPlay.wrongDisplay.continueText=pressione qualquer tecla para continuar
packPlay.wrongDisplay.userLabel=Você escreveu
packPlay.wrongDisplay.typeText=Digite a resposta correta abaixo
##
packLearn.remaining=Restante
packLearn.familiar=Familiar
packLearn.mastered=Masterizado
packLearn.write.placeholder=Escreva sua resposta aqui
packLearn.write.placeholderCopy=Copie a resposta correta aqui
packLearn.write.submit=Responder
packLearn.write.skip=Pular
packLearn.write.stillCorrect=eu tinha razão
packLearn.write.giveup=Desistir
packLearn.dialogs.options.title=Opções
packLearn.dialogs.options.questionTitle=Responda com
packLearn.dialogs.options.questionTerm=Prazo
packLearn.dialogs.options.questionDefinition=Definição
packLearn.dialogs.options.questionError=Pelo menos uma caixa de seleção deve ser verificada
packLearn.dialogs.options.modeTitle=Tipos de perguntas
packLearn.dialogs.options.modeSelect=Múltipla escolha
packLearn.dialogs.options.modeWrite=Escrito
packLearn.dialogs.options.modeError=Pelo menos uma caixa de seleção deve ser verificada
packLearn.dialogs.options.modeRandom=Ordem aleatória
packLearn.dialogs.options.resetTitle=Redefinir o progresso
packLearn.dialogs.options.resetButton=Redefinir
packLearn.dialogs.options.modeCards=Flashcards.
packLearn.dialogs.config.title=Opções de memorando.
packLearn.dialogs.firstTime.title=Configuração da primeira vez
packLearn.dialogs.firstTime.notice=Você pode alterar essas configurações no menu "Opções" a qualquer momento
packLearn.dialogs.options.writeAnyAnswer=Escreva qualquer resposta
packLearn.dialogs.options.writeAnyAnswer.notice=Você pode escrever apenas uma resposta disponível (que são separadas por vírgula).
packLearn.dialogs.options.playAudio=Jogar áudio.
packLearn.dialogs.options.newLimit=Limite de novos cartões
packLearn.dialogs.options.newLimitError=Deve ser um número positivo, menos ou igual a 50
packLearn.dialogs.options.order=Ordem de novo.
packLearn.dialogs.options.order.original=Original
packLearn.dialogs.options.order.random=Aleatória
packLearn.dialogs.options.advancedMode=Modo avançado
packLearn.dialogs.options.advancedMode.notice=Por padrão "Aprendizagem de longo prazo" usa um algoritmo de repetição de aprendizado modificado onde é suficiente para escolher apenas "know" ou "não sei". Por alternar para "Modo Avançado", você pode usar um algoritmo clássico com cinco opções disponíveis, semelhante ao SuperMemo ou Anki.
##
searchPage.title=Pesquisa "{0}"
searchPage.head=Resultados para consulta "{0}"
searchPage.display.packs=Mostrar conjuntos.
searchPage.display.courses=Mostrar cursos
searchPage.noResults.packs=Desculpe, nenhum conjunto foi encontrado
searchPage.noResults.courses=Desculpe, nenhum curso foi encontrado
##
learnPage.progress.total=Passado
learnPage.progress.correct=Correto
learnPage.progress.wrong=Errado
learnPage.answer.go=Responder
learnPage.answer.placeholder=Digite sua resposta
learnPage.answer.unknown=Eu não sei
learnPage.wrong.stillCorrect=Minha resposta está correta!
learnPage.next=Próximo
learnPage.result.actual=Sua Resposta
learnPage.result.correct=Resposta correta
learnPage.header.correct=Resposta correta
learnPage.header.wrong=Resposta errada
learnPage.emptyQueue=Parabéns! Você passou por todas as cartas.
learnPage.restartQueue=Reiniciar
learnPage.reviewComplete.title=Parabéns!
learnPage.reviewComplete.text=Você passou por todas as cartas.
##
testSelectPage.opt.limit=Limite de perguntas
testSelectPage.opt.first=Mostrar
testSelectPage.start=Começar o teste
testSelectPage.opt.limit.prefix=Usar
testSelectPage.opt.limit.suffix=de {0}
testSelectPage.opt.first.face=Prazo
testSelectPage.opt.first.back=Definição
testSelectPage.mode1=Corresponder
testSelectPage.mode2=Sim não
testSelectPage.mode3=4 opções
testSelectPage.mode4=Escrever
##
testResult.message=Sua pontuação:
testResult.correctTitle=Resposta esperada
testResult.noAnswer=Você não seleciona nada
testResult.noAnswerWrite=Você escreve nada
testCheck.answer=Responder:
testCheck.answer.yes=Correto
testCheck.answer.no=Errado
testCheck.answer.correct=Correto!
testCheck.answer.wrong=Errado!
testWrite.input.placeholder=Digite sua resposta aqui
##
uknownPack.text=Talvez tivéssemos este conjunto antes ... Infelizmente, não é mais disponível :(
##
packTest.form.questionLimit=Limite de perguntas
packTest.form.questionLimit.of=do
packTest.form.questionLimit.invalid=Contagem de perguntas é inválida.
packTest.form.questionWith=Pergunta com
packTest.form.questionWith.notSelected=Pelo menos uma opção é necessária.
packTest.form.requireOne=Requer apenas uma resposta
packTest.form.requireOne.explain=Pelo menos uma resposta correta é necessária. As respostas devem ser separadas por uma barra (/), uma vírgula (,) ou um ponto e vírgula (;)
packTest.form.types=Tipos de perguntas
packTest.form.types.notSelected=Pelo menos uma opção é necessária.
packTest.form.type.choice=Múltipla escolha
packTest.form.type.yn=Verdadeiro falso
packTest.form.type.written=Escrito
packTest.form.type.matching=Coincidindo
packTest.start=Começar o teste
packTest.qsMatching=Perguntas para correspondência
packTest.qsMatching.expected=A resposta correta é:
packTest.qsWritten=Perguntas para escrever
packTest.qsWritten.inputPlaceholder=Digite sua resposta aqui
packTest.qsWritten.expected=A resposta correta é:
packTest.qsChoice=Perguntas para a escolha
packTest.qsChoice.expected=A resposta correta é:
packTest.qsYN=Perguntas para True / Falso
packTest.qsYN.true=Verdade
packTest.qsYN.false=Falso
packTest.qsYN.expected=A resposta correta é:
packTest.checkAnswers=Verifique as respostas
packTest.score.prefix=Resultado do teste:
packTest.score.90=Excelente!
packTest.score.75=Boa!
packTest.score.50=Média
packTest.score.25=Pobre
packTest.score.0=Fraco
##
columns.cardsInPack=Cartões
##
fexplorer.page.title={0} Explorador de Flashcards
fexplorer.page.categoryTitle={0} flashcards
fexplorer.title=Explorador de Flashcards.
fexplorer.popularsCategories=Categorias populares
fexplorer.language=Minha língua
fexplorer.allCategories=Todas as categorias
fexplorer.collections.section=Coleções
fexplorer.flashcards.section=Flashcards.
fexplorer.flashcards.terms=termos.
fexplorer.empty.message=Por favor, selecione uma categoria <br> ou <br>
fexplorer.empty.search=Pesquise algo
##
packPlay.progressBar.title=Progresso
packPlay.head.play=Flashcards.
packPlay.head.learn=Aprender
packPlay.head.review=Análise
packPlay.head.test=Teste
packPlay.head.write=Escrever
packPlay.head.memo=Memorando
packPlay.head.spell=Feitiço
packPlay.head.match=Corresponder
##
packMemo.showAnswer=Mostre a resposta
packMemo.sessionComplete=Não mais cartões para hoje!
packMemo.sessionNextTime=Volte em {0}.
packMemo.grades.button.H=Dificilmente
packMemo.grades.button.E=Fácil
packMemo.grades.button.M=Médio
packMemo.grades.button.1=Mau
packMemo.grades.button.2=Fraco
packMemo.grades.button.3=Médio
packMemo.grades.button.4=Boa
packMemo.grades.button.5=Forte
packMemo.grades.button.Y=Ok, lembro-me
packMemo.grades.button.R=eu sei
packMemo.grades.button.F=Eu não sei
packMemo.short.days=d
packMemo.short.months=me
packMemo.short.hours=h
packMemo.welcome.title=Mesa de aprendizagem de longo prazo
packMemo.welcome.text=É agendador de repetição espaçado. Depois de começar o processo de aprendizagem, você aprenderá 20 cartas todos os dias. A frequência da exibição do cartão depende de suas respostas. Este modo é baseado no famoso algoritmo de repetição espaçada chamada SM-2. <br> <a href="https://en.wikipedia.org/wiki/supermemo"> Leia mais. </a>
packMemo.welcome.start=Vamos começar
packMemo.welcome.login=Infelizmente, este modo está disponível apenas para usuários autorizados. <br> por favor, <a href="/login"> Entrar </a> ou <a href="/registration""> Inscreva-se </a>. <br> É fácil e absolutamente livre.




packMemo.help.title=Dica
packMemo.help.line1=Barra de espaço para mostrar resposta
packMemo.help.line2=1,2,3,4,5 para fazer sua escolha
packMemo.help.hide=Esconder
packMemo.end.freshAvailable=Você quer ver mais cartões novos hoje?
packMemo.end.freshAccept=Sim, mostre-me mais
##
fl.matchGame.welcome.text=Arraste os itens um para o outro para torná-los dissappes
fl.matchGame.startGame=Começar o jogo
fl.matchGame.timer=Tempo
fl.matchGame.preset.number=Contar
fl.matchGame.result.title=Parabéns!
fl.matchGame.result.text=Você pontua é {0} segundos
fl.matchGame.result.again=Jogar de novo
##
fl.memoHeatMap.title=Sua história de aprendizado
fl.memoHeatMap.legend.daverage=Média diária:
fl.memoHeatMap.legend.daverage.value={0} {0,plural,one{cartão}few{cartões}other{cartões}}
fl.memoHeatMap.legend.dlearned=Dias aprendidos:

fl.memoHeatMap.legend.beststreak=Melhor raia:
fl.memoHeatMap.legend.beststreak.value={0} {0,plural,one{dia}few{dias}other{dias}}
fl.memoHeatMap.legend.currentstreak=Streak atual:
fl.memoHeatMap.legend.currentstreak.value={0} {0,plural,one{dia}few{dias}other{dias}}
fl.memoHeatMap.tooltip.passed={0} {0,plural,one{Avaliação aprovada}few{Passado revisões}other{Passado revisões}}
fl.memoHeatMap.tooltip.failed={0} {0,plural,one{Revisão falhada}few{Revisões falhadas}other{Revisões falhadas}}
fl.memoHeatMap.tooltip.fresh={0} {0,plural,one{cartão fresco.}few{Cartões frescos.}other{Cartões frescos.}}
fl.memoHeatMap.tooltip.date=em {0}
##
cardButtons.ban.title=Proibir este cartão
cardButtons.edit.title=Corrija este cartão
cardButtons.star.title=Alternar cartão favorito
cardButtons.ban.prompt=Ignorar este cartão?
##
validation.cardText.tooLong=Comprimento máximo deste texto é de 1000 caracteres
##
extLocale.la=Latino
extLocale.rw=Kinyarwanda.
extLocale.af=afrikaans
extLocale.am=Amharic.
extLocale.az=Azerbaijani.
extLocale.bn=Bengal
extLocale.bs=Bósnio
extLocale.ceb=Cebuano.
extLocale.co=Corsicana
extLocale.cy=galês
extLocale.eo=esperanto
extLocale.eu=Basque.
extLocale.fa=persa
extLocale.fy=Frísio
extLocale.gd=Escocês (gaélico)
extLocale.gl=Galego
extLocale.gu=Gujarati.
extLocale.ha=casa
extLocale.haw=havaiano
extLocale.hmn=Hmong.
extLocale.ht=Creole (Haiti)
extLocale.hy=Armênio
extLocale.id=indonésio
extLocale.ig=Igbo.
extLocale.jw=Javanese.
extLocale.ka=Georgiano
extLocale.kk=Cazaque
extLocale.km=Khmer.
extLocale.kn=Kannada
extLocale.ku=Kurmanji.
extLocale.ky=Quirguistão
extLocale.lb=Luxemburgo
extLocale.lo=Laotian
extLocale.mg=malgaxe
extLocale.mi=maori
extLocale.ml=Malayalam.
extLocale.mn=mongol
extLocale.mr=Marathi.
extLocale.my=birmanês
extLocale.ne=Nepali.
extLocale.ny=Ceva.
extLocale.or=Oria.
extLocale.pa=Punjabi.
extLocale.ps=Pashto.
extLocale.sd=Sindi.
extLocale.si=Sinhalês
extLocale.sm=Samoan
extLocale.sn=Shona.
extLocale.so=Somali.
extLocale.st=Sesoto.
extLocale.su=sudanês
extLocale.sw=Swahili.
extLocale.ta=tâmil
extLocale.te=Telugu
extLocale.tg=Tajik.
extLocale.tk=Turkmen.
extLocale.tl=filipino
extLocale.tt=Tártaro
extLocale.ug=uigur
extLocale.ur=urdu
extLocale.uz=Uzbeque
extLocale.xh=Xhosa.
extLocale.yi=Iídiche
extLocale.yo=Yoruba.
extLocale.zu=zulu


# landing
fl.landing.title=Aprendendo com {0}
fl.landing.title.small=Sabe o que {0} pode adicionar ao seu processo de aprendizagem
fl.landing.why1.title=É fácil e divertido
fl.landing.why1.text=Milhares de escolares e alunos realizam uma tarefa educacional para {0} todos os dias, melhorando seu nível de avaliações. No sofá, no metrô, em linha - sempre haverá alguns minutos para estudar.
fl.landing.why2.title=Seis modos de treinamento
fl.landing.why2.text=Pegue essas ferramentas mais adequadas às informações necessárias para aprender.
fl.landing.why3.title=Repetição espaçada
fl.landing.why3.text=Com base no estudo da psicologia, o método de repetições de intervalo permite memorizar grandes quantidades de informação sem esforço extraordinário. Basta pesquisar por 10-15 minutos por dia.
fl.landing.why4.title=Em qualquer dispositivo
fl.landing.why4.text=Exercício no dispositivo que você tem em mãos. Não importa, computador, tablet ou telefone - nossas ferramentas são otimizadas.
fl.landing.create.title=Crie seus conjuntos de cartão
fl.landing.create1.title=Criando memorizar
fl.landing.create1.text=Para preparar um conjunto, você tem que estruturar e simplificar informações. Esta é uma das melhores maneiras de dominar o material. No futuro, você só precisará atualizar memórias.
fl.landing.create2.title=Importação conveniente
fl.landing.create2.text=Aproveite a ferramenta flexível para importar cartões de arquivos de texto, a fim de baixar conjuntos de outras fontes. Você também pode importar o arquivo Anki.
fl.landing.create3.title=Modificar
fl.landing.create3.text=Selecione o conjunto apropriado da nossa base, altere-o e adicione-o de acordo com o seu currículo.
fl.landing.create.button=Crie um conjunto
fl.landing.gallery=Exemplos
fl.landing.gallery.others=Veja outros exemplos
fl.landing.stars1=3 milhões de estudantes todos os meses
fl.landing.stars2=100 000 000+ <br> sessões de treinamento
fl.landing.stars3=1000 000+ <br> kits educativos
fl.landing.reg1=Participe de agora e use cartões educacionais de treinamento de intervalo, coleções educacionais e documentos educacionais úteis.
fl.landing.reg2=Não há conta no Google ou no Facebook? Sem problemas! Inscreva-se usando e-mail
## fast add card










## uninstall page
fl_uninstall_title=Obrigado por usar a extensão Studylib.
fl_uninstall_subtitle=Lamentamos ver você ir! Se você tiver um momento, por favor, diga-nos como podemos tornar a extensão melhor:
fl_uninstall_notice=Seu feedback nos ajuda a melhorar o produto para todos, obrigado 😊
fl_uninstall_button=Mandar
fl_uninstall_success=Vejo você em breve!

## APPEND ITEMS ABOVE THIS LINE