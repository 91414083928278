
defsPackEditor.title.placeholder=Придумайте название (тема или что-нибудь другое)
defsPackEditor.description.placeholder=Добавьте описание. Но это не обязательно.
defsPackEditor.title.create=Создание набора карточек
defsPackEditor.title.save=Редактирование набора
defsPackEditor.addPackImage=Добавить картинку
visibility.label=Видимость
visibility.0=Только мне
visibility.1=Виден всем
## не удаляйте этот блок, на него есть ссылки в {plurals}
pl.card.one=карточка
pl.card.few=карточки
pl.card.other=карточек
pl.day.one=день
pl.day.few=дня
pl.day.other=дней
pl.passedReview.one=правильный ответ
pl.passedReview.few=правильных ответа
pl.passedReview.other=правильных ответов
pl.failedReview.one=неправильный ответ
pl.failedReview.few=неправильных ответа
pl.failedReview.other=неправильных ответов
pl.freshCard.one=новая карточка
pl.freshCard.few=новых карточки
pl.freshCard.other=новых карточек
pl.hours.one=час
pl.hours.few=часа
pl.hours.other=часов
pl.hours={0} {0,plural,one{час}few{часа}other{часов}}
pl.days.one=день
pl.days.few=дня
pl.days.other=дней
pl.days={0} {0,plural,one{день}few{дня}other{дней}}
pl.minutes.one=минута
pl.minutes.few=минуты
pl.minutes.other=минут
pl.minutes={0} {0,plural,one{минута}few{минуты}other{минут}}
## validation
rubd.flashcards.services.validation.SideTextWhenCardIsNotEmpty.message=Карточка не пуста, нужен какой-то текст для этой стороны
##
button.save=Сохранить
button.saveContinue=Сохранить и продолжить
button.create=Сохранить
button.cancel=Отмена
button.clone=Клонировать
button.print=Напечатать
button.checkTest=Проверить
button.yes=Да
button.close=Закрыть
button.ok=Ok
button.help=Помощь
##
title.label=Название
title.description=Описание
title.optional=(необязательно)
## duration messages
fl.agoString={0} назад
fl.agoRecently=Недавно
fl.timeSpent=Пройдено {0}
fl.timeSpentLessThanMinute=Пройдено чуть-чуть
##
pack.cardCount={0} карточек
pack.cardCount.one={0} карточка
pack.cardCount.many={0} карточек
pack.cardCount.few={0} карточки
##
page.defaultTitle=Удобные учебные инструменты
## user private dashboard
fl.mainDashboard.packAgo.title=Время прошедшее с последнего визита
fl.mainDashboard.packSpent.title=Время, потраченное на обучение
fl.mainDashboard.hideMemoPack=Этот набор будет удален из списка обучения. Для того, чтобы добавить его обратно вам нужно нажать кнопку «Начать обучение» на странице набора.
##
packEditor.form.termLanguage=Язык терминов
packEditor.form.termLanguage.placeholder=Выберите язык
packEditor.form.definitionLanguage=Язык определений
packEditor.form.definitionLanguage.placeholder=Выберите язык
packEditor.form.loading=Загружается список карточек...
packEditor.form.cardMinimum=Нужно заполнить по крайней мере две карточки
packEditor.importer.open=Импортировать карточки из txt, Word, Excel, Google Docs и т.п.
packEditor.importer.close=Закрыть
packEditor.importer.import=Импортировать
packEditor.importer.textLabel=Скопируйте и вставьте сюда текст (из Word, Excel, Google Docs и т.д.)
packEditor.importer.textPlaceholder=Слово 1      Определение 1\nСлово 2      Определение 2\nСлово 3      Определение 3
packEditor.importer.result={0} карточек распозналось
packEditor.importer.preview.title=Предпросмотр карточек
packEditor.importer.preview.empty=Карточки в тексте не найдены. Проверьте настройки импорта.
packEditor.anki.button=Импорт из Анки
packEditor.anki.dialog.message=Загрузите ваш .APKG фаил для импорта карточке из приложения Anki
packEditor.anki.dialog.notice1=Некоторые наборы карточек не могут быть импортирована или будут импортированы с ошибкой. Это зависит от структуры набора.
packEditor.anki.dialog.notice2=Вы можете загружать ваши собственные наборы или те, которые Вы имеете право копировать и изменять.
packEditor.anki.dialog.title=Импортировать из Анки
packEditor.anki.dialog.button=Загрузить файл APKG
packEditor.anki.progress.sending=Отправляем файл...
packEditor.anki.progress.parsing=Вытаскиваем карточки...
packEditor.anki.progress.forming=Вставляем новые карточки в форму...
packEditor.anki.progress.error=Не удалось выполнить операцию.
packEditor.anki.progress.success=Операция завершена. Новые карты были добавлены в список.
packEditor.addRow=Добавить карточку
packEditor.card.term.placeholder=Термин
packEditor.card.term.needText=Необходимо задать текст на карточке
packEditor.card.def.placeholder=Определение
packEditor.card.def.needText=Необходимо задать текст на карточке
packEditor.importer.form.mode=Режим распознавания
packEditor.importer.form.mode.everyLine=Термин и определение - в каждой строчке
packEditor.importer.form.mode.lineByLine=Термин и определение идут строчка за строчкой
packEditor.importer.form.everyLine1=Разделитель строчки
packEditor.importer.form.everyLine2=Разделитель термин-определение
packEditor.importer.form.everyLine3=Дополнительные опции
packEditor.importer.form.ldLine=Перенос строки
packEditor.importer.form.ldDoubleLine=Двойной перенос строки
packEditor.importer.form.ldCustom=Другой символ
packEditor.importer.form.ldJoinLowercase=Соединить строку с предыдущей, если она начинается с маленькой буквы
packEditor.importer.form.tdDash=Различные виды тире (-)
packEditor.importer.form.tdTab=Таб
packEditor.importer.form.tdCustom=Другой символ
packEditor.importer.form.lineByLine1=Дополнительные опции
packEditor.importer.form.llDoubleLine=Двойной перенос строки разделяет карточки
packEditor.importer.form.llJoinLowercase=Соединить строку с предыдущей, если она начинается с маленькой буквы
packEditor.splitNewCards.notice=У вас получается много карточек. Имеет смысл разбить их на несколько наборов
packEditor.splitNewCards.checkbox.1=Да, создать несколько наборов по
packEditor.splitNewCards.checkbox.2=карточек в каждом
packEditor.splitNewCards.shuffle=И перемешать карточки перед разбивкой
packEditor.splitNewCards.suffix=Часть {0}.
packEditor.putToCollection=Добавить карточки в
packEditor.putToCollection.none=<нет>
packEditor.putToCollection.createNew=коллекцию или {0}создать новую{1}. 
packEditor.images.search=Поиск изображений
packEditor.images.search.or=или
packEditor.images.upload=Загрузить
packEditor.images.remove=Удалить изображение
packEditor.images.emptySearch=Изображения не найдены. Попробуйте другой запрос.
packEditor.audio.record=Записать
packEditor.audio.remove=Удалить
packEditor.audio.upload=Загрузить
##
collectionForm.modal.title=Создать коллекцию
collectionForm.name=Название
collectionForm.name.placeholder=придумайте название коллекции
collectionForm.description=Описание
collectionForm.description.placeholder=придумайте описание
##
myPage.title.author=Мои личные карточки
myPage.title.lastPacks=Последние карточки
myPage.title.favourite=Мои отмеченные карточки
myPage.empty.last=Вашей активности в последнее время замечено не было
myPage.empty.author=Вами не создано ни одной карточки
myPage.empty.favourite=Вы не отметили ни одной карточки
##
packPage.header.from=от
packPage.buttonGroup.play=Игры
packPage.button.play=Обзор карточек
packPage.button.test=Тест
packPage.button.learn=Изучать
packPage.button.write=Написать
packPage.button.spell=Правописание
packPage.button.match=Подбор
packPage.button.live=Игра
packPage.solw.short=Быстрое повторение
packPage.solw.short.subtitle=Повторить набор карточек
packPage.solw.short.new=Новые:
packPage.solw.short.familiar=Знакомые:
packPage.solw.short.mastered=Выученные:
packPage.solw.long=Долговременное обучение
packPage.solw.long.subtitle=Интервальные повторения
packPage.solw.long.today=На сегодня
packPage.solw.long.new=Новые:
packPage.solw.long.review=Повторить:
packPage.button.memo=Запомнить
ppp0.title=Хотите быстро выучить этот набор?
packPage.dialogs.forbidden.alert=Необходимо авторизоваться
packPage.dialogs.forbidden.addToCollection.text=Вы можете добавить карточки в одну из ваших коллекций
packPage.dialogs.forbidden.addToCollection.alert=Доступно только для зарегистрированных пользователей
packPage.dialogs.forbidden.createNew.title=Добро пожаловать!
packPage.dialogs.forbidden.createNew.text=Создание новых карточек доступно только для авторизованных пользователей.
packPage.dialogs.forbidden.clone.text=Если вам подходят эти карточки для учебы, но вы хотите их дополнить, просто клонируйте их и создайте новый набор, который нужен именно вам.
packPage.dialogs.forbidden.clone.alert=Для клонирования требуется авторизоваться
packPage.dialogs.addToCollection.title=Добавление карточек в коллекцию
packPage.dialogs.addToCollection.notice=Вы можете добавить эти карточки в вашу коллекцию
packPage.dialogs.addToCollection.createNew= + создать коллекцию
packPage.dialogs.addToCollection.forbidden=Только зарегистрированные пользователи могут добавлять карточки в коллекции
packPage.dialogs.addToFavourites.title=Добавить в сохраненное
packPage.dialogs.addToFavourites.forbidden=Вы можете сохранить эти карточки, чтобы вернуться к ним позднее
packPage.dialogs.login=Войти / регистрация
packPage.dialogs.share.title=Поделиться
packPage.dialogs.clone.title=Клонировать
packPage.dialogs.clone.notice=Если вам подходят эти карточки для учебы, но вы хотите их дополнить, просто клонируйте их и создайте новый набор, который нужен именно вам.
packPage.command.edit=Изменить
packPage.command.customize=Изменить
packPage.command.addTo=Добавить в ...
packPage.command.addTo.collection=коллекцию
packPage.command.addTo.saved=сохраненные
packPage.command.share=Поделиться
packPage.list.sorting.original=Оригинальный
packPage.list.sorting.alphabetical=По алфавиту
packPage.list.filter.all=Все
packPage.list.filter.difficult=Сложные
packPage.list.filter.banned=Скрытые
packPage.list.filter.mastered=Выученные
packPage.list.filter.familiar=Знакомые
packPage.list.filter.starred=Помеченные
packPage.list.emptyMessage=Таких карт нет
packPage.list.loadMore=Показать остальные карточки
packPage.review=Повтор
packPage.startMemo=Добавить на панель обучения
packPage.startLearning=Начать обучение
packPage.speedReview=Быстрый повтор
packEmbed.clickToFlip=Посмотреть ответ
packPage.tooltips.memoShort=Режим быстрого обучения нужен для того, чтобы повторить какие-то слова или вопросы перед экзаменом. Подходит для небольших наборов.
packPage.tooltips.memoLong=Режим интервального повторения основан на методе интервального повторения. Полезен для планомерной подготовки к экзаменам и свободному длительному обучению.
packPage.tooltips.preview=Просмотр всех карточек набора без проверки.
packPage.tooltips.test=Создание теста для проверки своих знаний по этому набору.
packPage.tooltips.spell=Проверка правописания. Напишите правильно термин на слух.
##
packPlay.back=Назад
packPlay.options=Настройки
packPlay.progress=Пройдено
packPlay.progressTemplate={0} из {1}
packPlay.wrongDisplay.title=Неправильно
packPlay.wrongDisplay.label=Правильный ответ
packPlay.wrongDisplay.continueText=Продолжить
packPlay.wrongDisplay.userLabel=Ваш ответ
packPlay.wrongDisplay.typeText=Напишите правильный ответ внизу
##
packLearn.remaining=Осталось
packLearn.familiar=Знакомые
packLearn.mastered=Выученные
packLearn.write.placeholder=Напишите здесь ответ
packLearn.write.placeholderCopy=Напишите правильный ответ
packLearn.write.submit=Ответить
packLearn.write.skip=Пропустить
packLearn.write.stillCorrect=Я был прав
packLearn.write.giveup=Нет ответа
packLearn.dialogs.options.title=Настройки режима
packLearn.dialogs.options.questionTitle=Отвечать на
packLearn.dialogs.options.questionTerm=Термин
packLearn.dialogs.options.questionDefinition=Определение
packLearn.dialogs.options.questionError=Нужно выбрать по крайней мере один вариант
packLearn.dialogs.options.modeTitle=Тип ответа
packLearn.dialogs.options.modeSelect=Выбор
packLearn.dialogs.options.modeWrite=Письменно
packLearn.dialogs.options.modeError=Нужно выбрать по крайней мере один вариант
packLearn.dialogs.options.modeRandom=Случайный порядок
packLearn.dialogs.options.resetTitle=Сбросить прогресс
packLearn.dialogs.options.resetButton=Сброс
packLearn.dialogs.options.modeCards=Карточки
packLearn.dialogs.config.title=Настройки обучения
packLearn.dialogs.firstTime.title=Первоподача
packLearn.dialogs.firstTime.notice=Вы можете изменить эти настройки в меню «Опции» в любое время
packLearn.dialogs.options.writeAnyAnswer=Напишите любой ответ
packLearn.dialogs.options.writeAnyAnswer.notice=Вы можете написать только один из доступных ответов (которые разделены запятой).
packLearn.dialogs.options.playAudio=Проигрывать аудио
packLearn.dialogs.options.newLimit=Кол-во новых карточек
packLearn.dialogs.options.newLimitError=Это должно быть положительное число
packLearn.dialogs.options.order=Порядок новых карточек
packLearn.dialogs.options.order.original=Оригинальный
packLearn.dialogs.options.order.random=Случайный
packLearn.dialogs.options.advancedMode=Расширенный режим
packLearn.dialogs.options.advancedMode.notice=По умолчанию используется модифицированный алгоритм обучения повторениями, где достаточно выбрать только «Знаю» или «Не знаю». Если включить «расширенный режим», вы можете использовать классический алгоритм с пятью доступными вариантами, аналогичными SuperMemo или Анки.
##
searchPage.title=Поиск "{0}"
searchPage.head=Результаты поиска по запросу "{0}"
searchPage.display.packs=Показать карточки
searchPage.display.courses=Показать курсы
searchPage.noResults.packs=К сожалению, карточек не найдено
searchPage.noResults.courses=К сожалению, курсов не найдено
##
learnPage.progress.total=Прогресс
learnPage.progress.correct=Правильно
learnPage.progress.wrong=Неправильно
learnPage.answer.go=Ответить
learnPage.answer.placeholder=Напишите ответ
learnPage.answer.unknown=Я не знаю
learnPage.wrong.stillCorrect=Я ответил правильно!
learnPage.next=Следующий вопрос
learnPage.result.actual=Ваш ответ
learnPage.result.correct=Правильный ответ
learnPage.header.correct=Правильный ответ
learnPage.header.wrong=Неправильный ответ
learnPage.emptyQueue=Поздравляю! Вы пропустили все карты.
learnPage.restartQueue=Запустить снова
learnPage.reviewComplete.title=Поздравляю!
learnPage.reviewComplete.text=Вы пропустили все карты.
##
testSelectPage.opt.limit=Ограничение вопросов
testSelectPage.opt.first=Показать первым
testSelectPage.start=Начать тест
testSelectPage.opt.limit.prefix=Выбрать
testSelectPage.opt.limit.suffix=из {0}
testSelectPage.opt.first.face=Термин
testSelectPage.opt.first.back=Определение
testSelectPage.mode1=Подбор
testSelectPage.mode2=Верно / Неверно
testSelectPage.mode3=4 варианта
testSelectPage.mode4=Письменный
##
testResult.message=Ваш результат:
testResult.correctTitle=Правильный ответ
testResult.noAnswer=Вы ничего не выбрали
testResult.noAnswerWrite=Вы ничего не написали
testCheck.answer=Ответ:
testCheck.answer.yes=Верно
testCheck.answer.no=Неверно
testCheck.answer.correct=Верно!
testCheck.answer.wrong=Неверно!
testWrite.input.placeholder=Напишите свой вариант ответа
##
uknownPack.text=Может и были здесь какие-то карточки. Но сейчас нету ничего.
##
packTest.form.questionLimit=Количество вопросов
packTest.form.questionLimit.of=из
packTest.form.questionLimit.invalid=Неправильное количество вопросов
packTest.form.questionWith=Вопрос из
packTest.form.questionWith.notSelected=Нужно выбрать хотя бы один вариант
packTest.form.requireOne=Достаточно одного варианта
packTest.form.requireOne.explain=По крайней мере одного правильного ответа достаточно для прохождения вопроса. Ответы могут быть разделены слэшем (/), запятой (,) или точкой с запятой (;) 
packTest.form.types=Типы вопросов
packTest.form.types.notSelected=Нужно выбрать хотя бы один вариант
packTest.form.type.choice=Выбор
packTest.form.type.yn=Верно/неверно
packTest.form.type.written=Написание
packTest.form.type.matching=Соответствие
packTest.start=Начать тест
packTest.qsMatching=Вопросы для соответствия
packTest.qsMatching.expected=Правильный ответ:
packTest.qsWritten=Вопросы для письма
packTest.qsWritten.inputPlaceholder=напишите свой ответ здесь
packTest.qsWritten.expected=Правильный ответ:
packTest.qsChoice=Вопросы для выбора
packTest.qsChoice.expected=Правильный ответ:
packTest.qsYN=Вопросы "верно-неверно"
packTest.qsYN.true=Верно
packTest.qsYN.false=Неверно
packTest.qsYN.expected=Правильный ответ:
packTest.checkAnswers=Проверить ответы
packTest.score.prefix=Результат теста:
packTest.score.90=Великолепно!
packTest.score.75=Хорошо!
packTest.score.50=Средне
packTest.score.25=Ниже среднего
packTest.score.0=Слабо
##
columns.cardsInPack=Canti.
##
fexplorer.page.title={0} каталог карточек
fexplorer.page.categoryTitle={0} карточки
fexplorer.title=Каталог карточек
fexplorer.popularsCategories=Популярные
fexplorer.language=Мой язык
fexplorer.allCategories=Все категории
fexplorer.collections.section=Коллекции
fexplorer.flashcards.section=Карточки
fexplorer.flashcards.terms=карт
fexplorer.empty.message=Пожалуйста, выберите категорию <br> или <br>
fexplorer.empty.search=Поиск
##
packPlay.progressBar.title=Прогресс
packPlay.head.play=Карточки
packPlay.head.learn=Учиться
packPlay.head.review=Повтор
packPlay.head.test=Тест
packPlay.head.write=Написать
packPlay.head.memo=Запомнить
packPlay.head.spell=Произношение
packPlay.head.match=Подбор
##
packMemo.showAnswer=Показать ответ
packMemo.sessionComplete=Нет больше карт для сегодняшнего дня!
packMemo.sessionNextTime=Вернитесь через {0}.
packMemo.grades.button.H=Сложно
packMemo.grades.button.E=Легко
packMemo.grades.button.M=Средне
packMemo.grades.button.1=Плохо
packMemo.grades.button.2=Слабо
packMemo.grades.button.3=Средне
packMemo.grades.button.4=Хороший
packMemo.grades.button.5=Супер
packMemo.grades.button.Y=Усвоено!
packMemo.grades.button.R=Знаю
packMemo.grades.button.F=Не знаю
packMemo.short.days=д
packMemo.short.months=мес
packMemo.short.hours=ч
packMemo.welcome.title=Панель долговременного обучения
packMemo.welcome.text=Это планировщик повторений. Как только вы начинаете процесс обучения вы будете учить по 20 карт каждый день. Частота показа карты зависит от ваших ответов. Этот режим основан на известном алгоритме, который называется СМ-2. <br> <a href="https://en.wikipedia.org/wiki/SuperMemo"> Подробнее. </a>
packMemo.welcome.start=Давайте начнем
packMemo.welcome.login=К сожалению, этот режим доступен только для авторизованных пользователей. <br> Пожалуйста, <a href="/login"> войдите в </a> или <a href="/registration"> зарегистрируйтесь</a>. <br> Это легко и абсолютно бесплатно.
packMemo.welcomeComplete.header=Набор был добавлен на панель долговременного обучения.
packMemo.welcomeComplete.html=<p>Вы можете учить набор:</p>\n<ul>\n<li>На сайте Studylib.net</li>\n<li>В расширении для браузера Google Chrome</li>\n</ul>
packMemo.welcomeComplete.learn=Начать обучение
packMemo.welcomeComplete.back=Завершить
packMemo.help.title=Подсказка
packMemo.help.line1=Пробел - чтобы показать ответ
packMemo.help.line2=1,2,3,4,5 - чтобы сделать свой выбор
packMemo.help.hide=Скрыть
packMemo.end.freshAvailable=Хотите увидеть больше новых карт сегодня?
packMemo.end.freshAccept=Да, покажи мне больше
##
fl.matchGame.welcome.text=Перетащите элементы друг на друга, чтобы они исчезли
fl.matchGame.startGame=Начать игру
fl.matchGame.timer=Время
fl.matchGame.preset.number=Кол-во карт
fl.matchGame.result.title=Поздравляем!
fl.matchGame.result.text=Вы справились за {0} секунд
fl.matchGame.result.again=Играть снова
##
fl.memoHeatMap.title=История обучения
fl.memoHeatMap.legend.daverage=Среднем в день:
fl.memoHeatMap.legend.daverage.value={0} {0,plural,one{карточка}few{карточки}other{карточек}}
fl.memoHeatMap.legend.dlearned=Покрытие дней:

fl.memoHeatMap.legend.beststreak=Лучший период:
fl.memoHeatMap.legend.beststreak.value={0} {0,plural,one{день}few{дня}other{дней}}
fl.memoHeatMap.legend.currentstreak=Текущий период:
fl.memoHeatMap.legend.currentstreak.value={0} {0,plural,one{день}few{дня}other{дней}}
fl.memoHeatMap.tooltip.passed={0} {0,plural,one{правильный ответ}few{правильных ответа}other{правильных ответов}}
fl.memoHeatMap.tooltip.failed={0} {0,plural,one{неправильный ответ}few{неправильных ответа}other{неправильных ответов}}
fl.memoHeatMap.tooltip.fresh={0} {0,plural,one{новая карточка}few{новых карточки}other{новых карточек}}
fl.memoHeatMap.tooltip.date={0}
##
cardButtons.ban.title=Запретить эту карту
cardButtons.edit.title=Исправить эту карту
cardButtons.star.title=Переключите любимую карту
cardButtons.ban.prompt=Игнорировать эту карту?
##
validation.cardText.tooLong=Максимальная длина этого текста составляет 1000 символов
##
extLocale.la=Латынь
extLocale.rw=Руанда
extLocale.af=Африкаанс
extLocale.am=Амхарский
extLocale.az=Азербайджанский
extLocale.bn=Бенгальский
extLocale.bs=Боснийский
extLocale.ceb=Себуанский
extLocale.co=Корсиканский
extLocale.cy=Валлийский
extLocale.eo=Эсперанто
extLocale.eu=Баскский
extLocale.fa=Персидский
extLocale.fy=Фризский
extLocale.gd=Шотландский (гэльский)
extLocale.gl=Галисийский
extLocale.gu=Гуджарати
extLocale.ha=Хауса
extLocale.haw=Гавайский
extLocale.hmn=Хмонг
extLocale.ht=Креольский (Гаити)
extLocale.hy=Армянский
extLocale.id=Индонезийский
extLocale.ig=Игбо
extLocale.jw=Яванский
extLocale.ka=Грузинский
extLocale.kk=Казахский
extLocale.km=Кхмерский
extLocale.kn=Каннада
extLocale.ku=Курманджи
extLocale.ky=Киргизский
extLocale.lb=Люксембургский
extLocale.lo=Лаосский
extLocale.mg=Малагасийский
extLocale.mi=Маори
extLocale.ml=Малаялам
extLocale.mn=Монгольский
extLocale.mr=Маратхи
extLocale.my=Бирманский
extLocale.ne=Непальский
extLocale.ny=Чева
extLocale.or=Ория
extLocale.pa=Панджаби
extLocale.ps=Пушту
extLocale.sd=Синдхи
extLocale.si=Сингальский
extLocale.sm=Самоанский
extLocale.sn=Шона
extLocale.so=Сомалийский
extLocale.st=Сесото
extLocale.su=Суданский
extLocale.sw=Суахили
extLocale.ta=Тамильский
extLocale.te=Телугу
extLocale.tg=Таджикский
extLocale.tk=Туркменский
extLocale.tl=Филиппинский
extLocale.tt=Татарский
extLocale.ug=Уйгурский
extLocale.ur=Урду
extLocale.uz=Узбекский
extLocale.xh=Кхоса
extLocale.yi=Идиш
extLocale.yo=Йоруба
extLocale.zu=Зулу


# landing
fl.landing.title=Обучение с помощью {0}
fl.landing.title.small=Узнайте, почему надо попробовать {0}
fl.landing.why1.title=Это легко и весело
fl.landing.why1.text=Тысячи школьников и студентов каждый день выполняют учебный задания на {0}, улучшая уровень своих оценок. На диване, в метро, в очереди - всегда найдется несколько минут на учебу.
fl.landing.why2.title=Шесть режимов обучения
fl.landing.why2.text=Подбирайте те инструменты, которые лучше подходят к той информации, которую вам требуется выучить.
fl.landing.why3.title=Долговременное обучение
fl.landing.why3.text=Основанный на изучении психологии метод интервальных повторений позволяет запоминать большие объемы информации без экстраординарных усилий. Просто занимайтесь по 10-15 минут в день.
fl.landing.why4.title=На любом устройстве
fl.landing.why4.text=Занимайтесь на том устройстве, которое есть у вас под рукой. Не важно, компьютер, планшет или телефон - наши инструменты под них оптимизированы.
fl.landing.create.title=Создавайте свои наборы карточек
fl.landing.create1.title=Создавая запоминай
fl.landing.create1.text=Для подготовки набора приходится структурировать и упрощать информацию. Это один из лучших способов освоить материал. В дальнейшем Вам потребуется только обновлять воспоминания.
fl.landing.create2.title=Удобный импорт
fl.landing.create2.text=Воспользуйтесь гибким инструментом импорта карточек из текстовых файлов для того, чтобы загрузить наборы из других источников. Так же Вы можете импортировать ANKI-файл.
fl.landing.create3.title=Изменяйте
fl.landing.create3.text=Выберите из нашей базы подходящий набор, измените и дополните его в соответствии с Вашим учебным планом.
fl.landing.create.button=Создать набор
fl.landing.gallery=Примеры наборов
fl.landing.gallery.others=Посмотреть другие примеры
fl.landing.stars1=3 миллиона<br>учеников ежемесячно
fl.landing.stars2=100 000 000+<br>учебных сеансов
fl.landing.stars3=1 000 000+<br>учебных наборов
fl.landing.reg1=Присоединяйтесь и пользуйтесь интервальным обучением образовательных карточек, учебными коллекциями и полезными образовательными документами.
fl.landing.reg2=Нет аккаунта в Google или Facebook? Не беда! Зарегистрируйтесь с помощью e-mail
## fast add card










## uninstall page
fl_uninstall_title=Спасибо за использование Studyylib расширение.
fl_uninstall_subtitle=Нам жаль, что вы уходите! Если у вас есть момент, пожалуйста, сообщите нам, как мы можем сделать расширение лучше:
fl_uninstall_notice=Ваш отзыв помогает нам улучшить продукт для всех, спасибо 😊
fl_uninstall_button=Послать
fl_uninstall_success=До скорого!

## APPEND ITEMS ABOVE THIS LINE