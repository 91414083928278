import * as EN from '../../../../memtrick-backend/src/main/resources/memtrick/ui.properties';
import * as DE from '../../../../memtrick-backend/src/main/resources/memtrick/ui_de.properties';
import * as ES from '../../../../memtrick-backend/src/main/resources/memtrick/ui_es.properties';
import * as FR from '../../../../memtrick-backend/src/main/resources/memtrick/ui_fr.properties';
import * as ID from '../../../../memtrick-backend/src/main/resources/memtrick/ui_id.properties';
import * as IT from '../../../../memtrick-backend/src/main/resources/memtrick/ui_it.properties';
import * as PT from '../../../../memtrick-backend/src/main/resources/memtrick/ui_pt.properties';
import * as RU from '../../../../memtrick-backend/src/main/resources/memtrick/ui_ru.properties';

const languages: { [key: string]: any } = {'en': EN, 'de': DE, 'es': ES, 'fr': FR, 'id': ID, 'it': IT, 'pt': PT, 'ru': RU};

function useLanguage(code: string) {
    if (code in languages) {
        (window as any).FlashcardsL18N = (function (l) {
            const translations = languages[code];
            return {
                ...l,
                ...translations,
                _language: code,
            };
        })(typeof FlashcardsL18N === 'undefined' ? {} : (FlashcardsL18N || {}))
    }
}

useLanguage('en')
useLanguage(document.documentElement.lang)