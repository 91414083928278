
defsPackEditor.title.placeholder=Introduzca el título del juego de cartas (tema o algo)
defsPackEditor.description.placeholder=Introduzca breve descripción del juego de cartas
defsPackEditor.title.create=Crear nuevas flashcards
defsPackEditor.title.save=Editar flashcards
defsPackEditor.addPackImage=Añadir imagen
visibility.label=Visible a
visibility.0=Solo yo
visibility.1=Todo el mundo
## не удаляйте этот блок, на него есть ссылки в {plurals}
pl.card.one=tarjeta
pl.card.few=tarjetas
pl.card.other=tarjetas
pl.day.one=día
pl.day.few=dias
pl.day.other=dias
pl.passedReview.one=revisión aprobada
pl.passedReview.few=revisiones aprobadas
pl.passedReview.other=revisiones aprobadas
pl.failedReview.one=revisión fallida
pl.failedReview.few=revisiones fallidas
pl.failedReview.other=revisiones fallidas
pl.freshCard.one=tarjeta fresca
pl.freshCard.few=Tarjetas Fresh
pl.freshCard.other=Tarjetas Fresh
pl.hours.one=hora
pl.hours.few=horas
pl.hours.other=horas
pl.hours={0} {0,plural,one{hora}few{horas}other{horas}}
pl.days.one=día
pl.days.few=dias
pl.days.other=dias
pl.days={0} {0,plural,one{día}few{dias}other{dias}}
pl.minutes.one=minuto
pl.minutes.few=minutos
pl.minutes.other=minutos
pl.minutes={0} {0,plural,one{minuto}few{minutos}other{minutos}}
## validation
rubd.flashcards.services.validation.SideTextWhenCardIsNotEmpty.message=La tarjeta no está vacía, necesitas un mensaje de texto para este lado.
##
button.save=Salvar
button.saveContinue=Guardar y continuar
button.create=Crear
button.cancel=Cancelar
button.clone=Clon
button.print=Imprimir
button.checkTest=Comprobar resultados
button.yes=sí
button.close=Cerrar
button.ok=OK
button.help=Ayudar
##
title.label=Título
title.description=Descripción
title.optional=(Opcional)
## duration messages
fl.agoString={0} hace
fl.agoRecently=Recientemente
fl.timeSpent={0} gastado
fl.timeSpentLessThanMinute=Pasé un poco
##
pack.cardCount={0} tarjetas
pack.cardCount.one={0} tarjeta
pack.cardCount.many={0} tarjetas
pack.cardCount.few={0} tarjetas
##
page.defaultTitle=FlashCards en línea
## user private dashboard
fl.mainDashboard.packAgo.title=Tiempo transcurrido desde la última visita.
fl.mainDashboard.packSpent.title=Tiempo dedicado a aprender
fl.mainDashboard.hideMemoPack=Este conjunto se eliminará del panel de aprendizaje a largo plazo. Para agregarlo, deberá hacer clic en "Iniciar aprendizaje" en la página SET.
##
packEditor.form.termLanguage=Lenguaje a plazo
packEditor.form.termLanguage.placeholder=Elige lengua
packEditor.form.definitionLanguage=Lenguaje de definición
packEditor.form.definitionLanguage.placeholder=Elige lengua
packEditor.form.loading=Lista de cartas de carga ...
packEditor.form.cardMinimum=Por favor, complete al menos dos flashcards
packEditor.importer.open=Importación de TXT, Word, Excel, Google Docs, etc.
packEditor.importer.close=Cerrar
packEditor.importer.import=Importar
packEditor.importer.textLabel=Copie y pegue texto aquí (desde Word, Excel, Google Docs, etc.)
packEditor.importer.textPlaceholder=Palabra 1   Definición 1\nPalabra 2   Definición 2\nPalabra 3   Definición 3
packEditor.importer.result=Se importaron {0} entradas
packEditor.importer.preview.title=Vista previa de tarjetas
packEditor.importer.preview.empty=No se han reconocido tarjetas. Compruebe la configuración de importación en el panel derecho.
packEditor.anki.button=Importar Anki
packEditor.anki.dialog.message=Sube su archivo .apkg para importar su cubierta de tarjetas de anki.
packEditor.anki.dialog.notice1=Algunas cubiertas no pueden ser importadas o pueden ser importadas con errores. Depende de la estructura de la cubierta. Lamentamos eso.
packEditor.anki.dialog.notice2=Puede importar sus propias cubiertas o cubiertas que tiene derecho a copiar y modificar.
packEditor.anki.dialog.title=Importar Anki Flashcards
packEditor.anki.dialog.button=Navegar
packEditor.anki.progress.sending=Enviando archivo ...
packEditor.anki.progress.parsing=Archivo de análisis ...
packEditor.anki.progress.forming=Insertar el resultado en forma ...
packEditor.anki.progress.error=La importación falló.
packEditor.anki.progress.success=Operación completa. Se han agregado nuevas tarjetas a la lista a continuación.
packEditor.addRow=Agregar tarjeta
packEditor.card.term.placeholder=Término
packEditor.card.term.needText=Necesita texto aquí
packEditor.card.def.placeholder=Definición
packEditor.card.def.needText=Necesita texto aquí
packEditor.importer.form.mode=Modo de importación
packEditor.importer.form.mode.everyLine=Término y definición en cada línea, separados por delimitador.
packEditor.importer.form.mode.lineByLine=El término y la definición son secuenciales, un elemento por línea.
packEditor.importer.form.everyLine1=Delimitador de entrada (línea)
packEditor.importer.form.everyLine2=Término / definición delimitador
packEditor.importer.form.everyLine3=Opciones adicionales
packEditor.importer.form.ldLine=Envoltura de línea
packEditor.importer.form.ldDoubleLine=Doble línea de envoltura
packEditor.importer.form.ldCustom=Símbolo personalizado
packEditor.importer.form.ldJoinLowercase=Únete a la línea que comienza con un símbolo en minúscula
packEditor.importer.form.tdDash=Dash (-)
packEditor.importer.form.tdTab=Lengüeta
packEditor.importer.form.tdCustom=Símbolo personalizado
packEditor.importer.form.lineByLine1=Opciones adicionales
packEditor.importer.form.llDoubleLine=Doble línea de envoltura entre cada grupo
packEditor.importer.form.llJoinLowercase=Únete a la línea que comienza con un símbolo en minúscula
packEditor.splitNewCards.notice=Parece que tienes muchas cartas. Tal vez sea razonable dividir las tarjetas en varios juegos
packEditor.splitNewCards.checkbox.1=Sí, dividir las cartas en
packEditor.splitNewCards.checkbox.2=piezas en cada set
packEditor.splitNewCards.shuffle=Y baraja las cartas antes de dividirlas.
packEditor.splitNewCards.suffix=Parte {0}.
packEditor.putToCollection=Añadir paquete a
packEditor.putToCollection.none=<none>
packEditor.putToCollection.createNew=colección o {0}crear nuevo{1}.
packEditor.images.search=Buscar imágenes
packEditor.images.search.or=o
packEditor.images.upload=Subir
packEditor.images.remove=Quita la imagen
packEditor.images.emptySearch=No se han encontrado imágenes. Prueba otra consulta.
packEditor.audio.record=Registro
packEditor.audio.remove=Eliminar
packEditor.audio.upload=Subir
##
collectionForm.modal.title=Crear nueva colección
collectionForm.name=Nombre
collectionForm.name.placeholder=Escriba el nombre de la colección
collectionForm.description=Descripción
collectionForm.description.placeholder=Tipo de descripción de la colección
##
myPage.title.author=Mis propios sets
myPage.title.lastPacks=Mis ultimos sets
myPage.title.favourite=Mis sets favoritos
myPage.empty.last=No has visitado ningún set.
myPage.empty.author=No has creado ningún conjunto.
myPage.empty.favourite=Tu lista de favoritos está vacía
##
packPage.header.from=por
packPage.buttonGroup.play=Jugar
packPage.button.play=Tarjetas
packPage.button.test=Prueba
packPage.button.learn=Aprender
packPage.button.write=Escribir
packPage.button.spell=Deletrear
packPage.button.match=Fósforo
packPage.button.live=Vivir
packPage.solw.short=Aprendizaje rápido
packPage.solw.short.subtitle=Repite este conjunto de flashcards
packPage.solw.short.new=Nuevo:
packPage.solw.short.familiar=Familiar:
packPage.solw.short.mastered=Dominado:
packPage.solw.long=Aprendizaje a largo plazo
packPage.solw.long.subtitle=Repetición espaciada
packPage.solw.long.today=Tarea de hoy
packPage.solw.long.new=Nuevo:
packPage.solw.long.review=Para revisar:
packPage.button.memo=Memorándum
ppp0.title=¿Quieres aprender este conjunto rápidamente?
packPage.dialogs.forbidden.alert=Disponible solo para usuarios autorizados
packPage.dialogs.forbidden.addToCollection.text=Puedes añadir este conjunto a una de tus colecciones.
packPage.dialogs.forbidden.addToCollection.alert=Disponible solo para usuarios autorizados
packPage.dialogs.forbidden.createNew.title=¡Bienvenido!
packPage.dialogs.forbidden.createNew.text=Solo los usuarios autorizados pueden crear nuevas tarjetas.
packPage.dialogs.forbidden.clone.text=Si usa estas tarjetas para estudiar, pero desea complementarlas, solo clónelas y cree un nuevo conjunto que necesite.
packPage.dialogs.forbidden.clone.alert=Disponible solo para usuarios autorizados
packPage.dialogs.addToCollection.title=Añadir este conjunto a la colección
packPage.dialogs.addToCollection.notice=Puedes agregar este conjunto de flashcards a tus colecciones de estudio
packPage.dialogs.addToCollection.createNew= + Añadir nueva colección
packPage.dialogs.addToCollection.forbidden=Solo usuarios autorizados pueden agregar tarjetas a sus cursos.
packPage.dialogs.addToFavourites.title=Añadir este conjunto a guardado
packPage.dialogs.addToFavourites.forbidden=Puede agregar guardar este conjunto para volver más tarde
packPage.dialogs.login=Iniciar sesión / registrarse
packPage.dialogs.share.title=Comparte este set
packPage.dialogs.clone.title=Clonar este conjunto
packPage.dialogs.clone.notice=Si usa estas tarjetas para estudiar, pero desea complementarlas, solo clónelas y cree un nuevo conjunto que necesite.
packPage.command.edit=Editar
packPage.command.customize=Personalizar
packPage.command.addTo=Añadir ...
packPage.command.addTo.collection=colección
packPage.command.addTo.saved=salvado
packPage.command.share=Compartir
packPage.list.sorting.original=Original
packPage.list.sorting.alphabetical=Alfabético
packPage.list.filter.all=Mostrar todo
packPage.list.filter.difficult=Difícil
packPage.list.filter.banned=Ignorado
packPage.list.filter.mastered=Dominado
packPage.list.filter.familiar=Familiar
packPage.list.filter.starred=Sembrado de estrellas
packPage.list.emptyMessage=Ninguna tarjeta se ajusta a este filtro.
packPage.list.loadMore=Ver mas tarjetas
packPage.review=Revisar
packPage.startMemo=Añadir a escritorio de aprendizaje a largo plazo
packPage.startLearning=Comienza a aprender
packPage.speedReview=Revisión de velocidad
packEmbed.clickToFlip=Haga clic para voltear
packPage.tooltips.memoShort=Un modo de aprendizaje rápido es útil cuando necesita repetir algunas palabras o preguntas antes del examen. Adecuado para pequeños conjuntos de palabras.
packPage.tooltips.memoLong=El modo de aprendizaje de repetición espaciado se basa en el método de repetición de intervalo. Es útil para la preparación sistemática del examen y la educación a largo plazo.
packPage.tooltips.preview=Ver todos los conjuntos de tarjetas sin controlarte.
packPage.tooltips.test=Cree una prueba para verificar su conocimiento en un conjunto de cartas.
packPage.tooltips.spell=Corrector ortográfico. Escuchando y escribiendo la prueba.
##
packPlay.back=Espalda
packPlay.options=Opciones
packPlay.progress=Progreso
packPlay.progressTemplate={0} de {1}
packPlay.wrongDisplay.title=Respuesta incorrecta
packPlay.wrongDisplay.label=Respuesta correcta
packPlay.wrongDisplay.continueText=Pulse cualquier tecla para continuar
packPlay.wrongDisplay.userLabel=Tu escribiste
packPlay.wrongDisplay.typeText=Escriba la respuesta correcta a continuación
##
packLearn.remaining=Restante
packLearn.familiar=Familiar
packLearn.mastered=Dominado
packLearn.write.placeholder=escribe tu respuesta aquí
packLearn.write.placeholderCopy=Copia la respuesta correcta aquí
packLearn.write.submit=Responder
packLearn.write.skip=Saltar
packLearn.write.stillCorrect=yo tenía razón
packLearn.write.giveup=Rendirse
packLearn.dialogs.options.title=Opciones
packLearn.dialogs.options.questionTitle=Responder con
packLearn.dialogs.options.questionTerm=Término
packLearn.dialogs.options.questionDefinition=Definición
packLearn.dialogs.options.questionError=Al menos una casilla debe estar marcada.
packLearn.dialogs.options.modeTitle=Tipos de preguntas
packLearn.dialogs.options.modeSelect=Opción multiple
packLearn.dialogs.options.modeWrite=Escrito
packLearn.dialogs.options.modeError=Al menos una casilla debe estar marcada.
packLearn.dialogs.options.modeRandom=Orden aleatorio
packLearn.dialogs.options.resetTitle=Restablecer el progreso
packLearn.dialogs.options.resetButton=Reiniciar
packLearn.dialogs.options.modeCards=Flashcards
packLearn.dialogs.config.title=Opciones de memo
packLearn.dialogs.firstTime.title=Configuración por primera vez
packLearn.dialogs.firstTime.notice=Puede cambiar estas configuraciones en el menú "Opciones" en cualquier momento
packLearn.dialogs.options.writeAnyAnswer=Escribe cualquier respuesta
packLearn.dialogs.options.writeAnyAnswer.notice=Puede escribir solo una de las respuestas disponibles (que están separadas por coma).
packLearn.dialogs.options.playAudio=Jugar audio
packLearn.dialogs.options.newLimit=Límite de tarjetas nuevas
packLearn.dialogs.options.newLimitError=Debe ser un número positivo, menos o igual a 50.
packLearn.dialogs.options.order=Orden de nuevo
packLearn.dialogs.options.order.original=Original
packLearn.dialogs.options.order.random=Aleatorio
packLearn.dialogs.options.advancedMode=Modo avanzado
packLearn.dialogs.options.advancedMode.notice=Por defecto "Aprendizaje a largo plazo" usa un algoritmo de repetición espaciado de aprendizaje modificado donde es suficiente para elegir solo "saber" o "no sé". Al cambiar al "Modo avanzado", puede usar un algoritmo clásico con cinco opciones disponibles, similar a SuperMemo o Anki.
##
searchPage.title=Buscar "{0}"
searchPage.head=Resultados para la consulta "{0}"
searchPage.display.packs=Mostrar conjuntos
searchPage.display.courses=Mostrar cursos
searchPage.noResults.packs=Lo sentimos, no se ha encontrado ningún conjunto
searchPage.noResults.courses=Lo sentimos, no se han encontrado cursos
##
learnPage.progress.total=Pasado
learnPage.progress.correct=Correcto
learnPage.progress.wrong=Incorrecto
learnPage.answer.go=Responder
learnPage.answer.placeholder=Escribe tu respuesta
learnPage.answer.unknown=No lo sé
learnPage.wrong.stillCorrect=Mi respuesta es correcta!
learnPage.next=Siguiente
learnPage.result.actual=Tu respuesta
learnPage.result.correct=Respuesta correcta
learnPage.header.correct=Respuesta correcta
learnPage.header.wrong=Respuesta incorrecta
learnPage.emptyQueue=¡Felicidades! Has pasado todas las cartas.
learnPage.restartQueue=Reiniciar
learnPage.reviewComplete.title=¡Felicidades!
learnPage.reviewComplete.text=Has pasado todas las cartas.
##
testSelectPage.opt.limit=Límite de preguntas
testSelectPage.opt.first=Show
testSelectPage.start=Iniciar prueba
testSelectPage.opt.limit.prefix=Utilizar
testSelectPage.opt.limit.suffix=de {0}
testSelectPage.opt.first.face=Término
testSelectPage.opt.first.back=Definición
testSelectPage.mode1=Partido
testSelectPage.mode2=Si/no
testSelectPage.mode3=4 opciones
testSelectPage.mode4=Escribir
##
testResult.message=Tu puntuación:
testResult.correctTitle=Respuesta esperada
testResult.noAnswer=No seleccionas nada
testResult.noAnswerWrite=No escribes nada
testCheck.answer=Responder:
testCheck.answer.yes=Correcto
testCheck.answer.no=Incorrecto
testCheck.answer.correct=¡Correcto!
testCheck.answer.wrong=¡Incorrecto!
testWrite.input.placeholder=Escriba su respuesta aquí
##
uknownPack.text=Tal vez teníamos este set antes ... Desafortunadamente, ya no está disponible :(
##
packTest.form.questionLimit=Límite de preguntas
packTest.form.questionLimit.of=de
packTest.form.questionLimit.invalid=El recuento de preguntas no es válido.
packTest.form.questionWith=Pregunta con
packTest.form.questionWith.notSelected=Se requiere al menos una opción.
packTest.form.requireOne=Requerir solo una respuesta
packTest.form.requireOne.explain=Se requiere al menos una respuesta correcta. Las respuestas deben estar separadas por una barra (/), una coma (,) o un punto y coma (;)
packTest.form.types=Tipos de preguntas
packTest.form.types.notSelected=Se requiere al menos una opción.
packTest.form.type.choice=Opción multiple
packTest.form.type.yn=Verdadero/Falso
packTest.form.type.written=Escrito
packTest.form.type.matching=Pareo
packTest.start=Iniciar prueba
packTest.qsMatching=Preguntas para emparejar
packTest.qsMatching.expected=La respuesta correcta es:
packTest.qsWritten=Preguntas para escribir
packTest.qsWritten.inputPlaceholder=escriba su respuesta aquí
packTest.qsWritten.expected=La respuesta correcta es:
packTest.qsChoice=Preguntas para elegir
packTest.qsChoice.expected=La respuesta correcta es:
packTest.qsYN=Preguntas para verdadero / falso
packTest.qsYN.true=Cierto
packTest.qsYN.false=Falso
packTest.qsYN.expected=La respuesta correcta es:
packTest.checkAnswers=Revisar respuestas
packTest.score.prefix=Resultado de la prueba:
packTest.score.90=¡Excelente!
packTest.score.75=¡Bueno!
packTest.score.50=Promedio
packTest.score.25=Pobre
packTest.score.0=Débiles
##
columns.cardsInPack=Canti.
##
fexplorer.page.title={0} flashcards explorer
fexplorer.page.categoryTitle={0} tarjetas
fexplorer.title=Catálogo de tarjetas
fexplorer.popularsCategories=Categorías Populares
fexplorer.language=Mi idioma
fexplorer.allCategories=Todas las categorias
fexplorer.collections.section=Colecciones
fexplorer.flashcards.section=Fichas
fexplorer.flashcards.terms=término
fexplorer.empty.message=Por favor seleccione una categoría <br> o <br>
fexplorer.empty.search=Buscar algo
##
packPlay.progressBar.title=Progreso
packPlay.head.play=Flashcards
packPlay.head.learn=Aprender
packPlay.head.review=Revisar
packPlay.head.test=Prueba
packPlay.head.write=Escribir
packPlay.head.memo=Memorándum
packPlay.head.spell=Deletrear
packPlay.head.match=Fósforo
##
packMemo.showAnswer=Mostrar respuesta
packMemo.sessionComplete=¡No hay más tarjetas para hoy!
packMemo.sessionNextTime=Volver en {0}.
packMemo.grades.button.H=Difícilmente
packMemo.grades.button.E=Fácil
packMemo.grades.button.M=Medio
packMemo.grades.button.1=Malo
packMemo.grades.button.2=Débil
packMemo.grades.button.3=Medio
packMemo.grades.button.4=Bien
packMemo.grades.button.5=Fuerte
packMemo.grades.button.Y=Ok lo recuerdo
packMemo.grades.button.R=sé
packMemo.grades.button.F=No sé
packMemo.short.days=d
packMemo.short.months=me
packMemo.short.hours=h
packMemo.welcome.title=Escritorio de aprendizaje a largo plazo
packMemo.welcome.text=Es programador de repetición espaciado. Una vez que inicie el proceso de aprendizaje, aprenderá 20 cartas todos los días. La frecuencia de la tarjeta que muestra depende de tus respuestas. Este modo se basa en el famoso algoritmo de repetición espaciado llamado SM-2. <br> <a href="https://en.wikipedia.org/wiki/supermemo"> Leer más. </a>
packMemo.welcome.start=Empecemos
packMemo.welcome.login=Desafortunadamente, este modo está disponible solo para usuarios autorizados. <br> Por favor, <a href="/login"> Iniciar sesión </a> o <a href="/registration"> Registrarse </a>. <br> es fácil y absolutamente gratis.




packMemo.help.title=Insinuación
packMemo.help.line1=Barra espaciadora para mostrar respuesta
packMemo.help.line2=1,2,3,4,5 para hacer su elección
packMemo.help.hide=Esconder
packMemo.end.freshAvailable=¿Quieres ver más cartas nuevas hoy?
packMemo.end.freshAccept=Sí, muéstrame más.
##
fl.matchGame.welcome.text=Arrastre los elementos uno en el otro para hacerlos disapparar
fl.matchGame.startGame=Empezar juego
fl.matchGame.timer=Tiempo
fl.matchGame.preset.number=Contar
fl.matchGame.result.title=¡Felicidades!
fl.matchGame.result.text=Tu puntuación es {0} segundos
fl.matchGame.result.again=Juega de nuevo
##
fl.memoHeatMap.title=Tu historia de aprendizaje
fl.memoHeatMap.legend.daverage=Promedio diario:
fl.memoHeatMap.legend.daverage.value={0} {0,plural,one{tarjeta}few{tarjetas}other{tarjetas}}
fl.memoHeatMap.legend.dlearned=Días aprendidos:

fl.memoHeatMap.legend.beststreak=La mejor racha:
fl.memoHeatMap.legend.beststreak.value={0} {0,plural,one{día}few{dias}other{dias}}
fl.memoHeatMap.legend.currentstreak=Racha actual:
fl.memoHeatMap.legend.currentstreak.value={0} {0,plural,one{día}few{dias}other{dias}}
fl.memoHeatMap.tooltip.passed={0} {0,plural,one{revisión aprobada}few{revisiones aprobadas}other{revisiones aprobadas}}
fl.memoHeatMap.tooltip.failed={0} {0,plural,one{revisión fallida}few{revisiones fallidas}other{revisiones fallidas}}
fl.memoHeatMap.tooltip.fresh={0} {0,plural,one{tarjeta fresca}few{Tarjetas Fresh}other{Tarjetas Fresh}}
fl.memoHeatMap.tooltip.date=en {0}
##
cardButtons.ban.title=Prohibir esta tarjeta
cardButtons.edit.title=Corregir esta tarjeta
cardButtons.star.title=Toggle tarjeta favorita
cardButtons.ban.prompt=Ignora esta tarjeta?
##
validation.cardText.tooLong=La longitud máxima de este texto es de 1000 caracteres.
##
extLocale.la=latín
extLocale.rw=Kinyarwanda
extLocale.af=africaans
extLocale.am=Amárico
extLocale.az=Azerbaiyano
extLocale.bn=Bengala
extLocale.bs=bosnio
extLocale.ceb=Cebuano
extLocale.co=corso
extLocale.cy=galés
extLocale.eo=esperanto
extLocale.eu=vasco
extLocale.fa=persa
extLocale.fy=frisio
extLocale.gd=Escoceses (gaélico)
extLocale.gl=gallego
extLocale.gu=Gujarati
extLocale.ha=casa
extLocale.haw=hawaiano
extLocale.hmn=Hmong
extLocale.ht=CREOLE (Haití)
extLocale.hy=armenio
extLocale.id=indonesio
extLocale.ig=Igbo
extLocale.jw=javanés
extLocale.ka=georgiano
extLocale.kk=Kazakh
extLocale.km=Khmer
extLocale.kn=Kannada
extLocale.ku=Kurmanji
extLocale.ky=Kirguisa
extLocale.lb=Luxemburgo
extLocale.lo=Laotiana
extLocale.mg=madagascarí
extLocale.mi=maorí
extLocale.ml=Malayalam
extLocale.mn=mongol
extLocale.mr=Marathi
extLocale.my=birmano
extLocale.ne=Nepalí
extLocale.ny=Ceva
extLocale.or=Oria
extLocale.pa=punjabi
extLocale.ps=Pashto
extLocale.sd=Sindhi
extLocale.si=Cingalés
extLocale.sm=Samoano
extLocale.sn=Shona
extLocale.so=somalí
extLocale.st=Sesotho
extLocale.su=sudanés
extLocale.sw=swahili
extLocale.ta=Tamil
extLocale.te=Telugu
extLocale.tg=Tajik
extLocale.tk=Turkmen
extLocale.tl=filipino
extLocale.tt=Tártaro
extLocale.ug=uigur
extLocale.ur=Urdu
extLocale.uz=Uzbeko
extLocale.xh=Xhosa
extLocale.yi=yídish
extLocale.yo=Yoruba
extLocale.zu=zulú


# landing
fl.landing.title=Aprendiendo con {0}
fl.landing.title.small=Sepa qué {0} puede agregar a su proceso de aprendizaje
fl.landing.why1.title=Es fácil y divertido
fl.landing.why1.text=Miles de escolares y estudiantes realizan una tarea educativa para {0} todos los días, mejorando su nivel de evaluaciones. En el sofá, en el metro, en línea, siempre habrá unos minutos para estudiar.
fl.landing.why2.title=Seis modos de entrenamiento
fl.landing.why2.text=Recoge esas herramientas que se adapten mejor a la información que necesita aprender.
fl.landing.why3.title=Repetición espaciada
fl.landing.why3.text=Basado en el estudio de la psicología, el método de repeticiones de intervalo le permite memorizar grandes cantidades de información sin un esfuerzo extraordinario. Solo busca 10-15 minutos al día.
fl.landing.why4.title=En cualquier dispositivo
fl.landing.why4.text=Ejercicio en el dispositivo que tienes en la mano. No importa, computadora, tableta o teléfono, nuestras herramientas están optimizadas.
fl.landing.create.title=Crea tus conjuntos de tarjetas
fl.landing.create1.title=Creando memorizar
fl.landing.create1.text=Para preparar un conjunto, tiene que estructurar y simplificar la información. Esta es una de las mejores maneras de dominar el material. En el futuro, solo necesitarás actualizar los recuerdos.
fl.landing.create2.title=Importación conveniente
fl.landing.create2.text=Aproveche la herramienta flexible para importar tarjetas de archivos de texto para descargar conjuntos de otras fuentes. También puede importar archivo Anki.
fl.landing.create3.title=Modificar
fl.landing.create3.text=Seleccione el conjunto apropiado de nuestra base, cambiarlo y agregarlo de acuerdo con su currículo.
fl.landing.create.button=Crear un conjunto
fl.landing.gallery=Ejemplos
fl.landing.gallery.others=Ver otros ejemplos
fl.landing.stars1=3 millones de estudiantes en cada mes
fl.landing.stars2=100 000 000+ <br> sesiones de entrenamiento
fl.landing.stars3=1000 000+ <br> Kits educativos
fl.landing.reg1=Únase ahora y use tarjetas educativas de capacitación en intervalos, colecciones educativas y documentos educativos útiles.
fl.landing.reg2=¿No hay cuenta en Google o Facebook? ¡No hay problema! Regístrese usando el correo electrónico
## fast add card










## uninstall page
fl_uninstall_title=Gracias por usar Studylib Extension.
fl_uninstall_subtitle=¡Sentimos verte partir! Si tiene un momento, por favor, díganos cómo podemos mejorar la extensión:
fl_uninstall_notice=Sus comentarios nos ayudan a mejorar el producto para todos, gracias 😊
fl_uninstall_button=Enviar
fl_uninstall_success=¡Nos vemos pronto!

## APPEND ITEMS ABOVE THIS LINE