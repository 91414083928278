
defsPackEditor.title.placeholder=Masukkan judul (tema atau sesuatu)
defsPackEditor.description.placeholder=Masukkan Deskripsi
defsPackEditor.title.create=Buat Flashcards baru
defsPackEditor.title.save=Edit Flashcards.
defsPackEditor.addPackImage=Menambahkan gambar
visibility.label=Terlihat oleh.
visibility.0=Hanya aku
visibility.1=Setiap orang
## не удаляйте этот блок, на него есть ссылки в {plurals}
pl.card.one=kartu
pl.card.few=kartu-kartu
pl.card.other=kartu-kartu
pl.day.one=hari
pl.day.few=hari.
pl.day.other=hari.
pl.passedReview.one=lulus ulasan
pl.passedReview.few=melewati ulasan
pl.passedReview.other=melewati ulasan
pl.failedReview.one=Tinjauan gagal
pl.failedReview.few=Ulasan gagal
pl.failedReview.other=Ulasan gagal
pl.freshCard.one=kartu segar.
pl.freshCard.few=kartu segar.
pl.freshCard.other=kartu segar.
pl.hours.one=jam
pl.hours.few=jam
pl.hours.other=jam
pl.hours={0} {0,plural,one{jam}few{jam}other{jam}}
pl.days.one=hari
pl.days.few=hari.
pl.days.other=hari.
pl.days={0} {0,plural,one{hari}few{hari.}other{hari.}}
pl.minutes.one=menit
pl.minutes.few=menit
pl.minutes.other=menit
pl.minutes={0} {0,plural,one{menit}few{menit}other{menit}}
## validation
rubd.flashcards.services.validation.SideTextWhenCardIsNotEmpty.message=Kartu tidak kosong, Anda memerlukan beberapa teks untuk sisi ini
##
button.save=Menyimpan
button.saveContinue=Simpan dan Lanjutkan
button.create=Membuat
button.cancel=Membatalkan
button.clone=Klon
button.print=Mencetak
button.checkTest=Periksa hasilnya
button.yes=Ya
button.close=Menutup
button.ok=Oke
button.help=Membantu
##
title.label=Judul
title.description=Keterangan
title.optional=(opsional)
## duration messages
fl.agoString={0} lalu
fl.agoRecently=Baru-baru ini
fl.timeSpent={0} Menghabiskan
fl.timeSpentLessThanMinute=Menghabiskan sedikit
##
pack.cardCount={0} kartu
pack.cardCount.one={0} kartu
pack.cardCount.many={0} kartu
pack.cardCount.few={0} kartu
##
page.defaultTitle=Flashcards online
## user private dashboard
fl.mainDashboard.packAgo.title=Waktu yang berlalu sejak kunjungan terakhir
fl.mainDashboard.packSpent.title=Waktu yang dihabiskan untuk belajar
fl.mainDashboard.hideMemoPack=Set ini akan dihapus dari dasbor pembelajaran jangka panjang. Untuk menambahkannya kembali, Anda harus mengklik halaman "Mulai Belajar" pada set.
##
packEditor.form.termLanguage=Bahasa istilah.
packEditor.form.termLanguage.placeholder=Pilih bahasa
packEditor.form.definitionLanguage=Bahasa definisi.
packEditor.form.definitionLanguage.placeholder=Pilih bahasa
packEditor.form.loading=Memuat daftar kartu ...
packEditor.form.cardMinimum=Silakan isi setidaknya dua kartu flash
packEditor.importer.open=Impor dari TXT, Word, Excel, Google Documents, dll.
packEditor.importer.close=Menutup
packEditor.importer.import=Impor
packEditor.importer.textLabel=Salin dan tempel teks di sini (dari Word, Excel, Google Documents, dll)
packEditor.importer.textPlaceholder=Kata 1    Definisi 1\nKata 2    Definisi 2\nKata 3    Definisi 3
packEditor.importer.result={0} Entri diimpor
packEditor.importer.preview.title=Pratinjau kartu
packEditor.importer.preview.empty=Tidak ada kartu yang dikenali. Periksa pengaturan impor di panel kanan.
packEditor.anki.button=Impor Anki.
packEditor.anki.dialog.message=Unggah file .apkg Anda untuk mengimpor dek kartu flash ANKI Anda.
packEditor.anki.dialog.notice1=Beberapa deck tidak dapat diimpor atau dapat diimpor dengan kesalahan. Itu tergantung pada struktur geladak. Kami mohon maaf tentang itu.
packEditor.anki.dialog.notice2=Anda dapat mengimpor deck atau deck Anda sendiri sehingga Anda memiliki hak untuk menyalin dan memodifikasi.
packEditor.anki.dialog.title=Impor kartu flash ANKI.
packEditor.anki.dialog.button=Jelajahi
packEditor.anki.progress.sending=Mengirim file ...
packEditor.anki.progress.parsing=File parsing ...
packEditor.anki.progress.forming=Memasukkan hasil ke dalam formulir ...
packEditor.anki.progress.error=Impor gagal.
packEditor.anki.progress.success=Operasi selesai. Kartu baru telah ditambahkan ke daftar di bawah ini.
packEditor.addRow=Tambahkan kartu.
packEditor.card.term.placeholder=Ketentuan
packEditor.card.term.needText=Butuh teks di sini
packEditor.card.def.placeholder=Definisi
packEditor.card.def.needText=Butuh teks di sini
packEditor.importer.form.mode=Mode Impor
packEditor.importer.form.mode.everyLine=Istilah dan definisi ada di setiap baris, dipisahkan oleh pembatas
packEditor.importer.form.mode.lineByLine=Istilah dan definisi berurutan, satu elemen per baris
packEditor.importer.form.everyLine1=Delimiter entri (line)
packEditor.importer.form.everyLine2=Penjelasan Term / Definisi
packEditor.importer.form.everyLine3=Opsi tambahan
packEditor.importer.form.ldLine=Bungkus garis
packEditor.importer.form.ldDoubleLine=Bungkus garis ganda
packEditor.importer.form.ldCustom=Simbol kustom.
packEditor.importer.form.ldJoinLowercase=Gabung garis yang dimulai dengan simbol huruf kecil
packEditor.importer.form.tdDash=Berlari (-)
packEditor.importer.form.tdTab=Tab.
packEditor.importer.form.tdCustom=Simbol kustom.
packEditor.importer.form.lineByLine1=Opsi tambahan
packEditor.importer.form.llDoubleLine=Bungkus garis ganda antara setiap kelompok
packEditor.importer.form.llJoinLowercase=Gabung garis yang dimulai dengan simbol huruf kecil
packEditor.splitNewCards.notice=Sepertinya Anda memiliki banyak kartu. Mungkin masuk akal untuk membagi kartu menjadi beberapa set
packEditor.splitNewCards.checkbox.1=Ya, break card
packEditor.splitNewCards.checkbox.2=potongan di setiap set
packEditor.splitNewCards.shuffle=Dan kartu shuffle sebelum split
packEditor.splitNewCards.suffix=Bagian {0}.
packEditor.putToCollection=Tambahkan paket ke
packEditor.putToCollection.none=<None >.
packEditor.putToCollection.createNew=koleksi atau {0} Buat baru {1}.
packEditor.images.search=Cari gambar
packEditor.images.search.or=atau
packEditor.images.upload=Mengunggah
packEditor.images.remove=Hapus gambar
packEditor.images.emptySearch=Tidak ada gambar yang ditemukan. Coba kueri lain.
packEditor.audio.record=Catatan
packEditor.audio.remove=Menghapus
packEditor.audio.upload=Mengunggah
##
collectionForm.modal.title=Buat koleksi baru
collectionForm.name=Nama
collectionForm.name.placeholder=Ketikkan nama koleksi
collectionForm.description=Keterangan
collectionForm.description.placeholder=Ketik Deskripsi Koleksi ..
##
myPage.title.author=Set saya sendiri
myPage.title.lastPacks=Set terakhir saya
myPage.title.favourite=Set favorit saya
myPage.empty.last=Anda belum mengunjungi set apa pun
myPage.empty.author=Anda belum membuat set apa pun
myPage.empty.favourite=Daftar favorit Anda kosong
##
packPage.header.from=oleh
packPage.buttonGroup.play=Bermain
packPage.button.play=Pratinjau
packPage.button.test=Tes
packPage.button.learn=Mempelajari
packPage.button.write=Menulis
packPage.button.spell=Mengeja
packPage.button.match=Cocok
packPage.button.live=Hidup
packPage.solw.short=Belajar cepat
packPage.solw.short.subtitle=Ulangi set flashcard ini
packPage.solw.short.new=Baru:
packPage.solw.short.familiar=Akrab:
packPage.solw.short.mastered=Menguasai:
packPage.solw.long=Belajar jangka panjang
packPage.solw.long.subtitle=Pengulangan jarak
packPage.solw.long.today=Tugas hari ini
packPage.solw.long.new=Baru:
packPage.solw.long.review=Untuk meninjau:
packPage.button.memo=Memo
ppp0.title=Ingin belajar set ini dengan cepat?
packPage.dialogs.forbidden.alert=Hanya tersedia untuk pengguna yang berwenang
packPage.dialogs.forbidden.addToCollection.text=Anda dapat menambahkan set ini ke salah satu koleksi Anda
packPage.dialogs.forbidden.addToCollection.alert=Hanya tersedia untuk pengguna yang berwenang
packPage.dialogs.forbidden.createNew.title=Selamat datang!
packPage.dialogs.forbidden.createNew.text=Hanya pengguna yang berwenang yang dapat membuat kartu baru.
packPage.dialogs.forbidden.clone.text=Jika Anda menggunakan kartu-kartu ini untuk belajar, tetapi Anda ingin menambahnya, hanya mengkloning mereka dan membuat set baru yang Anda butuhkan.
packPage.dialogs.forbidden.clone.alert=Hanya tersedia untuk pengguna yang berwenang
packPage.dialogs.addToCollection.title=Tambahkan set ini ke koleksi
packPage.dialogs.addToCollection.notice=Anda dapat menambahkan set flashcard ini ke koleksi studi Anda
packPage.dialogs.addToCollection.createNew=+ Tambahkan koleksi baru
packPage.dialogs.addToCollection.forbidden=Hanya pengguna yang berwenang yang dapat menambahkan kartu ke kursus mereka
packPage.dialogs.addToFavourites.title=Tambahkan set ini untuk disimpan
packPage.dialogs.addToFavourites.forbidden=Anda dapat menambahkan Simpan set ini untuk kembali lagi nanti
packPage.dialogs.login=Login / Registrasi
packPage.dialogs.share.title=Bagikan set ini
packPage.dialogs.clone.title=Mengkloning set ini
packPage.dialogs.clone.notice=Jika Anda menggunakan kartu-kartu ini untuk belajar, tetapi Anda ingin menambahnya, hanya mengkloning mereka dan membuat set baru yang Anda butuhkan.
packPage.command.edit=Edit
packPage.command.customize=Kustomisasi.
packPage.command.addTo=Tambahkan ...
packPage.command.addTo.collection=koleksi
packPage.command.addTo.saved=diselamatkan
packPage.command.share=Membagikan
packPage.list.sorting.original=Asli
packPage.list.sorting.alphabetical=Alfabetis
packPage.list.filter.all=Tunjukkan semua
packPage.list.filter.difficult=Sulit
packPage.list.filter.banned=Diabaikan
packPage.list.filter.mastered=Menguasai
packPage.list.filter.familiar=Akrab
packPage.list.filter.starred=Membintangi
packPage.list.emptyMessage=Tidak ada kartu yang cocok untuk filter ini
packPage.list.loadMore=Lihat Lebih Banyak Kartu
packPage.review=Tinjauan
packPage.startMemo=Tambahkan ke Meja Belajar Jangka Panjang
packPage.startLearning=Mulai belajar
packPage.speedReview=Ulasan Kecepatan
packEmbed.clickToFlip=Klik untuk Flip.
packPage.tooltips.memoShort=Mode pembelajaran cepat sangat membantu ketika Anda perlu mengulang beberapa kata atau pertanyaan sebelum ujian. Cocok untuk set kata kecil.
packPage.tooltips.memoLong=Mode pembelajaran Repetisi Spaced didasarkan pada metode pengulangan interval. Ini berguna untuk persiapan ujian sistematis dan pendidikan jangka panjang.
packPage.tooltips.preview=Lihat semua set kartu tanpa mengendalikan diri.
packPage.tooltips.test=Buat tes untuk memeriksa pengetahuan Anda pada satu set kartu.
packPage.tooltips.spell=Cek ejaan. Tes mendengarkan dan menulis.
##
packPlay.back=Kembali
packPlay.options=Pilihan
packPlay.progress=Kemajuan
packPlay.progressTemplate={0} {1}
packPlay.wrongDisplay.title=Jawaban yang salah
packPlay.wrongDisplay.label=Jawaban yang benar
packPlay.wrongDisplay.continueText=tekan tombol apa saja untuk melanjutkan
packPlay.wrongDisplay.userLabel=Kau menulis
packPlay.wrongDisplay.typeText=Ketikkan jawaban yang benar di bawah ini
##
packLearn.remaining=Tersisa
packLearn.familiar=Akrab
packLearn.mastered=Menguasai
packLearn.write.placeholder=Tulis jawaban Anda di sini
packLearn.write.placeholderCopy=Salin jawaban yang benar di sini
packLearn.write.submit=Menjawab
packLearn.write.skip=Melewati
packLearn.write.stillCorrect=saya benar
packLearn.write.giveup=Menyerah
packLearn.dialogs.options.title=Pilihan
packLearn.dialogs.options.questionTitle=Jawab dengan
packLearn.dialogs.options.questionTerm=Ketentuan
packLearn.dialogs.options.questionDefinition=Definisi
packLearn.dialogs.options.questionError=Setidaknya satu kotak centang harus diperiksa
packLearn.dialogs.options.modeTitle=Jenis pertanyaan
packLearn.dialogs.options.modeSelect=Pilihan ganda
packLearn.dialogs.options.modeWrite=Tertulis
packLearn.dialogs.options.modeError=Setidaknya satu kotak centang harus diperiksa
packLearn.dialogs.options.modeRandom=Pesanan acak.
packLearn.dialogs.options.resetTitle=Atur Ulang Kemajuan
packLearn.dialogs.options.resetButton=Mengatur ulang
packLearn.dialogs.options.modeCards=Flashcards.
packLearn.dialogs.config.title=Pilihan memo
packLearn.dialogs.firstTime.title=Pengaturan pertama kali
packLearn.dialogs.firstTime.notice=Anda dapat mengubah pengaturan ini di menu "Opsi" kapan saja
packLearn.dialogs.options.writeAnyAnswer=Tulis semua jawaban
packLearn.dialogs.options.writeAnyAnswer.notice=Anda dapat menulis hanya satu jawaban yang tersedia (yang dipisahkan oleh koma).
packLearn.dialogs.options.playAudio=Mainkan Audio.
packLearn.dialogs.options.newLimit=Batas kartu baru
packLearn.dialogs.options.newLimitError=Seharusnya angka positif, kurang atau sama dengan 50
packLearn.dialogs.options.order=Pesanan baru
packLearn.dialogs.options.order.original=Asli
packLearn.dialogs.options.order.random=Acak
packLearn.dialogs.options.advancedMode=Mode lanjutan
packLearn.dialogs.options.advancedMode.notice=Secara default "pembelajaran jangka panjang" menggunakan algoritma pengulangan spasi yang dimodifikasi di mana ia cukup memilih "tahu" atau "tidak tahu". Dengan beralih ke "mode lanjutan", Anda dapat menggunakan algoritma klasik dengan lima opsi yang tersedia, mirip dengan Supermemo atau Anki.
##
searchPage.title=Cari "{0}"
searchPage.head=Hasil untuk permintaan "{0}"
searchPage.display.packs=Tampilkan set
searchPage.display.courses=Tampilkan kursus
searchPage.noResults.packs=Maaf, tidak ada set yang ditemukan
searchPage.noResults.courses=Maaf, tidak ada kursus yang ditemukan
##
learnPage.progress.total=Lulus
learnPage.progress.correct=Benar
learnPage.progress.wrong=Salah
learnPage.answer.go=Menjawab
learnPage.answer.placeholder=Ketik jawabanmu
learnPage.answer.unknown=Saya tidak tahu
learnPage.wrong.stillCorrect=Jawaban saya benar!
learnPage.next=Lanjut
learnPage.result.actual=Jawaban Anda
learnPage.result.correct=Jawaban yang benar
learnPage.header.correct=Jawaban yang benar
learnPage.header.wrong=Jawaban yang salah
learnPage.emptyQueue=Selamat! Anda telah melewati semua kartu.
learnPage.restartQueue=Mengulang kembali
learnPage.reviewComplete.title=Selamat!
learnPage.reviewComplete.text=Anda telah melewati semua kartu.
##
testSelectPage.opt.limit=Batas pertanyaan
testSelectPage.opt.first=Menunjukkan
testSelectPage.start=Mulailah Tes
testSelectPage.opt.limit.prefix=Menggunakan
testSelectPage.opt.limit.suffix=dari {0}
testSelectPage.opt.first.face=Ketentuan
testSelectPage.opt.first.back=Definisi
testSelectPage.mode1=Cocok
testSelectPage.mode2=Ya Tidak
testSelectPage.mode3=4 opsi
testSelectPage.mode4=Menulis
##
testResult.message=Nilai Anda:
testResult.correctTitle=Jawaban yang diharapkan
testResult.noAnswer=Anda tidak memilih apa pun
testResult.noAnswerWrite=Anda tidak menulis apa pun
testCheck.answer=Menjawab:
testCheck.answer.yes=Benar
testCheck.answer.no=Salah
testCheck.answer.correct=Benar!
testCheck.answer.wrong=Salah!
testWrite.input.placeholder=Ketikkan jawaban Anda di sini
##
uknownPack.text=Mungkin kami memiliki set ini sebelumnya ... Sayangnya, itu tidak tersedia lagi :(
##
packTest.form.questionLimit=Batas pertanyaan
packTest.form.questionLimit.of=dari
packTest.form.questionLimit.invalid=Jumlah pertanyaan tidak valid.
packTest.form.questionWith=Pertanyaan dengan
packTest.form.questionWith.notSelected=Diperlukan setidaknya satu opsi.
packTest.form.requireOne=Membutuhkan satu jawaban saja
packTest.form.requireOne.explain=Setidaknya diperlukan satu jawaban yang benar. Jawaban harus dipisahkan dengan garis miring (/), koma (,) atau titik koma (;)
packTest.form.types=Jenis pertanyaan
packTest.form.types.notSelected=Diperlukan setidaknya satu opsi.
packTest.form.type.choice=Pilihan ganda
packTest.form.type.yn=Benar salah
packTest.form.type.written=Tertulis
packTest.form.type.matching=Cocok
packTest.start=Mulailah Tes
packTest.qsMatching=Pertanyaan untuk mencocokkan
packTest.qsMatching.expected=Jawaban yang benar adalah:
packTest.qsWritten=Pertanyaan untuk menulis
packTest.qsWritten.inputPlaceholder=Ketikkan jawaban Anda di sini
packTest.qsWritten.expected=Jawaban yang benar adalah:
packTest.qsChoice=Pertanyaan untuk pilihan
packTest.qsChoice.expected=Jawaban yang benar adalah:
packTest.qsYN=Pertanyaan untuk Benar / Salah
packTest.qsYN.true=benar
packTest.qsYN.false=Palsu
packTest.qsYN.expected=Jawaban yang benar adalah:
packTest.checkAnswers=Periksa jawaban
packTest.score.prefix=Hasil tes:
packTest.score.90=Bagus sekali!
packTest.score.75=Bagus!
packTest.score.50=Rata-rata
packTest.score.25=Miskin
packTest.score.0=Lemah
##
columns.cardsInPack=Kartu-kartu
##
fexplorer.page.title={0} Flashcards Explorer
fexplorer.page.categoryTitle={0} Flashcards
fexplorer.title=Flashcards Explorer.
fexplorer.popularsCategories=Kategori populer
fexplorer.language=Bahasaku
fexplorer.allCategories=Semua Kategori
fexplorer.collections.section=Koleksi
fexplorer.flashcards.section=Flashcards.
fexplorer.flashcards.terms=ketentuan
fexplorer.empty.message=Silakan pilih kategori <br> atau Situs
fexplorer.empty.search=Cari sesuatu
##
packPlay.progressBar.title=Kemajuan
packPlay.head.play=Flashcards.
packPlay.head.learn=Mempelajari
packPlay.head.review=Tinjauan
packPlay.head.test=Tes
packPlay.head.write=Menulis
packPlay.head.memo=Memo
packPlay.head.spell=Mengeja
packPlay.head.match=Cocok
##
packMemo.showAnswer=Tunjukkan jawaban
packMemo.sessionComplete=Tidak ada lagi kartu <br> untuk hari ini!
packMemo.sessionNextTime=Kembali dalam {0}.
packMemo.grades.button.H=Hampir tidak
packMemo.grades.button.E=Mudah
packMemo.grades.button.M=Medium
packMemo.grades.button.1=Buruk
packMemo.grades.button.2=Lemah
packMemo.grades.button.3=Medium
packMemo.grades.button.4=Bagus
packMemo.grades.button.5=Kuat
packMemo.grades.button.Y=Ok, saya ingat
packMemo.grades.button.R=aku tahu
packMemo.grades.button.F=Saya tidak tahu
packMemo.short.days=h
packMemo.short.months=bu
packMemo.short.hours=j
packMemo.welcome.title=Meja Belajar Jangka Panjang
packMemo.welcome.text=Ini penjadwal pengulangan yang spasi. Setelah Anda mulai belajar proses, Anda akan belajar 20 kartu setiap hari. Frekuensi pertunjukan kartu tergantung pada jawaban Anda. Mode ini didasarkan pada algoritma pengulangan spasi terkenal yang disebut SM-2. <br> <a href="https://en.wikipedia.org/wiki/supermemo"> Baca Selengkapnya. </a>
packMemo.welcome.start=Ayo mulai
packMemo.welcome.login=Sayangnya, mode ini hanya tersedia untuk pengguna yang berwenang. <br> tolong, <a href="/login"> Masuk </a> atau <a href="/registration"> mendaftar </a>. Situs itu mudah dan benar-benar gratis.




packMemo.help.title=Petunjuk
packMemo.help.line1=Spasi untuk menunjukkan jawaban
packMemo.help.line2=1,2,3,4,5 untuk membuat pilihan Anda
packMemo.help.hide=Bersembunyi
packMemo.end.freshAvailable=Apakah Anda ingin melihat lebih banyak kartu baru hari ini?
packMemo.end.freshAccept=Ya, tunjukkan lebih banyak
##
fl.matchGame.welcome.text=Seret item ke satu sama lain untuk membuat mereka menghilang
fl.matchGame.startGame=Memulai permainan
fl.matchGame.timer=Waktu
fl.matchGame.preset.number=Menghitung
fl.matchGame.result.title=Selamat!
fl.matchGame.result.text=Skor Anda adalah {0} detik
fl.matchGame.result.again=Main lagi
##
fl.memoHeatMap.title=Sejarah Belajar Anda
fl.memoHeatMap.legend.daverage=Rata-rata harian:
fl.memoHeatMap.legend.daverage.value={0} {0,plural,one{kartu}few{kartu-kartu}other{kartu-kartu}}
fl.memoHeatMap.legend.dlearned=Hari belajar:

fl.memoHeatMap.legend.beststreak=Streak terbaik:
fl.memoHeatMap.legend.beststreak.value={0} {0,plural,one{hari}few{hari.}other{hari.}}
fl.memoHeatMap.legend.currentstreak=Streak saat ini:
fl.memoHeatMap.legend.currentstreak.value={0} {0,plural,one{hari}few{hari.}other{hari.}}
fl.memoHeatMap.tooltip.passed={0} {0,plural,one{lulus ulasan}few{melewati ulasan}other{melewati ulasan}}
fl.memoHeatMap.tooltip.failed={0} {0,plural,one{Tinjauan gagal}few{Ulasan gagal}other{Ulasan gagal}}
fl.memoHeatMap.tooltip.fresh={0} {0,plural,one{kartu segar.}few{kartu segar.}other{kartu segar.}}
fl.memoHeatMap.tooltip.date=pada {0}
##
cardButtons.ban.title=Larang kartu ini
cardButtons.edit.title=Perbaiki kartu ini
cardButtons.star.title=Beralih kartu favorit
cardButtons.ban.prompt=Abaikan kartu ini?
##
validation.cardText.tooLong=Panjang maksimum teks ini adalah 1000 karakter
##
extLocale.la=Latin
extLocale.rw=Kinyarwanda.
extLocale.af=Afrikanas
extLocale.am=Amharic.
extLocale.az=Azerbaijani.
extLocale.bn=Benggala
extLocale.bs=Bosnia.
extLocale.ceb=Cebuano.
extLocale.co=Corsican.
extLocale.cy=Welsh.
extLocale.eo=Esperanto
extLocale.eu=Basque.
extLocale.fa=Orang Persia
extLocale.fy=Frisian.
extLocale.gd=Skotlandia (Gaelic)
extLocale.gl=Galicia.
extLocale.gu=Gujarati
extLocale.ha=rumah
extLocale.haw=Hawaii.
extLocale.hmn=Hmong.
extLocale.ht=Creole (Haiti)
extLocale.hy=Armenia
extLocale.id=bahasa Indonesia
extLocale.ig=Igbo
extLocale.jw=Jawa
extLocale.ka=Georgia.
extLocale.kk=Kazakh
extLocale.km=Khmer.
extLocale.kn=Kannada.
extLocale.ku=Kuranji.
extLocale.ky=Kirgistan
extLocale.lb=Luxembourg.
extLocale.lo=Laotian.
extLocale.mg=Malagasi
extLocale.mi=Maori.
extLocale.ml=Malayalam.
extLocale.mn=Mongolia
extLocale.mr=Marathi.
extLocale.my=Birma
extLocale.ne=Nepal.
extLocale.ny=Ceva.
extLocale.or=Oria.
extLocale.pa=Punjabi.
extLocale.ps=Pashto.
extLocale.sd=Sindhi.
extLocale.si=Sinhalese
extLocale.sm=Samoan.
extLocale.sn=Shona
extLocale.so=Somalia.
extLocale.st=Sesotho.
extLocale.su=Sudan
extLocale.sw=Swahili.
extLocale.ta=Tamil
extLocale.te=Telugu.
extLocale.tg=Tajik.
extLocale.tk=Turkmen.
extLocale.tl=Filipina
extLocale.tt=Tatar.
extLocale.ug=Uigur.
extLocale.ur=Urdu
extLocale.uz=Uzbek.
extLocale.xh=Xhosa.
extLocale.yi=Yiddi
extLocale.yo=Yoruba.
extLocale.zu=Zulu


# landing
fl.landing.title=Belajar dengan {0}
fl.landing.title.small=Tahu apa {0} dapat menambah proses pembelajaran Anda
fl.landing.why1.title=Mudah dan menyenangkan
fl.landing.why1.text=Ribuan anak sekolah dan siswa melakukan tugas pendidikan untuk {0} setiap hari, meningkatkan tingkat penilaiannya. Di sofa, di kereta bawah tanah, sejalan - akan selalu ada beberapa menit untuk belajar.
fl.landing.why2.title=Enam mode pelatihan
fl.landing.why2.text=Ambil alat-alat yang lebih cocok dengan informasi yang perlu Anda pelajari.
fl.landing.why3.title=Pengulangan jarak
fl.landing.why3.text=Berdasarkan studi psikologi, metode pengulangan interval memungkinkan Anda menghafal sejumlah besar informasi tanpa upaya luar biasa. Cari saja 10-15 menit sehari.
fl.landing.why4.title=Pada perangkat apa pun
fl.landing.why4.text=Berolahraga pada perangkat yang Anda miliki. Itu tidak masalah, komputer, tablet atau telepon - alat kami dioptimalkan.
fl.landing.create.title=Buat set kartu Anda
fl.landing.create1.title=Menciptakan menghafal
fl.landing.create1.text=Untuk menyiapkan satu set, Anda harus menyusun dan menyederhanakan informasi. Ini adalah salah satu cara terbaik untuk menguasai materi. Di masa depan, Anda hanya perlu memperbarui kenangan.
fl.landing.create2.title=Impor yang nyaman
fl.landing.create2.text=Manfaatkan alat fleksibel untuk mengimpor kartu dari file teks untuk mengunduh set dari sumber lain. Anda juga dapat mengimpor file ANKI.
fl.landing.create3.title=Memodifikasi
fl.landing.create3.text=Pilih set yang sesuai dari basis kami, ubah dan tambahkan sesuai dengan kurikulum Anda.
fl.landing.create.button=Buat set
fl.landing.gallery=Contohnya
fl.landing.gallery.others=Lihat contoh lain
fl.landing.stars1=3 juta <br> siswa setiap bulan
fl.landing.stars2=100 000 000+ <br> sesi pelatihan
fl.landing.stars3=1000 000+ <br> kit pendidikan
fl.landing.reg1=Bergabunglah sekarang dan gunakan kartu pendidikan pelatihan interval, koleksi pendidikan, dan dokumen pendidikan yang bermanfaat.
fl.landing.reg2=Tidak ada akun di Google atau Facebook? Tidak masalah! Daftar menggunakan E-mail
## fast add card










## uninstall page
fl_uninstall_title=Terima kasih telah menggunakan ekstensi StudyLIB.
fl_uninstall_subtitle=Kami minta maaf melihat Anda pergi! Jika Anda memiliki waktu, tolong beri tahu kami bagaimana kami dapat membuat ekstensi lebih baik:
fl_uninstall_notice=Umpan balik Anda membantu kami meningkatkan produk untuk semua orang, terima kasih 😊
fl_uninstall_button=Mengirim
fl_uninstall_success=Sampai jumpa lagi!

## APPEND ITEMS ABOVE THIS LINE