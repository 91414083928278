
defsPackEditor.title.placeholder=Geben Sie den Titel ein (Thema oder etwas)
defsPackEditor.description.placeholder=Beschreibung eingeben
defsPackEditor.title.create=Erstellen Sie neue Kartenkarten
defsPackEditor.title.save=Karten bearbeiten
defsPackEditor.addPackImage=Bild hinzufügen
visibility.label=Sichtbar für
visibility.0=Nur ich
visibility.1=Jedermann
## не удаляйте этот блок, на него есть ссылки в {plurals}
pl.card.one=Karte
pl.card.few=Karten
pl.card.other=Karten
pl.day.one=Tag
pl.day.few=Tage
pl.day.other=Tage
pl.passedReview.one=Überprüfung
pl.passedReview.few=übergebene Bewertungen
pl.passedReview.other=übergebene Bewertungen
pl.failedReview.one=Fehlgeschlagene Überprüfung
pl.failedReview.few=keine Bewertungen gescheitert
pl.failedReview.other=keine Bewertungen gescheitert
pl.freshCard.one=frische Karte
pl.freshCard.few=frische Karten
pl.freshCard.other=frische Karten
pl.hours.one=Stunde
pl.hours.few=Std
pl.hours.other=Std
pl.hours={0} {0,plural,one{Stunde}few{Std}other{Std}}
pl.days.one=Tag
pl.days.few=Tage
pl.days.other=Tage
pl.days={0} {0,plural,one{Tag}few{Tage}other{Tage}}
pl.minutes.one=Minute
pl.minutes.few=Minuten
pl.minutes.other=Minuten
pl.minutes={0} {0,plural,one{Minute}few{Minuten}other{Minuten}}
## validation
rubd.flashcards.services.validation.SideTextWhenCardIsNotEmpty.message=Karte ist nicht leer, Sie benötigen einen Text für diese Seite
##
button.save=Speichern
button.saveContinue=Speichern und fortfahren
button.create=Schaffen
button.cancel=Abbrechen
button.clone=Klon
button.print=Drucken
button.checkTest=Ergebnisse überprüfen
button.yes=Jawohl
button.close=Nah dran
button.ok=Okay
button.help=Hilfe
##
title.label=Titel
title.description=Beschreibung
title.optional=(Optional)
## duration messages
fl.agoString={0} vor
fl.agoRecently=Vor kurzem
fl.timeSpent={0} ausgegeben
fl.timeSpentLessThanMinute=Verbrachte ein bisschen verbracht
##
pack.cardCount={0} Karten
pack.cardCount.one={0} Karte
pack.cardCount.many={0} Karten
pack.cardCount.few={0} Karten
##
page.defaultTitle=Online-Flashcards.
## user private dashboard
fl.mainDashboard.packAgo.title=Verstrichene Zeit seit dem letzten Besuch
fl.mainDashboard.packSpent.title=Zeit verbrachte das Lernen
fl.mainDashboard.hideMemoPack=Dieses Set wird aus langfristigem Lern-Dashboard entfernt. Um es hinzuzufügen, müssen Sie auf Set-Seite auf das "Start-Learning" klicken.
##
packEditor.form.termLanguage=Termsprache.
packEditor.form.termLanguage.placeholder=Sprache wählen
packEditor.form.definitionLanguage=Definitionssprache
packEditor.form.definitionLanguage.placeholder=Sprache wählen
packEditor.form.loading=Kartenliste laden ...
packEditor.form.cardMinimum=Bitte füllen Sie mindestens zwei Flashkarten aus
packEditor.importer.open=Import von TXT, WORD, Excel, Google Docs usw.
packEditor.importer.close=Nah dran
packEditor.importer.import=Importieren
packEditor.importer.textLabel=Text kopieren und einfügen (aus Word, Excel, Google Docs usw.)
packEditor.importer.textPlaceholder=Wort 1    Definition 1\nWort 2    Definition 2\nWort 3    Definition 3
packEditor.importer.result={0} Einträge wurden importiert
packEditor.importer.preview.title=Kartenvorschau.
packEditor.importer.preview.empty=Es wurden keine Karten erkannt. Überprüfen Sie Importeinstellungen im Rechtsfeld.
packEditor.anki.button=Anki importieren
packEditor.anki.dialog.message=Laden Sie Ihre .apkg-Datei hoch, um Ihr Anki-Flashkarten-Deck zu importieren.
packEditor.anki.dialog.notice1=Einige Decks können nicht importiert werden oder können mit Fehlern importiert werden. Es hängt von der Struktur des Decks ab. Das tut uns leid.
packEditor.anki.dialog.notice2=Sie können Ihre eigenen Decks oder Decks importieren, dass Sie das Recht haben, das Recht zu kopieren und zu ändern.
packEditor.anki.dialog.title=Anki-Flashkarten importieren.
packEditor.anki.dialog.button=Durchsuche
packEditor.anki.progress.sending=Datei senden ...
packEditor.anki.progress.parsing=Parsing-Datei ...
packEditor.anki.progress.forming=Ergebnis in Form ...
packEditor.anki.progress.error=Import ist fehlgeschlagen
packEditor.anki.progress.success=Bedienung komplett In der nachstehenden Liste wurden neue Karten hinzugefügt.
packEditor.addRow=Karte hinzufügen
packEditor.card.term.placeholder=Begriff
packEditor.card.term.needText=Benötigen Sie Text hier.
packEditor.card.def.placeholder=Definition
packEditor.card.def.needText=Benötigen Sie Text hier.
packEditor.importer.form.mode=Importmodus
packEditor.importer.form.mode.everyLine=Der Begriff und die Definition sind in jeder Zeile, getrennt durch Trennzeichen
packEditor.importer.form.mode.lineByLine=Der Begriff und die Definition sind sequentiell, ein Element pro Zeile
packEditor.importer.form.everyLine1=Eintrag (Linie) Trennzeichen
packEditor.importer.form.everyLine2=Begriff / Definition Trennzeichen
packEditor.importer.form.everyLine3=Zusatzoptionen
packEditor.importer.form.ldLine=Zeilenumbruch
packEditor.importer.form.ldDoubleLine=Doppelte Linienverpackung.
packEditor.importer.form.ldCustom=Benutzerdefiniertes Symbol
packEditor.importer.form.ldJoinLowercase=Join Line, die mit Kleinbuchstaben beginnt
packEditor.importer.form.tdDash=Bindestrich (-)
packEditor.importer.form.tdTab=Tab
packEditor.importer.form.tdCustom=Benutzerdefiniertes Symbol
packEditor.importer.form.lineByLine1=Zusatzoptionen
packEditor.importer.form.llDoubleLine=Doppelte Linienverpackung zwischen jeder Gruppe
packEditor.importer.form.llJoinLowercase=Join Line, die mit Kleinbuchstaben beginnt
packEditor.splitNewCards.notice=Es sieht so aus, als hätten Sie viele Karten. Vielleicht ist es vernünftig, Karten in mehrere Sätze aufzuteilen
packEditor.splitNewCards.checkbox.1=Ja, brechen Sie Karten in
packEditor.splitNewCards.checkbox.2=Stücke in jedem Set
packEditor.splitNewCards.shuffle=Und Shuffle-Karten vor Split
packEditor.splitNewCards.suffix=Teil {0}.
packEditor.putToCollection=Packung hinzufügen
packEditor.putToCollection.none=<none>
packEditor.putToCollection.createNew=Sammlung oder {0} Neue {1} erstellen.
packEditor.images.search=Bilder suchen
packEditor.images.search.or=oder
packEditor.images.upload=Hochladen
packEditor.images.remove=Entferne Bild
packEditor.images.emptySearch=Keine Bilder gefunden. Versuchen Sie eine andere Abfrage.
packEditor.audio.record=Aufzeichnen
packEditor.audio.remove=Entfernen
packEditor.audio.upload=Hochladen
##
collectionForm.modal.title=Neue Kollektion erstellen
collectionForm.name=Name
collectionForm.name.placeholder=Typ Sammlungsname
collectionForm.description=Beschreibung
collectionForm.description.placeholder=Typ Sammlung Beschreibung
##
myPage.title.author=Meine eigenen Sets
myPage.title.lastPacks=Meine letzten Sets
myPage.title.favourite=Meine Lieblings-Sets
myPage.empty.last=Sie haben keine Sets besucht
myPage.empty.author=Sie haben keine Sets erstellt
myPage.empty.favourite=Ihre Lieblingsliste ist leer
##
packPage.header.from=von
packPage.buttonGroup.play=Spiel
packPage.button.play=Vorschau
packPage.button.test=Prüfen
packPage.button.learn=Lernen
packPage.button.write=Schreiben
packPage.button.spell=Fluch
packPage.button.match=Spiel
packPage.button.live=Live
packPage.solw.short=Schnelles Lernen
packPage.solw.short.subtitle=Wiederholen Sie diesen Satz von Flashkarten
packPage.solw.short.new=Neu:
packPage.solw.short.familiar=Vertraut:
packPage.solw.short.mastered=Gemeistert:
packPage.solw.long=Langzeitlernen
packPage.solw.long.subtitle=Abstandsrepettition
packPage.solw.long.today=Heute Aufgabe.
packPage.solw.long.new=Neu:
packPage.solw.long.review=Zur Überprüfung:
packPage.button.memo=Memo
ppp0.title=Möchten Sie dieses Set schnell lernen?
packPage.dialogs.forbidden.alert=Nur für autorisierte Benutzer verfügbar
packPage.dialogs.forbidden.addToCollection.text=Sie können dieses Set mit einem Ihrer Sammlungen hinzufügen
packPage.dialogs.forbidden.addToCollection.alert=Nur für autorisierte Benutzer verfügbar
packPage.dialogs.forbidden.createNew.title=Willkommen!
packPage.dialogs.forbidden.createNew.text=Nur autorisierte Benutzer können neue Karten erstellen.
packPage.dialogs.forbidden.clone.text=Wenn Sie diese Karten für das Studium verwenden, aber Sie möchten sie ergänzen, einfach klonen und erstellen Sie ein neues Set, das Sie benötigen.
packPage.dialogs.forbidden.clone.alert=Nur für autorisierte Benutzer verfügbar
packPage.dialogs.addToCollection.title=Fügen Sie dieses Set zur Sammlung hinzu
packPage.dialogs.addToCollection.notice=Sie können diesen Satz von Kartenkarten an Ihre Studiensammlungen hinzufügen
packPage.dialogs.addToCollection.createNew=+ Neue Sammlung hinzufügen
packPage.dialogs.addToCollection.forbidden=Nur autorisierte Benutzer können Karten an ihre Kurse hinzufügen
packPage.dialogs.addToFavourites.title=Fügen Sie dieses Set hinzu, das gespeichert ist
packPage.dialogs.addToFavourites.forbidden=Sie können dieses Set speichern hinzufügen, um später zurückzukehren
packPage.dialogs.login=Anmeldung Registrieren
packPage.dialogs.share.title=Teilen Sie dieses Set
packPage.dialogs.clone.title=Klon dieses Set
packPage.dialogs.clone.notice=Wenn Sie diese Karten für das Studium verwenden, aber Sie möchten sie ergänzen, einfach klonen und erstellen Sie ein neues Set, das Sie benötigen.
packPage.command.edit=Bearbeiten
packPage.command.customize=Anpassen
packPage.command.addTo=Hinzufügen ...
packPage.command.addTo.collection=Sammlung
packPage.command.addTo.saved=Gerettet
packPage.command.share=Teilen
packPage.list.sorting.original=Original
packPage.list.sorting.alphabetical=Alphabetisch
packPage.list.filter.all=Zeige alles
packPage.list.filter.difficult=Schwierig
packPage.list.filter.banned=Ignoriert
packPage.list.filter.mastered=Gemeistert
packPage.list.filter.familiar=Vertraut
packPage.list.filter.starred=Sperre
packPage.list.emptyMessage=Keine Karte passt zu diesem Filter
packPage.list.loadMore=Weitere Karten anzeigen.
packPage.review=Rezension
packPage.startMemo=Zum langfristigen Lernschalter hinzufügen
packPage.startLearning=Beginne zu lernen
packPage.speedReview=Speed ​​Review
packEmbed.clickToFlip=Klicken Sie auf das Flip.
packPage.tooltips.memoShort=Ein schneller Lernmodus ist hilfreich, wenn Sie einige Wörter oder Fragen vor der Prüfung wiederholen müssen. Geeignet für kleine Wörter.
packPage.tooltips.memoLong=Der spezielle Wiederholungs-Lernmodus basiert auf dem Intervallwiederholungsverfahren. Es ist nützlich für systematische Prüfungsvorbereitung und langfristige Ausbildung.
packPage.tooltips.preview=Alle Karten-Sets anzeigen, ohne sich selbst zu kontrollieren.
packPage.tooltips.test=Erstellen Sie einen Test, um Ihr Wissen auf einem Kartensatz zu überprüfen.
packPage.tooltips.spell=Rechtschreibprüfung. Hören und Schreiben von Test.
##
packPlay.back=Zurück
packPlay.options=Optionen
packPlay.progress=Fortschritt
packPlay.progressTemplate={0} von {1}
packPlay.wrongDisplay.title=Falsche Antwort
packPlay.wrongDisplay.label=Richtige Antwort
packPlay.wrongDisplay.continueText=Drücken Sie eine beliebige Taste, um fortzufahren
packPlay.wrongDisplay.userLabel=Sie schrieben
packPlay.wrongDisplay.typeText=Geben Sie die richtige Antwort unten ein
##
packLearn.remaining=Verblieben
packLearn.familiar=Vertraut
packLearn.mastered=Gemeistert
packLearn.write.placeholder=Schreiben Sie Ihre Antwort hier
packLearn.write.placeholderCopy=Kopieren Sie die richtige Antwort hier
packLearn.write.submit=Antworten
packLearn.write.skip=Überspringen
packLearn.write.stillCorrect=Ich lag richtig
packLearn.write.giveup=Gib auf
packLearn.dialogs.options.title=Optionen
packLearn.dialogs.options.questionTitle=Beantworten
packLearn.dialogs.options.questionTerm=Begriff
packLearn.dialogs.options.questionDefinition=Definition
packLearn.dialogs.options.questionError=Mindestens ein Kontrollkästchen sollte überprüft werden
packLearn.dialogs.options.modeTitle=Fragearten
packLearn.dialogs.options.modeSelect=Mehrfachauswahl
packLearn.dialogs.options.modeWrite=Geschrieben
packLearn.dialogs.options.modeError=Mindestens ein Kontrollkästchen sollte überprüft werden
packLearn.dialogs.options.modeRandom=Zufällige Reihenfolge
packLearn.dialogs.options.resetTitle=Fortschritt zurücksetzen.
packLearn.dialogs.options.resetButton=Zurücksetzen
packLearn.dialogs.options.modeCards=Kartenkarten
packLearn.dialogs.config.title=Notizoptionen
packLearn.dialogs.firstTime.title=Erst-Setup
packLearn.dialogs.firstTime.notice=Sie können diese Einstellungen im Menü "Optionen" jederzeit ändern
packLearn.dialogs.options.writeAnyAnswer=Schreibe eine Antwort
packLearn.dialogs.options.writeAnyAnswer.notice=Sie können nur eine der verfügbaren Antworten schreiben (die durch Komma getrennt sind).
packLearn.dialogs.options.playAudio=Audio spielen
packLearn.dialogs.options.newLimit=Neue Kartenlimit
packLearn.dialogs.options.newLimitError=Es sollte eine positive Anzahl, weniger oder gleich 50 sein
packLearn.dialogs.options.order=Order of NEU.
packLearn.dialogs.options.order.original=Original
packLearn.dialogs.options.order.random=Willkürlich
packLearn.dialogs.options.advancedMode=Fortgeschrittener Modus
packLearn.dialogs.options.advancedMode.notice=Standardmäßig verwendet "Langzeitlernen" ein modifiziertes Lernabstand, der den Wiederholungsalgorithmus ausreicht, in dem es ausreicht, nur "Know" oder "WISSEN" auszuwählen. Wenn Sie in den "Erweiterten Modus" umschalten, können Sie einen klassischen Algorithmus mit fünf verfügbaren Optionen verwenden, ähnlich wie SuperMemo oder Anki.
##
searchPage.title=Suche "{0}"
searchPage.head=Ergebnisse für Abfrage "{0}"
searchPage.display.packs=Sets anzeigen
searchPage.display.courses=Kurse zeigen.
searchPage.noResults.packs=Leider wurde kein Set gefunden
searchPage.noResults.courses=Leider wurden keine Kurse gefunden
##
learnPage.progress.total=Bestanden
learnPage.progress.correct=Richtig
learnPage.progress.wrong=Falsch
learnPage.answer.go=Antworten
learnPage.answer.placeholder=Gib deine Antwort ein
learnPage.answer.unknown=Ich weiß nicht
learnPage.wrong.stillCorrect=Meine Antwort ist richtig!
learnPage.next=Nächste
learnPage.result.actual=Ihre Antwort
learnPage.result.correct=Richtige Antwort
learnPage.header.correct=Richtige Antwort
learnPage.header.wrong=Falsche Antwort
learnPage.emptyQueue=Herzliche Glückwünsche! Sie haben alle Karten bestanden.
learnPage.restartQueue=Neu starten
learnPage.reviewComplete.title=Herzliche Glückwünsche!
learnPage.reviewComplete.text=Sie haben alle Karten bestanden.
##
testSelectPage.opt.limit=Frageslimit
testSelectPage.opt.first=Zeigen
testSelectPage.start=Test beginnen
testSelectPage.opt.limit.prefix=Verwenden
testSelectPage.opt.limit.suffix=von {0}
testSelectPage.opt.first.face=Begriff
testSelectPage.opt.first.back=Definition
testSelectPage.mode1=Spiel
testSelectPage.mode2=Ja Nein
testSelectPage.mode3=4 Optionen
testSelectPage.mode4=Schreiben
##
testResult.message=Ihre Punktzahl:
testResult.correctTitle=Erwartete Antwort
testResult.noAnswer=Sie wählen nichts aus
testResult.noAnswerWrite=Du schreibst nichts
testCheck.answer=Antworten:
testCheck.answer.yes=Richtig
testCheck.answer.no=Falsch
testCheck.answer.correct=Richtig!
testCheck.answer.wrong=Falsch!
testWrite.input.placeholder=Geben Sie Ihre Antwort hier ein
##
uknownPack.text=Vielleicht hatten wir dieses Set vor ... Leider ist es nicht mehr verfügbar :(
##
packTest.form.questionLimit=Frageslimit
packTest.form.questionLimit.of=von
packTest.form.questionLimit.invalid=Die Frage der Frage ist ungültig.
packTest.form.questionWith=Frage mit
packTest.form.questionWith.notSelected=Mindestens eine Option ist erforderlich.
packTest.form.requireOne=Erfordert nur eine Antwort
packTest.form.requireOne.explain=Mindestens eine richtige Antwort ist erforderlich. Die Antworten müssen durch einen Schrägstrich (/), ein Komma (,) oder ein Semikolon (;) getrennt werden
packTest.form.types=Fragearten
packTest.form.types.notSelected=Mindestens eine Option ist erforderlich.
packTest.form.type.choice=Mehrfachauswahl
packTest.form.type.yn=Wahr falsch
packTest.form.type.written=Geschrieben
packTest.form.type.matching=Passend
packTest.start=Test beginnen
packTest.qsMatching=Fragen zum Matching.
packTest.qsMatching.expected=Richtige Antwort lautet:
packTest.qsWritten=Fragen zum Schreiben.
packTest.qsWritten.inputPlaceholder=Geben Sie Ihre Antwort hier ein
packTest.qsWritten.expected=Richtige Antwort lautet:
packTest.qsChoice=Fragen zur Wahl
packTest.qsChoice.expected=Richtige Antwort lautet:
packTest.qsYN=Fragen zu TRUE / FALSE
packTest.qsYN.true=Wahr
packTest.qsYN.false=Falsch
packTest.qsYN.expected=Richtige Antwort lautet:
packTest.checkAnswers=Antworten kontrollieren
packTest.score.prefix=Testergebnis:
packTest.score.90=Exzellent!
packTest.score.75=Gut!
packTest.score.50=Durchschnitt
packTest.score.25=Arm
packTest.score.0=Schwach
##
columns.cardsInPack=Karten
##
fexplorer.page.title={0} FlashCards Explorer
fexplorer.page.categoryTitle={0} Flashcards
fexplorer.title=Flashcards Explorer.
fexplorer.popularsCategories=Beliebte Kategorien
fexplorer.language=Meine Sprache
fexplorer.allCategories=Alle Kategorien
fexplorer.collections.section=Sammlungen
fexplorer.flashcards.section=Kartenkarten
fexplorer.flashcards.terms=Bedingungen
fexplorer.empty.message=Bitte wählen Sie eine Kategorie <br>oder<br>
fexplorer.empty.search=Suche etwas
##
packPlay.progressBar.title=Fortschritt
packPlay.head.play=Kartenkarten
packPlay.head.learn=Lernen
packPlay.head.review=Rezension
packPlay.head.test=Prüfen
packPlay.head.write=Schreiben
packPlay.head.memo=Memo
packPlay.head.spell=Fluch
packPlay.head.match=Spiel
##
packMemo.showAnswer=Zeige die Antwort
packMemo.sessionComplete=Keine Karten mehr für heute!
packMemo.sessionNextTime=Komm zurück in {0}.
packMemo.grades.button.H=Kaum
packMemo.grades.button.E=Einfach
packMemo.grades.button.M=Mittel
packMemo.grades.button.1=Schlecht
packMemo.grades.button.2=Schwach
packMemo.grades.button.3=Mittel
packMemo.grades.button.4=Gut
packMemo.grades.button.5=Stark
packMemo.grades.button.Y=Ok, ich erinnere mich
packMemo.grades.button.R=Ich kenne
packMemo.grades.button.F=Ich weiß nicht
packMemo.short.days=t
packMemo.short.months=mi
packMemo.short.hours=s
packMemo.welcome.title=Langfristiger Lernschalter
packMemo.welcome.text=Es ist ein reagierter Wiederholungsscheduler. Sobald Sie mit dem Lernprozess beginnen, lernen Sie täglich 20 Karten. Die Häufigkeit der Zeige der Karte hängt von Ihren Antworten ab. Dieser Modus basiert auf dem berühmten Abstandsregettalgorithmus namens SM-2. <br> <a href="https://en.wikipedia.org/wiki/supermemo"> mehr lesen. </a>
packMemo.welcome.start=Lasst uns beginnen
packMemo.welcome.login=Leider ist dieser Modus nur für autorisierte Benutzer verfügbar. <br> Bitte, <a href="/login"> Anmelden </a> oder <a href="/registration"> anmelden </a>. <br> Es ist einfach und absolut kostenlos.




packMemo.help.title=Hinweis
packMemo.help.line1=Leertaste, um die Antwort anzuzeigen
packMemo.help.line2=1,2,3,4,5, um Ihre Wahl zu treffen
packMemo.help.hide=Verstecken
packMemo.end.freshAvailable=Möchten Sie heute mehr neue Karten sehen?
packMemo.end.freshAccept=Ja, zeig mir mehr
##
fl.matchGame.welcome.text=Ziehen Sie Gegenstände aufeinander, um sie zulässt
fl.matchGame.startGame=Spiel starten
fl.matchGame.timer=Zeit
fl.matchGame.preset.number=Zählen
fl.matchGame.result.title=Herzliche Glückwünsche!
fl.matchGame.result.text=Sie punkten {0} Sekunden
fl.matchGame.result.again=Nochmal abspielen
##
fl.memoHeatMap.title=Ihre Lerngeschichte
fl.memoHeatMap.legend.daverage=Täglicher Durchschnitt:
fl.memoHeatMap.legend.daverage.value={0} {0,plural,one{Karte}few{Karten}other{Karten}}
fl.memoHeatMap.legend.dlearned=Tage gelernt:

fl.memoHeatMap.legend.beststreak=Beste Streak:
fl.memoHeatMap.legend.beststreak.value={0} {0,plural,one{Tag}few{Tage}other{Tage}}
fl.memoHeatMap.legend.currentstreak=Stromstreifen:
fl.memoHeatMap.legend.currentstreak.value={0} {0,plural,one{Tag}few{Tage}other{Tage}}
fl.memoHeatMap.tooltip.passed={0} {0,plural,one{Überprüfung}few{übergebene Bewertungen}other{übergebene Bewertungen}}
fl.memoHeatMap.tooltip.failed={0} {0,plural,one{Fehlgeschlagene Überprüfung}few{keine Bewertungen gescheitert}other{keine Bewertungen gescheitert}}
fl.memoHeatMap.tooltip.fresh={0} {0,plural,one{frische Karte}few{frische Karten}other{frische Karten}}
fl.memoHeatMap.tooltip.date=auf {0}
##
cardButtons.ban.title=Verbot dieser Karte
cardButtons.edit.title=Korrigieren Sie diese Karte.
cardButtons.star.title=Favoritenkarte umschalten
cardButtons.ban.prompt=Diese Karte ignorieren?
##
validation.cardText.tooLong=Die maximale Länge dieses Textes beträgt 1000 Zeichen
##
extLocale.la=Latein
extLocale.rw=Kinyarwanda.
extLocale.af=Afrikaans
extLocale.am=Amharisch
extLocale.az=Aserbaidschani.
extLocale.bn=Bengalen
extLocale.bs=bosnisch
extLocale.ceb=Cebuano.
extLocale.co=Korsikan
extLocale.cy=Walisisch
extLocale.eo=Esperanto
extLocale.eu=baskisch
extLocale.fa=persisch
extLocale.fy=Frissian
extLocale.gd=Scots (Gälisch)
extLocale.gl=galizisch
extLocale.gu=Gujarati
extLocale.ha=Haus
extLocale.haw=hawaiisch
extLocale.hmn=Hmong.
extLocale.ht=Kreolische (Haiti)
extLocale.hy=Armenisch
extLocale.id=Indonesisch
extLocale.ig=Igbo.
extLocale.jw=Javanesisch
extLocale.ka=georgisch
extLocale.kk=Kasakh.
extLocale.km=Khmer
extLocale.kn=Kannada.
extLocale.ku=Kurganji.
extLocale.ky=Kirgisisch
extLocale.lb=Luxemburg
extLocale.lo=Laotisch
extLocale.mg=Malagassisch
extLocale.mi=Maori
extLocale.ml=Malayalam
extLocale.mn=mongolisch
extLocale.mr=Marathi
extLocale.my=birmanisch
extLocale.ne=Nepali.
extLocale.ny=Ceva
extLocale.or=Oria.
extLocale.pa=Punjabi
extLocale.ps=Pashto.
extLocale.sd=Sindhi.
extLocale.si=Sinhalesisch
extLocale.sm=Samoan
extLocale.sn=Shona.
extLocale.so=somali
extLocale.st=Sesotho.
extLocale.su=Sudanesisch
extLocale.sw=Swahili
extLocale.ta=Tamil
extLocale.te=Telugu.
extLocale.tg=Tadjik
extLocale.tk=Turkmen
extLocale.tl=Philippinisch
extLocale.tt=Tatar.
extLocale.ug=Uigur.
extLocale.ur=Urdu
extLocale.uz=Usbekisch
extLocale.xh=Xhosa.
extLocale.yi=Jiddisch
extLocale.yo=Yoruba
extLocale.zu=Zulu-


# landing
fl.landing.title=Lernen mit {0}
fl.landing.title.small=Wissen, was {0} Ihren Lernprozess hinzufügen kann
fl.landing.why1.title=Es ist einfach und lustig
fl.landing.why1.text=Tausende von Schulkindern und Studenten führen täglich eine Bildungsaufgabe für {0} durch und verbessert seine Bewertungen. Auf dem Sofa, in der U-Bahn, in der Reihe, wird es immer ein paar Minuten dauern, bis sie studiert werden.
fl.landing.why2.title=Sechs Trainingsmodi.
fl.landing.why2.text=Nehmen Sie diese Werkzeuge auf, die besser für die Informationen geeignet sind, die Sie lernen müssen.
fl.landing.why3.title=Abstandsrepettition
fl.landing.why3.text=Basierend auf dem Studium der Psychologie können Sie die Methode der Intervallwiederholungen ohne außerordentliche Anstrengung auswendig lernen. Suchen Sie einfach 10-15 Minuten pro Tag.
fl.landing.why4.title=Auf einem beliebigen Gerät.
fl.landing.why4.text=Übung auf dem Gerät, das Sie zur Hand haben. Es spielt keine Rolle, Computer, Tablette oder Telefon - unsere Werkzeuge sind optimiert.
fl.landing.create.title=Erstellen Sie Ihre Karten-Sets
fl.landing.create1.title=Memorize erstellen.
fl.landing.create1.text=Um ein Set vorzubereiten, müssen Sie Informationen strukturieren und vereinfachen. Dies ist eine der besten Möglichkeiten, um das Material zu beherrschen. In Zukunft müssen Sie nur Erinnerungen aktualisieren.
fl.landing.create2.title=Bequemer Import.
fl.landing.create2.text=Nutzen Sie das flexible Tool zum Importieren von Karten aus Textdateien, um Sets aus anderen Quellen herunterzuladen. Sie können auch die Anki-Datei importieren.
fl.landing.create3.title=Ändern
fl.landing.create3.text=Wählen Sie das entsprechende Satz von unserer Basis aus, ändern Sie es und fügen Sie sie nach Ihrem Lehrplan hinzu.
fl.landing.create.button=Ein Set erstellen
fl.landing.gallery=Beispiele
fl.landing.gallery.others=Andere Beispiele anzeigen.
fl.landing.stars1=3 Millionen <br> Studenten jeden Monat
fl.landing.stars2=100 000 000+ <br> Trainingseinheiten
fl.landing.stars3=1000 000+ <br> pädagogische Kits
fl.landing.reg1=Jetzt beitreten und mit Intervalltraining-Bildungskarten, Bildungskollektionen und nützliche Bildungsdokumente verwenden.
fl.landing.reg2=Kein Konto in Google oder Facebook? Kein Problem! Melden Sie sich mit E-Mail an
## fast add card










## uninstall page
fl_uninstall_title=Vielen Dank, dass Sie Studyib-Erweiterung verwenden.
fl_uninstall_subtitle=Wir bedauern, dich gehen zu sehen! Wenn Sie einen Moment haben, sagen Sie uns bitte, wie wir die Erweiterung besser machen können:
fl_uninstall_notice=Ihr Feedback hilft uns, das Produkt für alle zu verbessern, danke 😊
fl_uninstall_button=Senden
fl_uninstall_success=Seh dich später!

## APPEND ITEMS ABOVE THIS LINE