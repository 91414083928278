
defsPackEditor.title.placeholder=Entrez le titre du jeu de cartes (thème ou quelque chose)
defsPackEditor.description.placeholder=Entrez brève description du jeu de cartes
defsPackEditor.title.create=Créer de nouvelles cartes mémoire
defsPackEditor.title.save=Modifier les cartes mémoire
defsPackEditor.addPackImage=Ajouter une image
visibility.label=Visible à
visibility.0=Juste moi
visibility.1=Toutes les personnes
## не удаляйте этот блок, на него есть ссылки в {plurals}
pl.card.one=carte
pl.card.few=cartes
pl.card.other=cartes
pl.day.one=journée
pl.day.few=jours
pl.day.other=jours
pl.passedReview.one=réussi
pl.passedReview.few=passé des critiques
pl.passedReview.other=passé des critiques
pl.failedReview.one=Échec de l'examen
pl.failedReview.few=Échec des commentaires
pl.failedReview.other=Échec des commentaires
pl.freshCard.one=carte fraîche
pl.freshCard.few=cartes fraîches
pl.freshCard.other=cartes fraîches
pl.hours.one=heure
pl.hours.few=les heures
pl.hours.other=les heures
pl.hours={0} {0,plural,one{heure}few{les heures}other{les heures}}
pl.days.one=journée
pl.days.few=jours
pl.days.other=jours
pl.days={0} {0,plural,one{journée}few{jours}other{jours}}
pl.minutes.one=minute
pl.minutes.few=minutes
pl.minutes.other=minutes
pl.minutes={0} {0,plural,one{minute}few{minutes}other{minutes}}
## validation
rubd.flashcards.services.validation.SideTextWhenCardIsNotEmpty.message=La carte n'est pas vide, vous avez besoin d'un texte de ce côté
##
button.save=Sauvegarder
button.saveContinue=Sauvegarder et continuer
button.create=Créer
button.cancel=Annuler
button.clone=Cloner
button.print=Imprimer
button.checkTest=Vérifier les résultats
button.yes=Oui
button.close=proche
button.ok=D'accord
button.help=Aider
##
title.label=Titre
title.description=La description
title.optional=(optionnel)
## duration messages
fl.agoString={0} il y a
fl.agoRecently=Récemment
fl.timeSpent={0} dépensé
fl.timeSpentLessThanMinute=Passé un peu
##
pack.cardCount={0} cartes
pack.cardCount.one={0} carte
pack.cardCount.many={0} cartes
pack.cardCount.few={0} cartes
##
page.defaultTitle=Cartes en ligne
## user private dashboard
fl.mainDashboard.packAgo.title=Temps écoulé depuis la dernière visite
fl.mainDashboard.packSpent.title=Temps passé à apprendre
fl.mainDashboard.hideMemoPack=Cet ensemble sera supprimé du tableau de bord d'apprentissage à long terme. Pour ajouter le retour, vous devrez cliquer sur «Démarrer l'apprentissage» sur la page Définir.
##
packEditor.form.termLanguage=Langage à terme
packEditor.form.termLanguage.placeholder=Choisissez la langue
packEditor.form.definitionLanguage=Langue de définition
packEditor.form.definitionLanguage.placeholder=Choisissez la langue
packEditor.form.loading=Liste de cartes de chargement ...
packEditor.form.cardMinimum=S'il vous plaît remplir au moins deux flashcards
packEditor.importer.open=Importer de TXT, Word, Excel, Google Docs, etc.
packEditor.importer.close=Fermer
packEditor.importer.import=Importer
packEditor.importer.textLabel=Copiez et collez le texte ici (à partir de Word, Excel, Google Docs, etc.)
packEditor.importer.textPlaceholder=Mot 1    Définition 1\nMot 2    Définition 2\nMot 3    Définition 3
packEditor.importer.result={0} entrées ont été importées
packEditor.importer.preview.title=Aperçu des cartes
packEditor.importer.preview.empty=Aucune carte n'a été reconnue. Vérifiez les paramètres d'importation dans le panneau de droite.
packEditor.anki.button=Importer anki
packEditor.anki.dialog.message=Téléchargez votre fichier .apkg pour importer votre pont ANKI FlashCards.
packEditor.anki.dialog.notice1=Certains decks ne peuvent pas être importés ou peuvent être importés avec des erreurs. Cela dépend de la structure du pont. Nous sommes désolés pour ça.
packEditor.anki.dialog.notice2=Vous pouvez importer vos propres ponts ou ponts que vous avez le droit de copier et de modifier.
packEditor.anki.dialog.title=Import ANKI Flashcards
packEditor.anki.dialog.button=Parcourir
packEditor.anki.progress.sending=Envoi de fichier ...
packEditor.anki.progress.parsing=Dossier d'analyse ...
packEditor.anki.progress.forming=Insertion de résultat en forme ...
packEditor.anki.progress.error=L'importation a échoué.
packEditor.anki.progress.success=Opération complète. Les nouvelles cartes ont été ajoutées à la liste ci-dessous.
packEditor.addRow=Ajouter une carte
packEditor.card.term.placeholder=Terme
packEditor.card.term.needText=Besoin de texte ici
packEditor.card.def.placeholder=Définition
packEditor.card.def.needText=Besoin de texte ici
packEditor.importer.form.mode=Mode d'importation
packEditor.importer.form.mode.everyLine=Le terme et la définition sont dans chaque ligne, séparés par un délimiteur
packEditor.importer.form.mode.lineByLine=Le terme et la définition sont séquentiels, un élément par ligne
packEditor.importer.form.everyLine1=Délimiteur d'entrée
packEditor.importer.form.everyLine2=Délimiteur de terme / définition
packEditor.importer.form.everyLine3=Options additionelles
packEditor.importer.form.ldLine=Retour à la ligne
packEditor.importer.form.ldDoubleLine=Enroulement double ligne
packEditor.importer.form.ldCustom=Symbole personnalisé
packEditor.importer.form.ldJoinLowercase=Jointure de ligne commençant par un symbole minuscule
packEditor.importer.form.tdDash=Tiret (-)
packEditor.importer.form.tdTab=Languette
packEditor.importer.form.tdCustom=Symbole personnalisé
packEditor.importer.form.lineByLine1=Options additionelles
packEditor.importer.form.llDoubleLine=Double line wrap entre chaque groupe
packEditor.importer.form.llJoinLowercase=Jointure de ligne commençant par un symbole minuscule
packEditor.splitNewCards.notice=On dirait que vous avez beaucoup de cartes. Peut-être qu'il est raisonnable de diviser les cartes en plusieurs jeux
packEditor.splitNewCards.checkbox.1=Oui, casser les cartes en
packEditor.splitNewCards.checkbox.2=pièces dans chaque ensemble
packEditor.splitNewCards.shuffle=Et mélangez les cartes avant de les fendre
packEditor.splitNewCards.suffix=Partie {0}.
packEditor.putToCollection=Ajouter le pack à
packEditor.putToCollection.none=<aucun>
packEditor.putToCollection.createNew=collection ou {0}créer un nouveau{1}.
packEditor.images.search=Rechercher des images
packEditor.images.search.or=ou
packEditor.images.upload=Télécharger
packEditor.images.remove=Enlever l'image
packEditor.images.emptySearch=Aucune image trouvée. Essayez une autre requête.
packEditor.audio.record=Enregistrer
packEditor.audio.remove=Supprimer
packEditor.audio.upload=Télécharger
##
collectionForm.modal.title=Créer une nouvelle collection
collectionForm.name=Prénom
collectionForm.name.placeholder=Nom de la collection de type
collectionForm.description=La description
collectionForm.description.placeholder=Description de la collection de types
##
myPage.title.author=Mes propres sets
myPage.title.lastPacks=Mes derniers sets
myPage.title.favourite=Mes ensembles préférés
myPage.empty.last=Vous n'avez visité aucune série
myPage.empty.author=Vous n'avez créé aucun ensemble
myPage.empty.favourite=Votre liste de favoris est vide
##
packPage.header.from=par
packPage.buttonGroup.play=Jouer
packPage.button.play=Cartes
packPage.button.test=Tester
packPage.button.learn=Apprendre
packPage.button.write=Écrire
packPage.button.spell=Épeler
packPage.button.match=Rencontre
packPage.button.live=Habitent
packPage.solw.short=Apprentissage rapide
packPage.solw.short.subtitle=Répétez cet ensemble de cartes mémoire
packPage.solw.short.new=Nouveau:
packPage.solw.short.familiar=Familier:
packPage.solw.short.mastered=Maîtrisé:
packPage.solw.long=Apprentissage à long terme
packPage.solw.long.subtitle=Répétition espacée
packPage.solw.long.today=Tâche aujourd'hui
packPage.solw.long.new=Nouveau:
packPage.solw.long.review=Réviser:
packPage.button.memo=Note
ppp0.title=Vous voulez apprendre cet ensemble rapidement?
packPage.dialogs.forbidden.alert=Disponible uniquement pour les utilisateurs autorisés
packPage.dialogs.forbidden.addToCollection.text=Vous pouvez ajouter cet ensemble à l'une de vos collections.
packPage.dialogs.forbidden.addToCollection.alert=Disponible uniquement pour les utilisateurs autorisés
packPage.dialogs.forbidden.createNew.title=Bienvenue!
packPage.dialogs.forbidden.createNew.text=Seuls les utilisateurs autorisés peuvent créer de nouvelles cartes.
packPage.dialogs.forbidden.clone.text=Si vous utilisez ces cartes à des fins d'étude, mais que vous souhaitez les compléter, il suffit de les cloner et de créer le nouvel ensemble dont vous avez besoin.
packPage.dialogs.forbidden.clone.alert=Disponible uniquement pour les utilisateurs autorisés
packPage.dialogs.addToCollection.title=Ajouter cet ensemble à la collection
packPage.dialogs.addToCollection.notice=Vous pouvez ajouter cet ensemble de cartes mémoire à vos collections d'étude.
packPage.dialogs.addToCollection.createNew= + Ajouter une collection
packPage.dialogs.addToCollection.forbidden=Seuls les utilisateurs autorisés peuvent ajouter des cartes à leurs cours.
packPage.dialogs.addToFavourites.title=Ajouter cet ensemble à enregistré
packPage.dialogs.addToFavourites.forbidden=Vous pouvez ajouter enregistrer cet ensemble pour revenir plus tard
packPage.dialogs.login=Se connecter / s'inscrire
packPage.dialogs.share.title=Partager cet ensemble
packPage.dialogs.clone.title=Cloner cet ensemble
packPage.dialogs.clone.notice=Si vous utilisez ces cartes à des fins d'étude, mais que vous souhaitez les compléter, il suffit de les cloner et de créer le nouvel ensemble dont vous avez besoin.
packPage.command.edit=Modifier
packPage.command.customize=Personnaliser
packPage.command.addTo=Ajouter à ...
packPage.command.addTo.collection=collection
packPage.command.addTo.saved=enregistré
packPage.command.share=Partager
packPage.list.sorting.original=Original
packPage.list.sorting.alphabetical=Alphabétique
packPage.list.filter.all=Afficher tout
packPage.list.filter.difficult=Difficile
packPage.list.filter.banned=Ignoré
packPage.list.filter.mastered=Maîtrisé
packPage.list.filter.familiar=Familier
packPage.list.filter.starred=Étoilé
packPage.list.emptyMessage=Aucune carte ne convient à ce filtre
packPage.list.loadMore=Voir plus de cartes
packPage.review=Revoir
packPage.startMemo=Ajouter au bureau d'apprentissage à long terme
packPage.startLearning=Commencer à apprendre
packPage.speedReview=Examen de la vitesse
packEmbed.clickToFlip=Cliquez pour retourner
packPage.tooltips.memoShort=Un mode d'apprentissage rapide est utile lorsque vous devez répéter certains mots ou questions avant l'examen. Convient aux petits ensembles de mots.
packPage.tooltips.memoLong=Le mode d'apprentissage de la répétition espacée est basé sur la méthode de répétition d'intervalle. Il est utile pour la préparation systématique des examens et une éducation à long terme.
packPage.tooltips.preview=Voir tous les ensembles de cartes sans vous contrôler.
packPage.tooltips.test=Créez un test pour vérifier vos connaissances sur un ensemble de cartes.
packPage.tooltips.spell=Vérification orthographique. Test d'écoute et d'écriture.
##
packPlay.back=Retour
packPlay.options=Les options
packPlay.progress=Le progrès
packPlay.progressTemplate={0} sur {1}
packPlay.wrongDisplay.title=Mauvaise réponse
packPlay.wrongDisplay.label=Bonne réponse
packPlay.wrongDisplay.continueText=Appuyez sur n'importe quelle touche pour continuer
packPlay.wrongDisplay.userLabel=Tu as écrit
packPlay.wrongDisplay.typeText=Tapez la réponse correcte ci-dessous
##
packLearn.remaining=Restant
packLearn.familiar=Familier
packLearn.mastered=Maîtrisé
packLearn.write.placeholder=écris ta réponse ici
packLearn.write.placeholderCopy=Copier la bonne réponse ici
packLearn.write.submit=Réponse
packLearn.write.skip=Sauter
packLearn.write.stillCorrect=j'avais raison
packLearn.write.giveup=Abandonner
packLearn.dialogs.options.title=Les options
packLearn.dialogs.options.questionTitle=Répondre avec
packLearn.dialogs.options.questionTerm=Terme
packLearn.dialogs.options.questionDefinition=Définition
packLearn.dialogs.options.questionError=Au moins une case à cocher doit être cochée
packLearn.dialogs.options.modeTitle=Types de questions
packLearn.dialogs.options.modeSelect=Choix multiple
packLearn.dialogs.options.modeWrite=Écrit
packLearn.dialogs.options.modeError=Au moins une case à cocher doit être cochée
packLearn.dialogs.options.modeRandom=Ordre aléatoire
packLearn.dialogs.options.resetTitle=Réinitialiser les progrès
packLearn.dialogs.options.resetButton=Réinitialiser
packLearn.dialogs.options.modeCards=Flashcards
packLearn.dialogs.config.title=Options de mémo
packLearn.dialogs.firstTime.title=Configuration de la première fois
packLearn.dialogs.firstTime.notice=Vous pouvez modifier ces paramètres dans le menu "Options" à tout moment
packLearn.dialogs.options.writeAnyAnswer=Écrire une réponse
packLearn.dialogs.options.writeAnyAnswer.notice=Vous pouvez écrire une seule des réponses disponibles (qui sont séparées par une virgule).
packLearn.dialogs.options.playAudio=Jouer audio
packLearn.dialogs.options.newLimit=Nouveaux cartes limite
packLearn.dialogs.options.newLimitError=Il devrait être un nombre positif, moins ou égal à 50
packLearn.dialogs.options.order=Ordre de nouvelle
packLearn.dialogs.options.order.original=Original
packLearn.dialogs.options.order.random=Aléatoire
packLearn.dialogs.options.advancedMode=Mode avancé
packLearn.dialogs.options.advancedMode.notice=Par défaut "L'apprentissage à long terme" utilise un algorithme de répétition espacée d'apprentissage modifié où il suffit de choisir "savoir" ou "ne sait pas". En bascule vers «Mode avancé», vous pouvez utiliser un algorithme classique avec cinq options disponibles, similaires à SuperMemo ou Anki.
##
searchPage.title=Recherchez "{0}"
searchPage.head=Résultats de la requête "{0}"
searchPage.display.packs=Afficher les ensembles
searchPage.display.courses=Montrer des cours
searchPage.noResults.packs=Désolé, aucun jeu n'a été trouvé
searchPage.noResults.courses=Désolé, aucun cours n'a été trouvé
##
learnPage.progress.total=Passé
learnPage.progress.correct=Correct
learnPage.progress.wrong=Faux
learnPage.answer.go=Réponse
learnPage.answer.placeholder=Tapez votre réponse
learnPage.answer.unknown=Je ne sais pas
learnPage.wrong.stillCorrect=Ma réponse est correcte!
learnPage.next=Suivant
learnPage.result.actual=Ta Réponse
learnPage.result.correct=Bonne réponse
learnPage.header.correct=Bonne réponse
learnPage.header.wrong=Mauvaise réponse
learnPage.emptyQueue=Toutes nos félicitations! Vous avez passé toutes les cartes.
learnPage.restartQueue=Redémarrage
learnPage.reviewComplete.title=Toutes nos félicitations!
learnPage.reviewComplete.text=Vous avez passé toutes les cartes.
##
testSelectPage.opt.limit=Question limite
testSelectPage.opt.first=Spectacle
testSelectPage.start=Commence l'exercice
testSelectPage.opt.limit.prefix=Utilisation
testSelectPage.opt.limit.suffix=de {0}
testSelectPage.opt.first.face=Terme
testSelectPage.opt.first.back=Définition
testSelectPage.mode1=Rencontre
testSelectPage.mode2=Oui / Non
testSelectPage.mode3=4 options
testSelectPage.mode4=Écrire
##
testResult.message=Ton score:
testResult.correctTitle=Réponse attendue
testResult.noAnswer=Vous ne sélectionnez rien
testResult.noAnswerWrite=Tu n'écris rien
testCheck.answer=Réponse:
testCheck.answer.yes=Correct
testCheck.answer.no=Faux
testCheck.answer.correct=Correct!
testCheck.answer.wrong=Faux!
testWrite.input.placeholder=Tapez votre réponse ici
##
uknownPack.text=Peut-être que nous avions cet ensemble auparavant ... Malheureusement, ce n'est plus disponible :(
##
packTest.form.questionLimit=Question limite
packTest.form.questionLimit.of=de
packTest.form.questionLimit.invalid=Le nombre de questions est invalide.
packTest.form.questionWith=Question avec
packTest.form.questionWith.notSelected=Au moins une option est requise.
packTest.form.requireOne=Nécessite une seule réponse
packTest.form.requireOne.explain=Au moins une réponse correcte est requise. Les réponses doivent être séparées par une barre oblique (/), une virgule (,) ou un point-virgule (;)
packTest.form.types=Types de questions
packTest.form.types.notSelected=Au moins une option est requise.
packTest.form.type.choice=Choix multiple
packTest.form.type.yn=Vrai/faux
packTest.form.type.written=Écrit
packTest.form.type.matching=Correspondant à
packTest.start=Commence l'exercice
packTest.qsMatching=Questions pour l'appariement
packTest.qsMatching.expected=La réponse correcte est:
packTest.qsWritten=Questions pour l'écriture
packTest.qsWritten.inputPlaceholder=tapez votre réponse ici
packTest.qsWritten.expected=La réponse correcte est:
packTest.qsChoice=Questions pour le choix
packTest.qsChoice.expected=La réponse correcte est:
packTest.qsYN=Questions pour vrai/faux
packTest.qsYN.true=Vrai
packTest.qsYN.false=Faux
packTest.qsYN.expected=La réponse correcte est:
packTest.checkAnswers=Vérifier les réponses
packTest.score.prefix=Résultat du test:
packTest.score.90=Excellent!
packTest.score.75=Bien!
packTest.score.50=Moyenne
packTest.score.25=Pauvre
packTest.score.0=Faible
##
columns.cardsInPack=Qté
##
fexplorer.page.title={0} flashcards explorer
fexplorer.page.categoryTitle={0} cartes
fexplorer.title=Catalogue de cartes
fexplorer.popularsCategories=Catégories populaires
fexplorer.language=Ma langue
fexplorer.allCategories=Toutes catégories
fexplorer.collections.section=Des collections
fexplorer.flashcards.section=Flashcards
fexplorer.flashcards.terms=termes
fexplorer.empty.message=Veuillez sélectionner une catégorie <br> ou <br>
fexplorer.empty.search=Rechercher quelque chose
##
packPlay.progressBar.title=Le progrès
packPlay.head.play=Flashcards
packPlay.head.learn=Apprendre
packPlay.head.review=Revoir
packPlay.head.test=Test
packPlay.head.write=Écrivez
packPlay.head.memo=Note
packPlay.head.spell=Épeler
packPlay.head.match=Rencontre
##
packMemo.showAnswer=Montrer la réponse
packMemo.sessionComplete=Pas plus de cartes <br> pour aujourd'hui!
packMemo.sessionNextTime=Reviens à {0}.
packMemo.grades.button.H=À peine
packMemo.grades.button.E=Facile
packMemo.grades.button.M=Moyen
packMemo.grades.button.1=Mauvais
packMemo.grades.button.2=Faible
packMemo.grades.button.3=Moyen
packMemo.grades.button.4=Bon
packMemo.grades.button.5=Fort
packMemo.grades.button.Y=Ok, je me souviens
packMemo.grades.button.R=je connais
packMemo.grades.button.F=Je ne sais pas
packMemo.short.days=jo
packMemo.short.months=mo
packMemo.short.hours=h
packMemo.welcome.title=Bureau d'apprentissage à long terme
packMemo.welcome.text=C'est un planificateur de répétition espacé. Une fois que vous avez commencé à apprendre, vous apprendrez 20 cartes tous les jours. La fréquence de la carte montrant dépend de vos réponses. Ce mode est basé sur une célèbre algorithme de répétition espacée appelée SM-2. <a href="https://fr.wikipedia.org/wiki/supermemo"> En savoir plus. </a>
packMemo.welcome.start=Commençons
packMemo.welcome.login=Malheureusement, ce mode est disponible uniquement pour les utilisateurs autorisés. <br> Veuillez, <a href="/login"/login"> Connexion </a> ou <a href="/registration"> Inscrivez-vous </a>. <br> C'est facile et absolument gratuit.




packMemo.help.title=Indice
packMemo.help.line1=Barre d'espace pour montrer la réponse
packMemo.help.line2=1,2,3,4,5 pour faire votre choix
packMemo.help.hide=Cacher
packMemo.end.freshAvailable=Voulez-vous voir plus de nouvelles cartes aujourd'hui?
packMemo.end.freshAccept=Oui, montre-moi plus
##
fl.matchGame.welcome.text=Faites glisser des éléments les uns sur les autres pour les faire disparaître
fl.matchGame.startGame=Démarrer jeu
fl.matchGame.timer=Temps
fl.matchGame.preset.number=Compter
fl.matchGame.result.title=Toutes nos félicitations!
fl.matchGame.result.text=Vous marquez est {0} secondes
fl.matchGame.result.again=Rejouer
##
fl.memoHeatMap.title=Votre histoire d'apprentissage
fl.memoHeatMap.legend.daverage=Moyenne quotidienne:
fl.memoHeatMap.legend.daverage.value={0} {0,plural,one{carte}few{cartes}other{cartes}}
fl.memoHeatMap.legend.dlearned=Jours appris:

fl.memoHeatMap.legend.beststreak=Meilleure série:
fl.memoHeatMap.legend.beststreak.value={0} {0,plural,one{journée}few{jours}other{jours}}
fl.memoHeatMap.legend.currentstreak=Série actuelle:
fl.memoHeatMap.legend.currentstreak.value={0} {0,plural,one{journée}few{jours}other{jours}}
fl.memoHeatMap.tooltip.passed={0} {0,plural,one{réussi}few{passé des critiques}other{passé des critiques}}
fl.memoHeatMap.tooltip.failed={0} {0,plural,one{Échec de l'examen}few{Échec des commentaires}other{Échec des commentaires}}
fl.memoHeatMap.tooltip.fresh={0} {0,plural,one{carte fraîche}few{cartes fraîches}other{cartes fraîches}}
fl.memoHeatMap.tooltip.date=sur {0}
##
cardButtons.ban.title=Interdire cette carte
cardButtons.edit.title=Corrigez cette carte
cardButtons.star.title=Basculer la carte préférée
cardButtons.ban.prompt=Ignorer cette carte?
##
validation.cardText.tooLong=La longueur maximale de ce texte est de 1000 caractères
##
extLocale.la=Latin
extLocale.rw=Kinyarwanda
extLocale.af=afrikaans
extLocale.am=Amharique
extLocale.az=azerbaïdjanais
extLocale.bn=Bengale
extLocale.bs=Bosniaque
extLocale.ceb=Cebuano
extLocale.co=Corse
extLocale.cy=gallois
extLocale.eo=espéranto
extLocale.eu=basque
extLocale.fa=persan
extLocale.fy=frison
extLocale.gd=Écossais (gaélique)
extLocale.gl=Galicien
extLocale.gu=Gujarati
extLocale.ha=loger
extLocale.haw=hawaïen
extLocale.hmn=Hmong
extLocale.ht=Créole (Haïti)
extLocale.hy=arménien
extLocale.id=indonésien
extLocale.ig=Igbo
extLocale.jw=Javanais
extLocale.ka=géorgien
extLocale.kk=Kazakh
extLocale.km=Khmer
extLocale.kn=Kannada
extLocale.ku=Kurmanji
extLocale.ky=Kirghize
extLocale.lb=Luxembourg
extLocale.lo=Laotien
extLocale.mg=malgache
extLocale.mi=Maori
extLocale.ml=Malayalam
extLocale.mn=mongol
extLocale.mr=Marathi
extLocale.my=birman
extLocale.ne=népalais
extLocale.ny=CEVA
extLocale.or=Oria
extLocale.pa=Punjabi
extLocale.ps=Pashto
extLocale.sd=Sindhi
extLocale.si=Sinhalais
extLocale.sm=Samoan
extLocale.sn=Shona
extLocale.so=somali
extLocale.st=Sesotho
extLocale.su=soudanais
extLocale.sw=Swahili
extLocale.ta=Tamil
extLocale.te=Telugu
extLocale.tg=Tajik
extLocale.tk=Turkmène
extLocale.tl=Philippin
extLocale.tt=tatar
extLocale.ug=Uigur
extLocale.ur=Ourdou
extLocale.uz=Uzbek
extLocale.xh=Xhosa
extLocale.yi=yiddish
extLocale.yo=Yoruba
extLocale.zu=zoulou


# landing
fl.landing.title=Apprendre avec {0}
fl.landing.title.small=Savoir ce que {0} peut ajouter à votre processus d'apprentissage
fl.landing.why1.title=C'est facile et amusant
fl.landing.why1.text=Des milliers d'écoliers et d'étudiants effectuent une tâche éducative pour {0} chaque jour, améliorant ainsi son niveau d'évaluation. Sur le canapé, dans le métro, en ligne - il y aura toujours quelques minutes à étudier.
fl.landing.why2.title=Six modes de formation
fl.landing.why2.text=Ramassez ces outils mieux adaptés aux informations dont vous avez besoin pour apprendre.
fl.landing.why3.title=Répétition espacée
fl.landing.why3.text=Sur la base de l'étude de la psychologie, la méthode des répétitions d'intervalles vous permet de mémoriser de grandes quantités d'informations sans effort extraordinaire. Il suffit de chercher 10-15 minutes par jour.
fl.landing.why4.title=Sur n'importe quel appareil
fl.landing.why4.text=Exercice sur l'appareil que vous avez à portée de main. Peu importe, l'ordinateur, la tablette ou le téléphone - nos outils sont optimisés.
fl.landing.create.title=Créez vos jeux de cartes
fl.landing.create1.title=Créer mémoriser
fl.landing.create1.text=Pour préparer un ensemble, vous devez structurer et simplifier les informations. C'est l'un des meilleurs moyens de maîtriser le matériel. À l'avenir, vous aurez besoin de mettre à jour des souvenirs.
fl.landing.create2.title=Importation commode
fl.landing.create2.text=Profitez de l'outil flexible pour importer des cartes à partir de fichiers texte afin de télécharger des ensembles d'autres sources. Vous pouvez également importer un fichier ANKI.
fl.landing.create3.title=Modifier
fl.landing.create3.text=Sélectionnez l'ensemble approprié de notre base, changez-le et ajoutez-le en fonction de votre curriculum.
fl.landing.create.button=Créer un ensemble
fl.landing.gallery=Exemples
fl.landing.gallery.others=Voir d'autres exemples
fl.landing.stars1=3 millions d'étudiants par mois
fl.landing.stars2=100 000 000+ sessions de formation de <br>
fl.landing.stars3=1000 000+ <br> kits éducatifs
fl.landing.reg1=Inscrivez-vous maintenant et utilisez des cartes d'enseignement, des collections éducatives et des documents éducatifs utiles.
fl.landing.reg2=Pas de compte dans Google ou Facebook? Aucun problème! Inscrivez-vous en utilisant e-mail
## fast add card










## uninstall page
fl_uninstall_title=Merci d'avoir utilisé l'extension Studylib.
fl_uninstall_subtitle=Nous sommes désolés de te voir partir! Si vous avez un moment, veuillez nous dire comment nous pouvons mieux faire une extension:
fl_uninstall_notice=Vos commentaires nous aident à améliorer le produit pour tout le monde, merci 😊
fl_uninstall_button=Envoyer
fl_uninstall_success=À bientôt!

## APPEND ITEMS ABOVE THIS LINE