
defsPackEditor.title.placeholder=Inserisci il titolo (tema o qualcosa del genere)
defsPackEditor.description.placeholder=Inserire la descrizione
defsPackEditor.title.create=Crea nuove flashcards.
defsPackEditor.title.save=Modifica flashcards.
defsPackEditor.addPackImage=Aggiungi immagine
visibility.label=Visibile a.
visibility.0=Solo io
visibility.1=Tutti
## не удаляйте этот блок, на него есть ссылки в {plurals}
pl.card.one=carta
pl.card.few=carte
pl.card.other=carte
pl.day.one=giorno
pl.day.few=giorni
pl.day.other=giorni
pl.passedReview.one=Passato
pl.passedReview.few=Recensioni passate
pl.passedReview.other=Recensioni passate
pl.failedReview.one=Revisione fallita
pl.failedReview.few=recensioni non riuscite
pl.failedReview.other=recensioni non riuscite
pl.freshCard.one=carta fresca
pl.freshCard.few=carte fresche
pl.freshCard.other=carte fresche
pl.hours.one=ora
pl.hours.few=ore
pl.hours.other=ore
pl.hours={0} {0,plural,one{ora}few{ore}other{ore}}
pl.days.one=giorno
pl.days.few=giorni
pl.days.other=giorni
pl.days={0} {0,plural,one{giorno}few{giorni}other{giorni}}
pl.minutes.one=minuto
pl.minutes.few=minuti
pl.minutes.other=minuti
pl.minutes={0} {0,plural,one{minuto}few{minuti}other{minuti}}
## validation
rubd.flashcards.services.validation.SideTextWhenCardIsNotEmpty.message=La carta non è vuota, hai bisogno di un po 'di testo per questo lato
##
button.save=Salva
button.saveContinue=Salva e continua
button.create=Creare
button.cancel=Annulla
button.clone=Clone
button.print=Stampa
button.checkTest=Controlla i risultati
button.yes=sì
button.close=Chiudere
button.ok=Ok
button.help=Aiuto
##
title.label=Titolo
title.description=Descrizione
title.optional=(opzionale)
## duration messages
fl.agoString={0} fa
fl.agoRecently=Recentemente
fl.timeSpent={0} speso
fl.timeSpentLessThanMinute=Trascorso un po '
##
pack.cardCount={0} carte
pack.cardCount.one={0} carta
pack.cardCount.many={0} carte
pack.cardCount.few={0} carte
##
page.defaultTitle=Flashcard online.
## user private dashboard
fl.mainDashboard.packAgo.title=Tempo trascorso dall'ultima visita
fl.mainDashboard.packSpent.title=Tempo trascorso all'apprendimento
fl.mainDashboard.hideMemoPack=Questo set verrà rimosso dal dashboard di apprendimento a lungo termine. Per aggiungerlo indietro è necessario fare clic sull'apprendimento "Inizia" sulla pagina impostata.
##
packEditor.form.termLanguage=Lingua del termine
packEditor.form.termLanguage.placeholder=Scegli la lingua
packEditor.form.definitionLanguage=Lingua definition.
packEditor.form.definitionLanguage.placeholder=Scegli la lingua
packEditor.form.loading=Elenco delle carte di caricamento ...
packEditor.form.cardMinimum=Si prega di compilare almeno due flashcard
packEditor.importer.open=Importa da TXT, Word, Excel, Google Documenti, ecc.
packEditor.importer.close=Chiudere
packEditor.importer.import=Importare
packEditor.importer.textLabel=Copia e incolla il testo qui (da Word, Excel, Google Documenti, ecc.)
packEditor.importer.textPlaceholder=Parola 1    Definizione 1\nParola 2    Definizione 2\nParola 3    Definizione 3
packEditor.importer.result={0} Le voci sono state importate
packEditor.importer.preview.title=Anteprima delle carte
packEditor.importer.preview.empty=Nessuna carta è stata riconosciuta. Controllare le impostazioni di importazione nel pannello destro.
packEditor.anki.button=Importa Anki.
packEditor.anki.dialog.message=Carica il tuo file .apkg per importare il tuo deck flashcard anki.
packEditor.anki.dialog.notice1=Alcuni ponti non possono essere importati o possono essere importati con errori. Dipende dalla struttura del ponte. Ci dispiace per quello.
packEditor.anki.dialog.notice2=Puoi importare i tuoi mazzi o ponti che hai il diritto di copiare e modificare.
packEditor.anki.dialog.title=Importa flashcard di Anki.
packEditor.anki.dialog.button=Navigare
packEditor.anki.progress.sending=Invio di file ...
packEditor.anki.progress.parsing=File parsing ...
packEditor.anki.progress.forming=Inserendo il risultato in forma ...
packEditor.anki.progress.error=Import fallito.
packEditor.anki.progress.success=Operazione completa. Le nuove carte sono state aggiunte alla lista qui sotto.
packEditor.addRow=Aggiungi carta
packEditor.card.term.placeholder=Termine
packEditor.card.term.needText=Hai bisogno di testo qui
packEditor.card.def.placeholder=Definizione
packEditor.card.def.needText=Hai bisogno di testo qui
packEditor.importer.form.mode=Modalità di importazione
packEditor.importer.form.mode.everyLine=Termine e definizione sono in ogni linea, separati dal delimitatore
packEditor.importer.form.mode.lineByLine=Il termine e la definizione sono sequenziali, un elemento per riga
packEditor.importer.form.everyLine1=Delimitatore di ingresso (linea)
packEditor.importer.form.everyLine2=Termine / definizione delimitatore
packEditor.importer.form.everyLine3=Opzioni aggiuntive
packEditor.importer.form.ldLine=Linea avvolgente
packEditor.importer.form.ldDoubleLine=Wrap a doppia linea
packEditor.importer.form.ldCustom=Simbolo personalizzato
packEditor.importer.form.ldJoinLowercase=Iscriviti alla riga che inizia con il simbolo minuscolo
packEditor.importer.form.tdDash=Trattino (-)
packEditor.importer.form.tdTab=Tab
packEditor.importer.form.tdCustom=Simbolo personalizzato
packEditor.importer.form.lineByLine1=Opzioni aggiuntive
packEditor.importer.form.llDoubleLine=Avvolgibile a doppia linea tra ogni gruppo
packEditor.importer.form.llJoinLowercase=Iscriviti alla riga che inizia con il simbolo minuscolo
packEditor.splitNewCards.notice=Sembra che tu abbia molte carte. Forse è ragionevole per dividere le carte in più set
packEditor.splitNewCards.checkbox.1=Sì, rompere le carte
packEditor.splitNewCards.checkbox.2=pezzi in ogni set
packEditor.splitNewCards.shuffle=E carte shuffle prima dividere
packEditor.splitNewCards.suffix=Parte {0}.
packEditor.putToCollection=Aggiungi pacco a.
packEditor.putToCollection.none=<none >.
packEditor.putToCollection.createNew=Collezione o {0} Crea nuovo {1}.
packEditor.images.search=Cerca immagini.
packEditor.images.search.or=o
packEditor.images.upload=Caricamento
packEditor.images.remove=Rimuovi l'immagine
packEditor.images.emptySearch=Nessuna immagine trovata. Prova un'altra query.
packEditor.audio.record=Disco
packEditor.audio.remove=Rimuovere
packEditor.audio.upload=Caricamento
##
collectionForm.modal.title=Crea una nuova collezione
collectionForm.name=Nome
collectionForm.name.placeholder=Digitare il nome della raccolta
collectionForm.description=Descrizione
collectionForm.description.placeholder=Tipo Descrizione della raccolta
##
myPage.title.author=I miei set
myPage.title.lastPacks=I miei ultimi set.
myPage.title.favourite=I miei set preferiti
myPage.empty.last=Non hai visitato nessun set
myPage.empty.author=Non hai creato alcun set
myPage.empty.favourite=La tua lista preferita è vuota
##
packPage.header.from=di
packPage.buttonGroup.play=Giocare a
packPage.button.play=Anteprima
packPage.button.test=Test
packPage.button.learn=Imparare
packPage.button.write=Scrivi
packPage.button.spell=Fare lo spelling
packPage.button.match=Incontro
packPage.button.live=Abitare
packPage.solw.short=Apprendimento veloce
packPage.solw.short.subtitle=Ripeti questo insieme di flashcards
packPage.solw.short.new=Nuovo:
packPage.solw.short.familiar=Familiare:
packPage.solw.short.mastered=Masterizzato:
packPage.solw.long=Apprendimento a lungo termine
packPage.solw.long.subtitle=Ripetizione distanziata
packPage.solw.long.today=Oggi compiti
packPage.solw.long.new=Nuovo:
packPage.solw.long.review=Revisionare:
packPage.button.memo=Memo
ppp0.title=Vuoi imparare questo set rapidamente?
packPage.dialogs.forbidden.alert=Disponibile solo per gli utenti autorizzati
packPage.dialogs.forbidden.addToCollection.text=Puoi aggiungere questo set a una delle tue collezioni
packPage.dialogs.forbidden.addToCollection.alert=Disponibile solo per gli utenti autorizzati
packPage.dialogs.forbidden.createNew.title=Accoglienza!
packPage.dialogs.forbidden.createNew.text=Solo gli utenti autorizzati possono creare nuove carte.
packPage.dialogs.forbidden.clone.text=Se usi queste carte per lo studio, ma vuoi integrarli, clonilili e creare un nuovo set di cui hai bisogno.
packPage.dialogs.forbidden.clone.alert=Disponibile solo per gli utenti autorizzati
packPage.dialogs.addToCollection.title=Aggiungi questo set per la raccolta
packPage.dialogs.addToCollection.notice=Puoi aggiungere questo set di flashcard alle tue raccolte di studio
packPage.dialogs.addToCollection.createNew=+ Aggiungi nuova collezione
packPage.dialogs.addToCollection.forbidden=Solo gli utenti autorizzati possono aggiungere carte ai loro corsi
packPage.dialogs.addToFavourites.title=Aggiungi questo set su Salvato
packPage.dialogs.addToFavourites.forbidden=Puoi aggiungere Salva questo set per tornare indietro più tardi
packPage.dialogs.login=Accedi / registrati.
packPage.dialogs.share.title=Condividi questo set.
packPage.dialogs.clone.title=Clona questo set.
packPage.dialogs.clone.notice=Se usi queste carte per lo studio, ma vuoi integrarli, clonilili e creare un nuovo set di cui hai bisogno.
packPage.command.edit=Modificare
packPage.command.customize=personalizzare
packPage.command.addTo=Aggiungere a ...
packPage.command.addTo.collection=collezione
packPage.command.addTo.saved=salvato
packPage.command.share=Condividere
packPage.list.sorting.original=Originale
packPage.list.sorting.alphabetical=Alfabetico
packPage.list.filter.all=Mostra tutto
packPage.list.filter.difficult=Difficile
packPage.list.filter.banned=Ignorato
packPage.list.filter.mastered=Masterizzato
packPage.list.filter.familiar=Familiare
packPage.list.filter.starred=Ha recitato
packPage.list.emptyMessage=Nessuna carta si adatta a questo filtro
packPage.list.loadMore=Visualizza altre carte
packPage.review=Recensione
packPage.startMemo=Aggiungi al banco di apprendimento a lungo termine
packPage.startLearning=Inizia l'apprendimento
packPage.speedReview=Revisione della velocità
packEmbed.clickToFlip=Clicca per capovolgere
packPage.tooltips.memoShort=Una modalità di apprendimento veloce è utile quando è necessario ripetere alcune parole o domande prima dell'esame. Adatto per piccoli gruppi di parole.
packPage.tooltips.memoLong=La modalità di apprendimento della ripetizione distanziata si basa sul metodo di ripetizione dell'intervallo. È utile per la preparazione sistematica dell'esame e l'istruzione a lungo termine.
packPage.tooltips.preview=Guarda tutti i set di carte senza controllare te stesso.
packPage.tooltips.test=Crea un test per verificare le tue conoscenze su una serie di carte.
packPage.tooltips.spell=Controllo ortografico. Test di ascolto e scrittura.
##
packPlay.back=Di ritorno
packPlay.options=Opzioni
packPlay.progress=Progresso
packPlay.progressTemplate={0} di {1}
packPlay.wrongDisplay.title=Risposta sbagliata
packPlay.wrongDisplay.label=Risposta corretta
packPlay.wrongDisplay.continueText=premere un tasto qualsiasi per continuare
packPlay.wrongDisplay.userLabel=Hai scritto
packPlay.wrongDisplay.typeText=Digita risposta corretta qui sotto
##
packLearn.remaining=Rimanente
packLearn.familiar=Familiare
packLearn.mastered=Masterizzato
packLearn.write.placeholder=Scrivi la tua risposta qui
packLearn.write.placeholderCopy=Copia la risposta corretta qui
packLearn.write.submit=Risposta
packLearn.write.skip=Saltare
packLearn.write.stillCorrect=avevo ragione
packLearn.write.giveup=Mollare
packLearn.dialogs.options.title=Opzioni
packLearn.dialogs.options.questionTitle=Risposta con
packLearn.dialogs.options.questionTerm=Termine
packLearn.dialogs.options.questionDefinition=Definizione
packLearn.dialogs.options.questionError=Almeno una casella di controllo deve essere controllata
packLearn.dialogs.options.modeTitle=Tipi di domanda
packLearn.dialogs.options.modeSelect=Scelta multipla
packLearn.dialogs.options.modeWrite=Scritto
packLearn.dialogs.options.modeError=Almeno una casella di controllo deve essere controllata
packLearn.dialogs.options.modeRandom=Ordine casuale
packLearn.dialogs.options.resetTitle=Ripristinare i progressi
packLearn.dialogs.options.resetButton=Ripristina
packLearn.dialogs.options.modeCards=Flashcards.
packLearn.dialogs.config.title=Opzioni di memo
packLearn.dialogs.firstTime.title=Setup di prima volta
packLearn.dialogs.firstTime.notice=È possibile modificare queste impostazioni nel menu "Opzioni" in qualsiasi momento
packLearn.dialogs.options.writeAnyAnswer=Scrivi qualsiasi risposta
packLearn.dialogs.options.writeAnyAnswer.notice=Puoi scrivere solo una delle risposte disponibili (che sono separate da Comma).
packLearn.dialogs.options.playAudio=Gioca a Audio.
packLearn.dialogs.options.newLimit=Limite nuove carte
packLearn.dialogs.options.newLimitError=Dovrebbe essere un numero positivo, meno o uguale a 50
packLearn.dialogs.options.order=Ordine di nuovo
packLearn.dialogs.options.order.original=Originale
packLearn.dialogs.options.order.random=A caso
packLearn.dialogs.options.advancedMode=Modalità avanzata
packLearn.dialogs.options.advancedMode.notice=Per impostazione predefinita "apprendimento a lungo termine" utilizza un algoritmo di ripetizione distanziata di apprendimento modificato dove è sufficiente scegliere solo "conoscere" o "non so". Per disattivare a "Modalità avanzata", è possibile utilizzare un algoritmo classico con cinque opzioni disponibili, simili a SuperMemo o Anki.
##
searchPage.title=Cerca "{0}"
searchPage.head=Risultati per query "{0}"
searchPage.display.packs=Visualizza set.
searchPage.display.courses=Mostra corsi
searchPage.noResults.packs=Siamo spiacenti, nessun set è stato trovato
searchPage.noResults.courses=Siamo spiacenti, nessun corso è stato trovato
##
learnPage.progress.total=Passato
learnPage.progress.correct=Corretta
learnPage.progress.wrong=Sbagliato
learnPage.answer.go=Risposta
learnPage.answer.placeholder=Scrivi la tua risposta
learnPage.answer.unknown=Non lo so
learnPage.wrong.stillCorrect=La mia risposta è corretta!
learnPage.next=Prossimo
learnPage.result.actual=La tua risposta
learnPage.result.correct=Risposta corretta
learnPage.header.correct=Risposta corretta
learnPage.header.wrong=Risposta sbagliata
learnPage.emptyQueue=Congratulazioni! Hai passato tutte le carte.
learnPage.restartQueue=Ricomincia
learnPage.reviewComplete.title=Congratulazioni!
learnPage.reviewComplete.text=Hai passato tutte le carte.
##
testSelectPage.opt.limit=Limite di domanda
testSelectPage.opt.first=Spettacolo
testSelectPage.start=Inizia il test
testSelectPage.opt.limit.prefix=Utilizzo
testSelectPage.opt.limit.suffix=di {0}
testSelectPage.opt.first.face=Termine
testSelectPage.opt.first.back=Definizione
testSelectPage.mode1=Incontro
testSelectPage.mode2=Si No
testSelectPage.mode3=4 opzioni
testSelectPage.mode4=Scrivi
##
testResult.message=Il tuo punteggio:
testResult.correctTitle=Risposta prevista
testResult.noAnswer=Se ne hai selezionato nulla
testResult.noAnswerWrite=Non scrivi nulla
testCheck.answer=Risposta:
testCheck.answer.yes=Corretta
testCheck.answer.no=Sbagliato
testCheck.answer.correct=Corretta!
testCheck.answer.wrong=Sbagliato!
testWrite.input.placeholder=Digita la tua risposta qui
##
uknownPack.text=Forse abbiamo avuto questo set prima ... sfortunatamente, non è più disponibile :(
##
packTest.form.questionLimit=Limite di domanda
packTest.form.questionLimit.of=di
packTest.form.questionLimit.invalid=Il conteggio delle domande non è valido.
packTest.form.questionWith=Domanda con
packTest.form.questionWith.notSelected=È richiesta almeno un'opzione.
packTest.form.requireOne=Richiedono solo una risposta
packTest.form.requireOne.explain=È richiesta almeno una risposta corretta. Le risposte devono essere separate da una barra (/), una virgola (,) o un punto e virgola (;)
packTest.form.types=Tipi di domanda
packTest.form.types.notSelected=È richiesta almeno un'opzione.
packTest.form.type.choice=Scelta multipla
packTest.form.type.yn=Vero falso
packTest.form.type.written=Scritto
packTest.form.type.matching=Abbinamento
packTest.start=Inizia il test
packTest.qsMatching=Domande per la corrispondenza
packTest.qsMatching.expected=La risposta corretta è:
packTest.qsWritten=Domande per la scrittura
packTest.qsWritten.inputPlaceholder=Digita la tua risposta qui
packTest.qsWritten.expected=La risposta corretta è:
packTest.qsChoice=Domande per la scelta
packTest.qsChoice.expected=La risposta corretta è:
packTest.qsYN=Domande per true / false
packTest.qsYN.true=Vero
packTest.qsYN.false=Falso
packTest.qsYN.expected=La risposta corretta è:
packTest.checkAnswers=Controlla le risposte
packTest.score.prefix=Risultato del test:
packTest.score.90=Eccellente!
packTest.score.75=Bene!
packTest.score.50=Media
packTest.score.25=Povero
packTest.score.0=Debole
##
columns.cardsInPack=Carte
##
fexplorer.page.title={0} Flashcards Explorer
fexplorer.page.categoryTitle={0} flashcards
fexplorer.title=Flashcards Explorer.
fexplorer.popularsCategories=Categorie popolari
fexplorer.language=Mio linguaggio
fexplorer.allCategories=Tutte le categorie
fexplorer.collections.section=Collezioni
fexplorer.flashcards.section=Flashcards.
fexplorer.flashcards.terms=Termini
fexplorer.empty.message=Si prega di selezionare una categoria <br> o <br>
fexplorer.empty.search=Cerca qualcosa
##
packPlay.progressBar.title=Progresso
packPlay.head.play=Flashcards.
packPlay.head.learn=Imparare
packPlay.head.review=Recensione
packPlay.head.test=Test
packPlay.head.write=Scrivi
packPlay.head.memo=Memo
packPlay.head.spell=Fare lo spelling
packPlay.head.match=Incontro
##
packMemo.showAnswer=Mostra risposta
packMemo.sessionComplete=Niente più carte per oggi!
packMemo.sessionNextTime=Torna in {0}.
packMemo.grades.button.H=Quasi
packMemo.grades.button.E=Facile
packMemo.grades.button.M=Medio
packMemo.grades.button.1=Cattivo
packMemo.grades.button.2=Debole
packMemo.grades.button.3=medio
packMemo.grades.button.4=Bene
packMemo.grades.button.5=Forte
packMemo.grades.button.Y=Ok, ricordo
packMemo.grades.button.R=lo so
packMemo.grades.button.F=Non lo so
packMemo.short.days=g
packMemo.short.months=me
packMemo.short.hours=o
packMemo.welcome.title=Scrivania di apprendimento a lungo termine
packMemo.welcome.text=È uno scheduler di ripetizione distanziato. Una volta iniziato il processo di apprendimento imparerai 20 carte ogni giorno. La frequenza della presentazione della carta dipende dalle tue risposte. Questa modalità è basata sul famoso algoritmo di ripetizione distanziato chiamato SM-2. <br> <a href="https://en.wikipedia.org/wiki/supermemo"> Leggi di più. </a>
packMemo.welcome.start=Iniziamo
packMemo.welcome.login=Sfortunatamente, questa modalità è disponibile solo per gli utenti autorizzati. <br> per favore, <a href="/login"> Accedi </a> o <ref ="/registration"> Iscriviti </a>. <br> È facile e assolutamente gratuito.




packMemo.help.title=Suggerimento
packMemo.help.line1=Barra spaziatrice per mostrare la risposta
packMemo.help.line2=1,2,3,4,5 per fare la tua scelta
packMemo.help.hide=Nascondere
packMemo.end.freshAvailable=Vuoi vedere altre nuove carte oggi?
packMemo.end.freshAccept=Sì, mostrami di più
##
fl.matchGame.welcome.text=Trascina oggetti l'uno sull'altro per farli discassare
fl.matchGame.startGame=Inizia il gioco
fl.matchGame.timer=Tempo
fl.matchGame.preset.number=Contare
fl.matchGame.result.title=Congratulazioni!
fl.matchGame.result.text=Il punteggio è {0} secondi
fl.matchGame.result.again=Gioca di nuovo
##
fl.memoHeatMap.title=La tua storia di apprendimento
fl.memoHeatMap.legend.daverage=Media giornaliera:
fl.memoHeatMap.legend.daverage.value={0} {0,plural,one{carta}few{carte}other{carte}}
fl.memoHeatMap.legend.dlearned=Giorni appresi:

fl.memoHeatMap.legend.beststreak=Best Streak:
fl.memoHeatMap.legend.beststreak.value={0} {0,plural,one{giorno}few{giorni}other{giorni}}
fl.memoHeatMap.legend.currentstreak=Streak corrente:
fl.memoHeatMap.legend.currentstreak.value={0} {0,plural,one{giorno}few{giorni}other{giorni}}
fl.memoHeatMap.tooltip.passed={0} {0,plural,one{Passato}few{Recensioni passate}other{Recensioni passate}}
fl.memoHeatMap.tooltip.failed={0} {0,plural,one{Revisione fallita}few{recensioni non riuscite}other{recensioni non riuscite}}
fl.memoHeatMap.tooltip.fresh={0} {0,plural,one{carta fresca}few{carte fresche}other{carte fresche}}
fl.memoHeatMap.tooltip.date=su {0}
##
cardButtons.ban.title=Ban questa carta
cardButtons.edit.title=Correggi questa carta
cardButtons.star.title=Attiva / disattiva la carta preferita
cardButtons.ban.prompt=Ignora questa carta?
##
validation.cardText.tooLong=La lunghezza massima di questo testo è di 1000 caratteri
##
extLocale.la=latino
extLocale.rw=Kinyarwanda.
extLocale.af=afrikaans
extLocale.am=Amharic.
extLocale.az=Azerbaijani.
extLocale.bn=Bengala
extLocale.bs=Bosniaco
extLocale.ceb=Cebuano.
extLocale.co=Corsican.
extLocale.cy=gallese
extLocale.eo=esperanto
extLocale.eu=Basco
extLocale.fa=persiano
extLocale.fy=Frisone
extLocale.gd=Scot (gaelico)
extLocale.gl=galiziano
extLocale.gu=Gujarati.
extLocale.ha=Casa
extLocale.haw=hawaiano
extLocale.hmn=Hmong.
extLocale.ht=Creolo (haiti)
extLocale.hy=armeno
extLocale.id=indonesiano
extLocale.ig=Igbo.
extLocale.jw=giavanese
extLocale.ka=georgiano
extLocale.kk=Kazako.
extLocale.km=Khmer.
extLocale.kn=Kannada.
extLocale.ku=Kurmanji.
extLocale.ky=Kirghiz
extLocale.lb=Lussemburgo
extLocale.lo=Laotian.
extLocale.mg=malgascio
extLocale.mi=Maori
extLocale.ml=Malayalam.
extLocale.mn=mongolo
extLocale.mr=Marathi
extLocale.my=birmano
extLocale.ne=Nepali.
extLocale.ny=Ceva.
extLocale.or=Oria.
extLocale.pa=Punjabi.
extLocale.ps=Pashto.
extLocale.sd=Sindhi.
extLocale.si=Singalese
extLocale.sm=Samoan.
extLocale.sn=Shona.
extLocale.so=Somalo
extLocale.st=Sesototo.
extLocale.su=sudanese
extLocale.sw=Swahili.
extLocale.ta=Tamil
extLocale.te=Telugu.
extLocale.tg=Tajik.
extLocale.tk=Turkmen.
extLocale.tl=Filippine
extLocale.tt=Tatar.
extLocale.ug=Uigur
extLocale.ur=Urdu
extLocale.uz=Uzbeke.
extLocale.xh=Xhosa.
extLocale.yi=yiddish
extLocale.yo=Yoruba.
extLocale.zu=Zuli


# landing
fl.landing.title=Imparare con {0}
fl.landing.title.small=Sapere cosa {0} può aggiungere al tuo processo di apprendimento
fl.landing.why1.title=È facile e divertente
fl.landing.why1.text=Migliaia di scolari e studenti svolgono un compito educativo per {0} ogni giorno, migliorando il suo livello di valutazioni. Sul divano, nella metropolitana, in linea - ci saranno sempre alcuni minuti per studiare.
fl.landing.why2.title=Sei modalità di allenamento
fl.landing.why2.text=Raccogli quegli strumenti più adatti alle informazioni necessarie per imparare.
fl.landing.why3.title=Ripetizione distanziata
fl.landing.why3.text=Sulla base dello studio della psicologia, il metodo delle ripetizioni dell'intervallo consente di memorizzare grandi quantità di informazioni senza sforzi straordinari. Basta cercare 10-15 minuti al giorno.
fl.landing.why4.title=Su qualsiasi dispositivo
fl.landing.why4.text=Esercitare sul dispositivo che hai a portata di mano. Non importa, computer, tablet o telefono - i nostri strumenti sono ottimizzati.
fl.landing.create.title=Crea i set di carte
fl.landing.create1.title=Creazione di memorizzazione
fl.landing.create1.text=Per preparare un set, è necessario strutturare e semplificare le informazioni. Questo è uno dei modi migliori per padroneggiare il materiale. In futuro, dovrai solo aggiornare i ricordi.
fl.landing.create2.title=Importazione conveniente
fl.landing.create2.text=Approfitta dello strumento flessibile per l'importazione di carte dai file di testo al fine di scaricare set da altre fonti. Puoi anche importare il file anki.
fl.landing.create3.title=Modificare
fl.landing.create3.text=Selezionare il set appropriato dalla nostra base, cambiarlo e aggiungerlo in base al tuo curriculum.
fl.landing.create.button=Creare un set.
fl.landing.gallery=Esempi
fl.landing.gallery.others=Guarda altri esempi
fl.landing.stars1=3 milioni <br> Studenti ogni mese
fl.landing.stars2=100 000 000+ <br> Sessioni di formazione
fl.landing.stars3=1000 000+ <br> Kit educativi
fl.landing.reg1=Iscriviti ora e usa le carte educative di formazione dell'intervallo, raccolte educative e documenti educativi utili.
fl.landing.reg2=Nessun account in Google o Facebook? Nessun problema! Iscriviti usando e-mail
## fast add card










## uninstall page
fl_uninstall_title=Grazie per aver utilizzato un'estensione di StudyLib.
fl_uninstall_subtitle=Ci dispiace vederti andare! Se hai un momento, ti preghiamo di dirci come possiamo fare un'estensione migliore:
fl_uninstall_notice=Il tuo feedback ci aiuta a migliorare il prodotto per tutti, grazie 😊
fl_uninstall_button=Spedire
fl_uninstall_success=A presto!

## APPEND ITEMS ABOVE THIS LINE