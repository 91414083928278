
defsPackEditor.title.placeholder=Enter title (theme or something)
defsPackEditor.description.placeholder=Enter description
defsPackEditor.title.create=Create new flashcards
defsPackEditor.title.save=Edit flashcards
defsPackEditor.addPackImage=Add image
visibility.label=Visible to
visibility.0=Just me
visibility.1=Everyone
## не удаляйте этот блок, на него есть ссылки в {plurals}
pl.card.one=card
pl.card.few=cards
pl.card.other=cards
pl.day.one=day
pl.day.few=days
pl.day.other=days
pl.passedReview.one=passed review
pl.passedReview.few=passed reviews
pl.passedReview.other=passed reviews
pl.failedReview.one=failed review
pl.failedReview.few=failed reviews
pl.failedReview.other=failed reviews
pl.freshCard.one=fresh card
pl.freshCard.few=fresh cards
pl.freshCard.other=fresh cards
pl.hours.one=hour
pl.hours.few=hours
pl.hours.other=hours
pl.hours={0} {0,plural,one{hour}few{hours}other{hours}}
pl.days.one=day
pl.days.few=days
pl.days.other=days
pl.days={0} {0,plural,one{day}few{days}other{days}}
pl.minutes.one=minute
pl.minutes.few=minutes
pl.minutes.other=minutes
pl.minutes={0} {0,plural,one{minute}few{minutes}other{minutes}}
## validation
rubd.flashcards.services.validation.SideTextWhenCardIsNotEmpty.message=Card is not empty, you need some text for this side
##
button.save=Save
button.saveContinue=Save and continue
button.create=Create
button.cancel=Cancel
button.clone=Clone
button.print=Print
button.checkTest=Check results
button.yes=Yes
button.close=Close
button.ok=Ok
button.help=Help
##
title.label=Title
title.description=Description
title.optional=(optional)
## duration messages
fl.agoString={0} ago
fl.agoRecently=Recently
fl.timeSpent={0} spent
fl.timeSpentLessThanMinute=Spent a little bit
##
pack.cardCount={0} cards
pack.cardCount.one={0} card
pack.cardCount.many={0} cards
pack.cardCount.few={0} cards
##
page.defaultTitle=Online FlashCards
## user private dashboard
fl.mainDashboard.packAgo.title=Elapsed time since last visit
fl.mainDashboard.packSpent.title=Time spent learning
fl.mainDashboard.hideMemoPack=This set will be removed from Long Term Learning Dashboard. To add it back you will need to click the "Start learning" on set page.
##
packEditor.form.termLanguage=Term language
packEditor.form.termLanguage.placeholder=Choose language
packEditor.form.definitionLanguage=Definition language
packEditor.form.definitionLanguage.placeholder=Choose language
packEditor.form.loading=Loading card list...
packEditor.form.cardMinimum=Please fill in at least two flashcards
packEditor.importer.open=Import from txt, Word, Excel, Google Docs, etc.
packEditor.importer.close=Close
packEditor.importer.import=Import
packEditor.importer.textLabel=Copy and paste text here (from Word, Excel, Google Docs, etc)
packEditor.importer.textPlaceholder=Word 1        Definition 1\nWord 2        Definition 2\nWord 3        Definition 3
packEditor.importer.result={0} entries were imported
packEditor.importer.preview.title=Cards preview
packEditor.importer.preview.empty=No cards have been recognized. Check import settings in right panel.
packEditor.anki.button=Import Anki
packEditor.anki.dialog.message=Upload your .APKG file to import your ANKI flashcards deck.
packEditor.anki.dialog.notice1=Some decks cannot be imported or may be imported with errors. It depends on the structure of the deck. We are sorry about that.
packEditor.anki.dialog.notice2=You may import your own decks or decks that you have the right to copy and modify. 
packEditor.anki.dialog.title=Import Anki flashcards
packEditor.anki.dialog.button=Browse
packEditor.anki.progress.sending=Sending file...
packEditor.anki.progress.parsing=Parsing file...
packEditor.anki.progress.forming=Inserting result into form...
packEditor.anki.progress.error=Import failed.
packEditor.anki.progress.success=Operation complete. New cards have been added to the list below.
packEditor.addRow=Add card
packEditor.card.term.placeholder=Term
packEditor.card.term.needText=Need text here
packEditor.card.def.placeholder=Definition
packEditor.card.def.needText=Need text here
packEditor.importer.form.mode=Import mode
packEditor.importer.form.mode.everyLine=Term and definition are in every line, separated by delimiter
packEditor.importer.form.mode.lineByLine=The term and definition are sequential, one element per line
packEditor.importer.form.everyLine1=Entry (line) delimiter
packEditor.importer.form.everyLine2=Term/definition delimiter
packEditor.importer.form.everyLine3=Additional options
packEditor.importer.form.ldLine=Line wrap
packEditor.importer.form.ldDoubleLine=Double line wrap
packEditor.importer.form.ldCustom=Custom symbol
packEditor.importer.form.ldJoinLowercase=Join line that starts with lowercase symbol
packEditor.importer.form.tdDash=Dash (-)
packEditor.importer.form.tdTab=Tab
packEditor.importer.form.tdCustom=Custom symbol
packEditor.importer.form.lineByLine1=Additional options
packEditor.importer.form.llDoubleLine=Double line wrap between every group
packEditor.importer.form.llJoinLowercase=Join line that starts with lowercase symbol
packEditor.splitNewCards.notice=It looks like you have many cards. Maybe it's reasonable to split cards into multiple sets
packEditor.splitNewCards.checkbox.1=Yes, break cards into
packEditor.splitNewCards.checkbox.2=pieces in each set
packEditor.splitNewCards.shuffle=And shuffle cards before split
packEditor.splitNewCards.suffix=Part {0}.
packEditor.putToCollection=Add pack to
packEditor.putToCollection.none=<none>
packEditor.putToCollection.createNew=collection or {0}create new{1}.
packEditor.images.search=Search images
packEditor.images.search.or=or
packEditor.images.upload=Upload
packEditor.images.remove=Remove Image
packEditor.images.emptySearch=No images found. Try another query.
packEditor.audio.record=Record
packEditor.audio.remove=Remove
packEditor.audio.upload=Upload
##
collectionForm.modal.title=Create new collection
collectionForm.name=Name
collectionForm.name.placeholder=Type collection name
collectionForm.description=Description
collectionForm.description.placeholder=Type collection description
##
myPage.title.author=My own sets
myPage.title.lastPacks=My last sets
myPage.title.favourite=My favourite sets
myPage.empty.last=You have not visited any sets
myPage.empty.author=You have not created any sets
myPage.empty.favourite=Your favourite list is empty
##
packPage.header.from=by
packPage.buttonGroup.play=Play
packPage.button.play=Preview
packPage.button.test=Test
packPage.button.learn=Learn
packPage.button.write=Write
packPage.button.spell=Spell
packPage.button.match=Match
packPage.button.live=Live
packPage.solw.short=Fast learning
packPage.solw.short.subtitle=Repeat this set of flashcards
packPage.solw.short.new=New:
packPage.solw.short.familiar=Familiar:
packPage.solw.short.mastered=Mastered:
packPage.solw.long=Long Term Learning
packPage.solw.long.subtitle=Spaced Repetition
packPage.solw.long.today=Today task
packPage.solw.long.new=New:
packPage.solw.long.review=To review:
packPage.button.memo=Memo
ppp0.title=Want to learn this set quickly?
packPage.dialogs.forbidden.alert=Available only to authorized users
packPage.dialogs.forbidden.addToCollection.text=You can add this set to one of your collections
packPage.dialogs.forbidden.addToCollection.alert=Available only to authorized users
packPage.dialogs.forbidden.createNew.title=Welcome!
packPage.dialogs.forbidden.createNew.text=Only authorized users can create new cards.
packPage.dialogs.forbidden.clone.text=If you use these cards for study, but you want to supplement them, just clone them and create a new set that you need.
packPage.dialogs.forbidden.clone.alert=Available only to authorized users
packPage.dialogs.addToCollection.title=Add this set to collection
packPage.dialogs.addToCollection.notice=You can add this set of flashcards to your study collections
packPage.dialogs.addToCollection.createNew=+ Add New Collection
packPage.dialogs.addToCollection.forbidden=Only authorized users can add cards to their courses
packPage.dialogs.addToFavourites.title=Add this set to saved
packPage.dialogs.addToFavourites.forbidden=You can add save this set to return back later
packPage.dialogs.login=Login / register
packPage.dialogs.share.title=Share this set
packPage.dialogs.clone.title=Clone this set
packPage.dialogs.clone.notice=If you use these cards for study, but you want to supplement them, just clone them and create a new set that you need.
packPage.command.edit=Edit
packPage.command.customize=Customize
packPage.command.addTo=Add to ...
packPage.command.addTo.collection=collection
packPage.command.addTo.saved=saved
packPage.command.share=Share
packPage.list.sorting.original=Original
packPage.list.sorting.alphabetical=Alphabetical
packPage.list.filter.all=Show all
packPage.list.filter.difficult=Difficult
packPage.list.filter.banned=Ignored
packPage.list.filter.mastered=Mastered
packPage.list.filter.familiar=Familiar
packPage.list.filter.starred=Starred
packPage.list.emptyMessage=No card fits this filter
packPage.list.loadMore=View more cards
packPage.review=Review
packPage.startMemo=Add to Long Term Learning Desk
packPage.startLearning=Start learning
packPage.speedReview=Speed review
packEmbed.clickToFlip=Click to flip
packPage.tooltips.memoShort=A fast learning mode is helpful when you need to repeat some words or questions before the exam. Suitable for small sets of words.
packPage.tooltips.memoLong=The spaced repetition learning mode is based on the interval repetition method. It is useful for systematic exam preparation and long-term education.
packPage.tooltips.preview=View all card sets without controlling yourself.
packPage.tooltips.test=Create a test to check your knowledge on a set of cards.
packPage.tooltips.spell=Spell check. Listening and writing test.
##
packPlay.back=Back
packPlay.options=Options
packPlay.progress=Progress
packPlay.progressTemplate={0} of {1}
packPlay.wrongDisplay.title=Wrong answer
packPlay.wrongDisplay.label=Correct answer
packPlay.wrongDisplay.continueText=Press any key to continue
packPlay.wrongDisplay.userLabel=You wrote
packPlay.wrongDisplay.typeText=Type correct answer below
##
packLearn.remaining=Remaining
packLearn.familiar=Familiar
packLearn.mastered=Mastered
packLearn.write.placeholder=write your answer here
packLearn.write.placeholderCopy=copy the correct answer here
packLearn.write.submit=Answer
packLearn.write.skip=Skip
packLearn.write.stillCorrect=I was right
packLearn.write.giveup=Give up
packLearn.dialogs.options.title=Options
packLearn.dialogs.options.questionTitle=Answer with
packLearn.dialogs.options.questionTerm=Term
packLearn.dialogs.options.questionDefinition=Definition
packLearn.dialogs.options.questionError=At least one checkbox should be checked
packLearn.dialogs.options.modeTitle=Question types
packLearn.dialogs.options.modeSelect=Multiple choice
packLearn.dialogs.options.modeWrite=Written
packLearn.dialogs.options.modeError=At least one checkbox should be checked
packLearn.dialogs.options.modeRandom=Random order
packLearn.dialogs.options.resetTitle=Reset progress
packLearn.dialogs.options.resetButton=Reset
packLearn.dialogs.options.modeCards=Flashcards
packLearn.dialogs.config.title=Memo options
packLearn.dialogs.firstTime.title=First-time setup
packLearn.dialogs.firstTime.notice=You can change these settings in the "Options" menu anytime
packLearn.dialogs.options.writeAnyAnswer=Write any answer
packLearn.dialogs.options.writeAnyAnswer.notice=You may write only one of available answers (that are separated by comma).
packLearn.dialogs.options.playAudio=Play audio
packLearn.dialogs.options.newLimit=New cards limit
packLearn.dialogs.options.newLimitError=It should be positive number, less or equal 50
packLearn.dialogs.options.order=Order of new
packLearn.dialogs.options.order.original=Original
packLearn.dialogs.options.order.random=Random
packLearn.dialogs.options.advancedMode=Advanced mode
packLearn.dialogs.options.advancedMode.notice=By default "long-term learning" uses a modified learning spaced repetition algorithm where it is enough to choose only "Know" or "Don't know". By toggle to "Advanced mode", you can use a classic algorithm with five available options, similar to Supermemo or Anki.
##
searchPage.title=Search "{0}"
searchPage.head=Results for query "{0}"
searchPage.display.packs=Show sets
searchPage.display.courses=Show courses
searchPage.noResults.packs=Sorry, no set has been found
searchPage.noResults.courses=Sorry, no courses has been found
##
learnPage.progress.total=Passed
learnPage.progress.correct=Correct
learnPage.progress.wrong=Wrong
learnPage.answer.go=Answer
learnPage.answer.placeholder=Type your answer
learnPage.answer.unknown=I don't know
learnPage.wrong.stillCorrect=My answer is correct!
learnPage.next=Next
learnPage.result.actual=Your answer
learnPage.result.correct=Correct answer
learnPage.header.correct=Correct answer
learnPage.header.wrong=Wrong answer
learnPage.emptyQueue=Congratulations! You've passed all the cards.
learnPage.restartQueue=Restart
learnPage.reviewComplete.title=Congratulations!
learnPage.reviewComplete.text=You've passed all the cards.
##
testSelectPage.opt.limit=Question limit
testSelectPage.opt.first=Show
testSelectPage.start=Begin test
testSelectPage.opt.limit.prefix=Use
testSelectPage.opt.limit.suffix=of {0}
testSelectPage.opt.first.face=Term
testSelectPage.opt.first.back=Definition
testSelectPage.mode1=Match
testSelectPage.mode2=Yes / No
testSelectPage.mode3=4 options
testSelectPage.mode4=Write
##
testResult.message=Your score:
testResult.correctTitle=Expected answer
testResult.noAnswer=You select nothing
testResult.noAnswerWrite=You write nothing
testCheck.answer=Answer:
testCheck.answer.yes=Correct
testCheck.answer.no=Wrong
testCheck.answer.correct=Correct!
testCheck.answer.wrong=Wrong!
testWrite.input.placeholder=Type your answer here
##
uknownPack.text=Maybe we had this set before... Unfortunately, it's no available anymore :(
##
packTest.form.questionLimit=Question limit
packTest.form.questionLimit.of=of
packTest.form.questionLimit.invalid=Question count is invalid.
packTest.form.questionWith=Question with
packTest.form.questionWith.notSelected=At least one option is required.
packTest.form.requireOne=Require one answer only
packTest.form.requireOne.explain=At least one correct answer is required. Answers must be separated by a slash (/), a comma (,) or a semicolon (;)
packTest.form.types=Question types
packTest.form.types.notSelected=At least one option is required.
packTest.form.type.choice=Multiple-Choice
packTest.form.type.yn=True/False
packTest.form.type.written=Written
packTest.form.type.matching=Matching
packTest.start=Begin test
packTest.qsMatching=Questions for matching
packTest.qsMatching.expected=Correct answer is:
packTest.qsWritten=Questions for writing
packTest.qsWritten.inputPlaceholder=type your answer here
packTest.qsWritten.expected=Correct answer is:
packTest.qsChoice=Questions for choice
packTest.qsChoice.expected=Correct answer is:
packTest.qsYN=Questions for true/false
packTest.qsYN.true=True
packTest.qsYN.false=False
packTest.qsYN.expected=Correct answer is:
packTest.checkAnswers=Check answers
packTest.score.prefix=Test result:
packTest.score.90=Excellent!
packTest.score.75=Good!
packTest.score.50=Average
packTest.score.25=Poor
packTest.score.0=Weak
##
columns.cardsInPack=Cards
##
fexplorer.page.title={0} flashcards explorer
fexplorer.page.categoryTitle={0} Flashcards
fexplorer.title=Flashcards explorer
fexplorer.popularsCategories=Popular categories
fexplorer.language=My language
fexplorer.allCategories=All categories
fexplorer.collections.section=Collections
fexplorer.flashcards.section=Flashcards
fexplorer.flashcards.terms=terms
fexplorer.empty.message=Please select a category <br>or<br>
fexplorer.empty.search=Search something
##
packPlay.progressBar.title=Progress
packPlay.head.play=Flashcards
packPlay.head.learn=Learn
packPlay.head.review=Review
packPlay.head.test=Test
packPlay.head.write=Write
packPlay.head.memo=Memo
packPlay.head.spell=Spell
packPlay.head.match=Match
##
packMemo.showAnswer=Show answer
packMemo.sessionComplete=No more cards<br>for today!
packMemo.sessionNextTime=Come back in {0}.
packMemo.grades.button.H=Hardly
packMemo.grades.button.E=Easy
packMemo.grades.button.M=Medium
packMemo.grades.button.1=Bad
packMemo.grades.button.2=Weak
packMemo.grades.button.3=Medium
packMemo.grades.button.4=Good
packMemo.grades.button.5=Strong
packMemo.grades.button.Y=Ok, I remember
packMemo.grades.button.R=I know
packMemo.grades.button.F=I don't know
packMemo.short.days=d
packMemo.short.months=mo
packMemo.short.hours=h
packMemo.welcome.title=Long Term Learning Desk
packMemo.welcome.text=It's spaced repetition scheduler. Once you start learning process you will learn 20 cards every day. Frequency of card showing depends on your answers. This mode is based on famous spaced repetition algorithm called SM-2. <br><a href="https://en.wikipedia.org/wiki/SuperMemo">Read more.</a>
packMemo.welcome.start=Let's start
packMemo.welcome.login=Unfortunately, this mode is available only for authorized users.<br>Please, <a href="/login">sign in</a> or <a href="/registration">sign up</a>. <br>It's easy and absolutely free.
packMemo.welcomeComplete.header=Set was added into the Long Term Learning Desk.
packMemo.welcomeComplete.html=<p>You can learn the set:</p>\n<ul>\n<li>On Studylib.net site</li>\n<li>In Chrome browser extension</li>\n</ul>
packMemo.welcomeComplete.learn=Learn now
packMemo.welcomeComplete.back=Finish
packMemo.help.title=Hint
packMemo.help.line1=SpaceBar to show answer
packMemo.help.line2=1,2,3,4,5 to make your choice
packMemo.help.hide=Hide
packMemo.end.freshAvailable=Do you want to see more new cards today?
packMemo.end.freshAccept=Yes, show me more
##
fl.matchGame.welcome.text=Drag items onto each other to make them dissappear
fl.matchGame.startGame=Start game
fl.matchGame.timer=Time
fl.matchGame.preset.number=Count
fl.matchGame.result.title=Congratulations!
fl.matchGame.result.text=You score is {0} seconds
fl.matchGame.result.again=Play again
##
fl.memoHeatMap.title=Your learning history
fl.memoHeatMap.legend.daverage=Daily average:
fl.memoHeatMap.legend.daverage.value={0} {0,plural,one{card}few{cards}other{cards}}
fl.memoHeatMap.legend.dlearned=Days learned:
fl.memoHeatMap.legend.dlearned.value={0}%
fl.memoHeatMap.legend.beststreak=Best streak:
fl.memoHeatMap.legend.beststreak.value={0} {0,plural,one{day}few{days}other{days}}
fl.memoHeatMap.legend.currentstreak=Current streak:
fl.memoHeatMap.legend.currentstreak.value={0} {0,plural,one{day}few{days}other{days}}
fl.memoHeatMap.tooltip.passed={0} {0,plural,one{passed review}few{passed reviews}other{passed reviews}}
fl.memoHeatMap.tooltip.failed={0} {0,plural,one{failed review}few{failed reviews}other{failed reviews}}
fl.memoHeatMap.tooltip.fresh={0} {0,plural,one{fresh card}few{fresh cards}other{fresh cards}}
fl.memoHeatMap.tooltip.date=on {0}
##
cardButtons.ban.title=Ban this card
cardButtons.edit.title=Correct this card
cardButtons.star.title=Toggle favourite card
cardButtons.ban.prompt=Ignore this card?
##
validation.cardText.tooLong=Maximum length of this text is 1000 characters
##
extLocale.la=Latin
extLocale.rw=Kinyarwanda
extLocale.af=Afrikaans
extLocale.am=Amharic
extLocale.az=Azerbaijani
extLocale.bn=Bengal
extLocale.bs=Bosnian
extLocale.ceb=Cebuano
extLocale.co=Corsican
extLocale.cy=Welsh
extLocale.eo=Esperanto
extLocale.eu=Basque
extLocale.fa=Persian
extLocale.fy=Frisian
extLocale.gd=Scots (Gaelic)
extLocale.gl=Galician
extLocale.gu=Gujarati
extLocale.ha=House
extLocale.haw=Hawaiian
extLocale.hmn=Hmong
extLocale.ht=Creole (Haiti)
extLocale.hy=Armenian
extLocale.id=Indonesian
extLocale.ig=Igbo
extLocale.jw=Javanese
extLocale.ka=Georgian
extLocale.kk=Kazakh
extLocale.km=Khmer
extLocale.kn=Kannada
extLocale.ku=Kurmanji
extLocale.ky=Kyrgyz
extLocale.lb=Luxembourg
extLocale.lo=Laotian
extLocale.mg=Malagasy
extLocale.mi=Maori
extLocale.ml=Malayalam
extLocale.mn=Mongolian
extLocale.mr=Marathi
extLocale.my=Burmese
extLocale.ne=Nepali
extLocale.ny=Ceva
extLocale.or=Oria
extLocale.pa=Punjabi
extLocale.ps=Pashto
extLocale.sd=Sindhi
extLocale.si=Sinhalese
extLocale.sm=Samoan
extLocale.sn=Shona
extLocale.so=Somali
extLocale.st=Sesotho
extLocale.su=Sudanese
extLocale.sw=Swahili
extLocale.ta=Tamil
extLocale.te=Telugu
extLocale.tg=Tajik
extLocale.tk=Turkmen
extLocale.tl=Philippine
extLocale.tt=Tatar
extLocale.ug=Uigur
extLocale.ur=Urdu
extLocale.uz=Uzbek
extLocale.xh=Xhosa
extLocale.yi=Yiddish
extLocale.yo=Yoruba
extLocale.zu=Zulu


# landing
fl.landing.title=Learning with {0}
fl.landing.title.small=Know what {0} can add to your learning process
fl.landing.why1.title=It's easy and fun
fl.landing.why1.text=Thousands of schoolchildren and students perform an educational task for {0} every day, improving its level of assessments. On the sofa, in the subway, in line - there will always be a few minutes to study.
fl.landing.why2.title=Six training modes
fl.landing.why2.text=Pick up those tools that are better suited to the information you need to learn.
fl.landing.why3.title=Spaced repetition
fl.landing.why3.text=Based on the study of psychology, the method of interval repetitions allows you to memorize large amounts of information without extraordinary effort. Just search for 10-15 minutes a day.
fl.landing.why4.title=On any device
fl.landing.why4.text=Exercise on the device that you have at hand. It does not matter, computer, tablet or phone - our tools are optimized.
fl.landing.create.title=Create your card sets
fl.landing.create1.title=Creating memorize
fl.landing.create1.text=To prepare a set, you have to structure and simplify information. This is one of the best ways to master the material. In the future, you will only need to update memories.
fl.landing.create2.title=Convenient import
fl.landing.create2.text=Take advantage of the flexible tool for importing cards from text files in order to download sets from other sources. You can also import anki file.
fl.landing.create3.title=Modify
fl.landing.create3.text=Select the appropriate set from our base, change it and add it according to your curriculum.
fl.landing.create.button=Create a set
fl.landing.gallery=Examples
fl.landing.gallery.others=View other examples
fl.landing.stars1=3 million<br>students every month
fl.landing.stars2=100 000 000+<br>training sessions
fl.landing.stars3=1000 000+<br>educational kits
fl.landing.reg1=Join now and use interval training educational cards, educational collections and useful educational documents.
fl.landing.reg2=No Account in Google or Facebook? No problem! Sign up using e-mail
## fast add card
fl_addCardModal_term_label=Term
fl_addCardModal_term_placeholder=Need text here
fl_addCardModal_def_label=Definition
fl_addCardModal_def_placeholder=Need text here
fl_addCardModal_addImage=Attach image
fl_addCardModal_addAudio=Attach audio
fl_addCardModal_playAudio=Play audio
fl_addCardModal_browseAudio=Browse for audio file
fl_addCardModal_recordAudio=Record audio

## uninstall page
fl_uninstall_title=Thank you for using Studylib Extension.
fl_uninstall_subtitle=We're sorry to see you go! If you have a moment, please tell us how we can make Extension better:
fl_uninstall_notice=Your feedback helps us improve the product for everyone, thank you 😊
fl_uninstall_button=Send
fl_uninstall_success=See you soon!

## APPEND ITEMS ABOVE THIS LINE